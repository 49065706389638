import React, { useState, memo, useEffect } from "react"
import { Link } from "react-router-dom"
import { BiHelpCircle } from "react-icons/bi"
import { BsChatDots } from "react-icons/bs"

import "./styles.css"

import logoWhatsapp from "../../../../assets/whatsapp.png"

import { apiPublica } from "../../../../services/api"

import AccordionPerguntasFrequentes from "../../../../components/AccordionPerguntasFrequentes"

function CentralDeAjuda() {
  const [perguntasSecaoGeral, setPerguntasSecaoGeral] = useState([])
  const [perguntasSecaoAssinatura, setPerguntasSecaoAssinatura] = useState([])
  const [perguntasSecaoPosGraduacao, setPerguntasSecaoPosGraduacao] = useState([])
  const [respostas, setRespostas] = useState([])


  useEffect(() => {
    // REQUISIÇÃO DE PERGUNTAS
    apiPublica.get("perguntas/")
      .then(function (response) {
        let todasPerguntas = response.data

        todasPerguntas.forEach((item) => {
          switch (item.secoes_pergunta) {
            case "1":
              setPerguntasSecaoGeral(perguntasSecaoGeral => [...perguntasSecaoGeral, item])
              break
            case "2":
              setPerguntasSecaoAssinatura(perguntasSecaoAssinatura => [...perguntasSecaoAssinatura, item])
              break
            case "3":
              setPerguntasSecaoPosGraduacao(perguntasSecaoPosGraduacao => [...perguntasSecaoPosGraduacao, item])
              break
            default:
              break
          }
        })
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE RESPOSTAS
    apiPublica.get("respostas/")
      .then(function (response) {
        setRespostas(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  const buscarRespostaPergunta = (idPergunta) => {
    return respostas.map((item) => {
      if (item.pergunta === idPergunta) {
        return item.descricao
      }

      return ""
    })
  }


  return (
    <div className="container-central-ajuda">
      <div className="container-titulo">
        <div className="titulo">
          <BiHelpCircle className="icone-titulo"></BiHelpCircle>
          <p>Central de Ajuda</p>
        </div>

        <p className="subtitulo">
          Precisando de ajuda? Entre em contato com o nosso time de suporte ou veja nosso faq.
        </p>
      </div>

      <div className="container-informacoes">
        {(perguntasSecaoGeral.length > 0 || perguntasSecaoAssinatura.length > 0 || perguntasSecaoPosGraduacao.length > 0) &&
          <div className="container-perguntas-frequentes">
            <h1>Perguntas <i>Frequentes</i></h1>

            <div className="accordion-perguntas-frequetes">
              {perguntasSecaoGeral.length > 0 &&
                <>
                  <h2>Geral</h2>
                  {perguntasSecaoGeral.sort((x, y) => x.posicao - y.posicao).map((item) => {
                    let resposta = buscarRespostaPergunta(item.id)

                    return (
                      <AccordionPerguntasFrequentes
                        key={item.id}
                        titulo={item.descricao}
                        conteudo={resposta}
                      />
                    )
                  })}
                </>
              }

              {perguntasSecaoAssinatura.length > 0 &&
                <>
                  <h2>Assinaturas e Compras</h2>
                  {perguntasSecaoAssinatura.sort((x, y) => x.posicao - y.posicao).map((item) => {
                    let resposta = buscarRespostaPergunta(item.id)

                    return (
                      <AccordionPerguntasFrequentes
                        key={item.id}
                        titulo={item.descricao}
                        conteudo={resposta}
                      />
                    )
                  })}
                </>
              }

              {perguntasSecaoPosGraduacao.length > 0 &&
                <>
                  <h2>Pós Graduação</h2>
                  {perguntasSecaoPosGraduacao.sort((x, y) => x.posicao - y.posicao).map((item) => {
                    let resposta = buscarRespostaPergunta(item.id)

                    return (
                      <AccordionPerguntasFrequentes
                        key={item.id}
                        titulo={item.descricao}
                        conteudo={resposta}
                      />
                    )
                  })}
                </>
              }
            </div>
          </div>
        }

        <div className="container-contato">
          <a className="botao-contato" href="https://wa.me/556285633841" target="_blank" rel="noreferrer">
            <div>
              <img className="logo" src={logoWhatsapp} alt="whatsapp" />
              <p>Fale Conosco</p>
            </div>
          </a>

          <Link className="botao-contato" to="/acesso/gerenciar-perfil-aluno/sugestao-critica">
            <div>
              <BsChatDots className="logo"></BsChatDots>
              <p>Sugestões/Críticas</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(CentralDeAjuda)
import React, { createContext, useState, useContext } from "react"

export const AuthContext = createContext({})

export const AuthProvider = (props) => {
  const [isAutenticado, setIsAutenticado] = useState(false)

  return (
    <AuthContext.Provider value={{ isAutenticado, setIsAutenticado }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return (
    useContext(AuthContext)
  )
}
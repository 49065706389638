import React, { memo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useUsuario } from "../../../../context/Usuario"

import apiPrivada from "../../../../services/api"

import "./styles.css"

function DetalhesCurso({ cursoSelecionado }) {
  const { usuario } = useUsuario()

  const [usuarioInscrito, setUsuarioInscrito] = useState(null)
  const [isPosGraduacao, setIsPosGraduacao] = useState(false)
  const [habilitarForum, setHabilitarForum] = useState(false)


  const inscreverCurso = () => {
    apiPrivada.post("inscritos/toggle/", {
      curso: cursoSelecionado.id
    })
      .then(function () {
        setUsuarioInscrito(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  useEffect(() => {
    setUsuarioInscrito(cursoSelecionado?.inscrito)

    apiPrivada.get("configuracoescurso/")
      .then(function (response) {
        let configuracoesCursos = response.data

        configuracoesCursos.forEach((config) => {
          if (cursoSelecionado?.id === config.curso) {
            if (config.habilitar_forum) {
              setHabilitarForum(true)
            }
            if (config.disponibilizar_pos_graduacao) {
              setIsPosGraduacao(true)
            }
          }
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [cursoSelecionado])


  return (
    <div className="container-detalhes-curso">
      <div className="container-horario-mensagem">
        <div className="carga-horaria">
          <p className="titulo-secao">Carga Horária</p>

          <div className="container-card-descricao">
            <p>{cursoSelecionado?.carga_horaria} Horas</p>
          </div>
        </div>

        {
          usuario?.assinatura.status === "free"
            ? cursoSelecionado?.valor_avulso !== null
            // ? <div className="mensagem-aluno">
            //   <p>Mensagem para compra do curso avulso<br />"Quando Disponível".</p>
            //   <p>R$ {cursoSelecionado?.valor_avulso}</p>

            //   <Link to="/acesso/cursos-atualizacao/adquirir-curso-avulso">
            //     <button>
            //       Adquirir Curso
            //     </button>
            //   </Link>
            // </div>
            // : <></>
            : <div className="container-botoes-increva-forum">
              <button
                className="btn-inscreva"
                onClick={
                  isPosGraduacao
                    ? () => window.open("https://docs.google.com/forms/d/e/1FAIpQLSe6aQjbR5CAR-8M9G2WD4ktyAYqyAqOdagrPsWedMAyakcLMA/viewform", "_blank")
                    : inscreverCurso
                }
                disabled={usuarioInscrito && !isPosGraduacao}
              >
                {usuarioInscrito && !isPosGraduacao ? "Inscrito" : "Inscreva-se no Curso"}
              </button>
              {habilitarForum &&
                <Link
                  to={isPosGraduacao
                    ? `/acesso/cursos-pos-graduacao/forum-curso/${cursoSelecionado?.id}`
                    : `/acesso/cursos-atualizacao/forum-curso/${cursoSelecionado?.id}`}
                >
                  <button>Fórum do Curso</button>
                </Link>
              }
            </div>
        }
      </div>

      {cursoSelecionado?.conteudos.length > 0 &&
        cursoSelecionado.conteudos.map((item, index) => {
          return (
            <div className="container-conteudo" key={index}>
              <p className="titulo-secao">{item.titulo}</p>

              <div className="container-card-descricao">
                <div dangerouslySetInnerHTML={
                  { __html: item.conteudo }
                } />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default memo(DetalhesCurso)
import React, { memo, useState, useEffect } from "react"

import "./styles.css"

import logoVazada from "../../../assets/logo-vazada.png"

import MeuPerfil from "./MeuPerfil"
import DadosPessoais from "./DadosPessoais"
import Assinatura from "./Assinatura"
import MeuProgresso from "./MeuProgresso"
import CentralDeAjuda from "./CentralDeAjuda"
import SugestaoCritica from "./CentralDeAjuda/SugestaoCritica"
import Rodape from "../../../components/Rodape"

function GerenciarPerfilAluno({ match }) {
  const opcaoMenu = match.params.opcaoMenu
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)

  const verificarOpcaoMenu = () => {
    switch (opcaoMenu) {
      case "meu-perfil":
        return <MeuPerfil></MeuPerfil>
      case "dados-pessoais":
        return <DadosPessoais></DadosPessoais>
      case "assinatura":
        return <Assinatura></Assinatura>
      case "meu-progresso":
        return <MeuProgresso></MeuProgresso>
      case "central-de-ajuda":
        return <CentralDeAjuda></CentralDeAjuda>
        case "sugestao-critica":
          return <SugestaoCritica></SugestaoCritica>
      default:
        return null
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-pagina-gerenciar-perfil-aluno">
      <img className="background" src={logoVazada} alt="background" />

      {
        verificarOpcaoMenu()
      }

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(GerenciarPerfilAluno)
import React, { useState, memo, useCallback } from "react"
import { Link, NavLink, useHistory } from "react-router-dom"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { RiArrowDownSFill } from "react-icons/ri"
import { FaUserAlt, FaChartLine } from "react-icons/fa"
import { BiExit } from "react-icons/bi"

import { useUsuario } from "../../../context/Usuario"
import { useAuth } from "../../../context/Auth"

import apiPrivada from "../../../services/api"

import "./styles.css"

import logoPrime from "../../../assets/logo-prime.png"
import avatar from "../../../assets/user.jpg"

const Header = () => {
  const { usuario } = useUsuario()
  const { setIsAutenticado } = useAuth()

  const history = useHistory()

  const [abrirMenu, setAbrirMenu] = useState(false)
  const [abrirSubMenu, setAbrirSubmenu] = useState(false)

  const menuMobile = useCallback(() => {
    setAbrirMenu(!abrirMenu)
  }, [abrirMenu])

  const fecharMenuMobile = useCallback(() => {
    setAbrirMenu(false)
  }, [])

  const retornarForum = useCallback(() => {
    fecharMenuMobile()

    if (window.location.pathname === "/acesso/forum-geral") {
      window.location.reload()
    }
  }, [])


  const logout = () => {
    apiPrivada.post("auth/invalidate-sessions/", {
      client_id: "x485A3oZ8VoYPVozEVfx7fehFBdwPKWaj4GkI6aR",
      client_secret: "0S77ivla4cjuq6Zp2lRigXcUtBDsiM4p7LBiMgg1HMPviFejySjmvBF1fGYb4rVmicNZ25EzHL2XYBZ0rx7iY011E8aN1guWeaWggRQ0cq6UWQk6WXGaTbNasUyJILyr"
    })
      .then(function () {
        localStorage.removeItem("@CSA:OAUTH_TOKEN")
        setIsAutenticado(false)
        history.replace("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  return (
    <div className="header">
      <div className="logo-container">
        <Link to="/acesso/inicio">
          <img src={logoPrime} alt="logo" />
        </Link>
      </div>

      <div className="nav">
        <ul className={abrirMenu ? "nav-options active" : "nav-options"}>
          <NavLink
            to="/acesso/inicio"
            className="option"
            activeClassName="activeLink"
            onClick={fecharMenuMobile}>
            Início
          </NavLink>

          <NavLink
            to="/acesso/cursos-pos-graduacao"
            className="option"
            activeClassName="activeLink"
            onClick={fecharMenuMobile}>
            Pós Graduação
          </NavLink>

          <NavLink
            to="/acesso/cursos-atualizacao"
            className="option"
            activeClassName="activeLink"
            onClick={fecharMenuMobile}>
            Curso de Atualização
          </NavLink>

          <NavLink
            to="/acesso/forum-geral"
            className="option"
            activeClassName="activeLink"
            onClick={retornarForum}>
            Fórum
          </NavLink>

          <button className="container-submenu" onClick={() => setAbrirSubmenu(!abrirSubMenu)}>
            <div className="btn-submenu">
              <img src={usuario?.imagem !== null ? usuario?.imagem : avatar} alt="aluno" />
              <p>{usuario?.nome_completo}</p>
              <RiArrowDownSFill className="icone-submenu"></RiArrowDownSFill>
            </div>

            <div className={abrirSubMenu ? "submenu aberto" : "submenu fechado"}>
              <Link to="/acesso/gerenciar-perfil-aluno/meu-perfil" onClick={fecharMenuMobile}>
                <div className="opcao-submenu">
                  <FaUserAlt className="icone-opcoes-submenu"></FaUserAlt>
                  Meu Perfil
                </div>
              </Link>

              <Link to="/acesso/gerenciar-perfil-aluno/meu-progresso" onClick={fecharMenuMobile}>
                <div className="opcao-submenu">
                  <FaChartLine className="icone-opcoes-submenu"></FaChartLine>
                  Meu Progresso
                </div>
              </Link>

              <Link to="/acesso/gerenciar-perfil-aluno/central-de-ajuda" onClick={fecharMenuMobile}>
                <div className="opcao-submenu">
                  <BsFillQuestionCircleFill className="icone-opcoes-submenu"></BsFillQuestionCircleFill>
                  Central de Ajuda
                </div>
              </Link>

              <hr className="divisoria-submenu" />

              <div className="opcao-submenu" onClick={logout}>
                <BiExit className="icone-opcoes-submenu opcao-desconectar"></BiExit>
                <span className="opcao-desconectar">Desconectar</span>
              </div>
            </div>
          </button>
        </ul>
      </div>

      <div className="mobile-menu" onClick={menuMobile}>
        {abrirMenu ? (
          <AiOutlineClose className="menu-icon" ></AiOutlineClose>
        ) : (
          <AiOutlineMenu className="menu-icon"></AiOutlineMenu>
        )}
      </div>
    </div>
  )
}

export default memo(Header)
import React, { memo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { AiOutlineFolderView } from "react-icons/ai"

import "./styles.css"

import { apiPublica } from "../../services/api"

function QuadroTrilhasConhecimento({ isPlataformaAcessada }) {
  const [trilhasConhecimento, setTrilhasConhecimento] = useState([])

  useEffect(() => {
    // REQUISIÇÃO DAS TRILHAS DE CONHECIMENTO
    apiPublica.get("trilhasconhecimento/")
      .then(function (response) {
        setTrilhasConhecimento(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  return (
    <div className="container-quadro-trilhas-conhecimento">
      <div className="quadros">
        {
          trilhasConhecimento.map((item) => {
            return (
              <Link key={item.id} to={
                isPlataformaAcessada
                  ? `/acesso/cursos-atualizacao/trilhas-conhecimento/${item.id}`
                  : `/trilhas-conhecimento/${item.id}`
              }
                className="quadro"
              >
                <div className="titulo-quadro">
                  <AiOutlineFolderView className="icone-titulo"></AiOutlineFolderView>
                  <p>{item.titulo}</p>
                </div>
              </Link>
            )
          })
        }
      </div>
    </div>
  )
}

export default memo(QuadroTrilhasConhecimento)
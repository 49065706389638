import React, { memo, useState, useEffect, useCallback } from "react"
import { AiFillWechat } from "react-icons/ai"
import { GrSearch } from "react-icons/gr"
import ReactPaginate from "react-paginate"
import { parseISO, format } from "date-fns"

import "./styles.css"

import apiPrivada from "../../../../services/api"

function ListarTopicos({ alterarTela, selecionarTopico }) {
  const [cursoSearch, setCursoSearch] = useState("")

  const [todosTopicos, setTodosTopicos] = useState([])
  const [quantidadePaginas, setQuantidadePaginas] = useState(0)
  const [quantidadeTopicosExibidos, setQuantidadeTopicosExibidos] = useState(0)
  const [paginaSelecionada, setPaginaSelecionada] = useState(0)
  const [intervaloTopicosEmTela, setIntervaloTopicosEmTela] = useState([])
  const quantidadePorPagina = 10


  const alterarPagina = (e) => {
    setPaginaSelecionada(e.selected)

    let auxQuantidadeTopicosExibidos = e.selected * quantidadePorPagina
    let calcQuantidadeTopicosExibidos = (todosTopicos.length - auxQuantidadeTopicosExibidos) > 10
      ? auxQuantidadeTopicosExibidos + 10
      : auxQuantidadeTopicosExibidos + todosTopicos.length - auxQuantidadeTopicosExibidos

    setQuantidadeTopicosExibidos(calcQuantidadeTopicosExibidos)
    setIntervaloTopicosEmTela(todosTopicos.slice(auxQuantidadeTopicosExibidos, auxQuantidadeTopicosExibidos + quantidadePorPagina))
  }


  const visualizarTopico = (id) => {
    alterarTela("visualizar")
    selecionarTopico(id)
  }


  const filtrarTopicos = useCallback((search) => {
    apiPrivada.get(`forumgeral/?search=${search}`)
      .then(function (response) {
        let todosTopicos = response.data

        setTodosTopicos(todosTopicos)
        setIntervaloTopicosEmTela(todosTopicos.slice(0, quantidadePorPagina))
        setQuantidadePaginas(Math.ceil(todosTopicos.length / quantidadePorPagina))
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  useEffect(() => {
    if (cursoSearch.length === 0) {
      filtrarTopicos("")
    } else if (cursoSearch.length > 2) {
      filtrarTopicos(cursoSearch)
    }
  }, [cursoSearch, filtrarTopicos])


  // SETA A QUANTIDADE DE TÓPICOS JÁ EXIBIDOS
  useEffect(() => {
    if (todosTopicos.length > 10) {
      return setQuantidadeTopicosExibidos(10)
    }

    setQuantidadeTopicosExibidos(todosTopicos.length)
  }, [todosTopicos, quantidadePaginas])


  return (
    <div className="container-listar-topicos">
      <div className="titulo-quadro">
        <button className="botao-criar-topico" onClick={() => alterarTela("criar")}>
          <AiFillWechat className="icone"></AiFillWechat>
          Criar Tópico
        </button>

        <div className="container-paginacao">
          <p className="quantidade">{quantidadeTopicosExibidos} de {todosTopicos.length} Tópicos</p>

          <ReactPaginate
            previousLabel={"Primeira"}
            nextLabel={"Última"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={alterarPagina}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={paginaSelecionada}
          />
        </div>
      </div>

      <div className="titulo-topico">
        <p>Título / Autor do Tópico</p>

        <div className={cursoSearch ? "btn-search open" : "btn-search"}>
          <span className="icon">
            <GrSearch></GrSearch>
          </span>

          <input type="text" className="input-search" onChange={(e) => setCursoSearch(e.target.value)} />
        </div>
      </div>

      {
        intervaloTopicosEmTela.map((item, index) => {
          return (
            <button className="topico" key={index} onClick={() => { visualizarTopico(item.id) }}>
              <AiFillWechat className="icone-topico"></AiFillWechat>

              <div className="informacoes-topico">
                <p>{item.titulo}</p>
                <p>Criado por: {item.autor.nome_completo} - {format(parseISO(item.data_criado), "dd/MM/yy")}</p>
              </div>
            </button>
          )
        })
      }

      <div className="container-paginacao-rodape">
        <div className="container-paginacao">
          <p className="quantidade">{quantidadeTopicosExibidos} de {todosTopicos.length} Tópicos</p>

          <ReactPaginate
            previousLabel={"Primeira"}
            nextLabel={"Última"}
            breakLabel={"..."}
            pageCount={quantidadePaginas}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={alterarPagina}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={paginaSelecionada}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(ListarTopicos)
import React, { memo, useEffect, useState } from "react"

import "./styles.css"

import logoVazada from "../../../assets/logo-vazada.png"

import Rodape from "../../../components/Rodape"
import ListarTopicos from "./ListarTopicos"
import CriarTopico from "./CriarTopico"
import VisualizarTopico from "./VisualizarTopico"

function ForumGeral() {
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [opcaoEscolhida, setOpcaoEscolhida] = useState("listar")
  const [idTopico, setIdTopico] = useState(null)


  const verificarOpcaoEscolhida = () => {
    switch (opcaoEscolhida) {
      case "listar":
        return (
          <ListarTopicos
            alterarTela={(opcao) => setOpcaoEscolhida(opcao)}
            selecionarTopico={(id) => setIdTopico(id)}
          ></ListarTopicos>
        )
      case "criar":
        return (
          <CriarTopico
            alterarTela={() => setOpcaoEscolhida("listar")}
          ></CriarTopico>
        )
      case "visualizar":
        return (
          <VisualizarTopico
            alterarTela={(opcao) => setOpcaoEscolhida(opcao)}
            idTopico={idTopico}
          ></VisualizarTopico>
        )
      default:
        return (
          <ListarTopicos
            alterarTela={() => setOpcaoEscolhida("criar")}
          ></ListarTopicos>
        )
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-forum-geral">
      <img className="background-1" src={logoVazada} alt="background" />
      <img className="background-2" src={logoVazada} alt="background" />

      <div className="quadro-forum-geral">
        {verificarOpcaoEscolhida()}
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(ForumGeral)
import React, { memo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { HiArrowNarrowLeft } from "react-icons/hi"

import "./styles.css"

import logoVazada from "../../../../assets/logo-vazada.png"
import whatsapp from "../../../../assets/whatsapp.png"
import visa from "../../../../assets/visa.png"
import mastercard from "../../../../assets/mastercard.png"
import hipercard from "../../../../assets/hipercard.png"
import elo from "../../../../assets/elo.png"

function AdquirirCursoAvulso() {
  const history = useHistory()

  const [nomeTitular, setNomeTitular] = useState(null)
  const [numeroCartao, setNumeroCartao] = useState(null)
  const [dataValidade, setDataValidade] = useState(null)
  const [codigoSeguranca, setCodigoSeguranca] = useState(null)
  const [formaPagamento, setFormaPagamento] = useState(null)


  return (
    <div className="container-pagina-curso-avulso">

      <img className="background-1" src={logoVazada} alt="background" />
      <img className="background-2" src={logoVazada} alt="background" />

      <form>
        <div className="titulo">
          <div>
            <button onClick={() => history.replace("/acesso/cursos-atualizacao/detalhes-assistir-aulas")}>
              <HiArrowNarrowLeft className="icone-voltar"></HiArrowNarrowLeft>
            </button>

            <h2>Realizar Pagamento</h2>
          </div>

          <p className="subtitulo">Preencha os campos abaixo para continuarmos avançando com a compra do curso</p>

          <div className="dados-compra">
            <p>Dados Da Compra:</p>

            <div>
              <p>Nome Do Curso</p>
              <p>R$499,00</p>
            </div>
          </div>

          <div className="cartoes">
            <img className="logo-cartao-visa" src={visa} alt="cartao" />
            <img className="logo-cartao" src={mastercard} alt="cartao" />
            <img className="logo-cartao" src={hipercard} alt="cartao" />
            <img className="logo-cartao-elo" src={elo} alt="cartao" />
          </div>
        </div>

        <div className="container-inputs">
          <label>
            Nome do Titular Do Cartão
            <input
              value={nomeTitular || ""}
              onChange={(e) => setNomeTitular(e.target.value)}
            />
          </label>

          <label>
            Número do Cartão
            <input
              type="number"
              value={numeroCartao || ""}
              onChange={(e) => setNumeroCartao(e.target.value)}
            />
          </label>

          <label>
            Data De Validade
            <input
              type="date"
              value={dataValidade || ""}
              onChange={(e) => setDataValidade(e.target.value)}
            />
          </label>

          <label>
            Código Segurança
            <input
              type="number"
              value={codigoSeguranca || ""}
              onChange={(e) => setCodigoSeguranca(e.target.value)}
            />
          </label>

          <label>
            Forma De Pagamento
            <select
              value={formaPagamento || ""}
              onChange={(e) => setFormaPagamento(e.target.value)}
            >
              <option value="" disabled></option>
              <option value="crédito">Crédito</option>
              <option value="débito">Débito</option>
            </select>
          </label>

          <button>
            Realizar Pagamento
          </button>
        </div>
      </form>

      <Link to="/">
        <img className="logo-whatsapp" src={whatsapp} alt="whatsapp" />
      </Link>
    </div>
  )
}

export default memo(AdquirirCursoAvulso)
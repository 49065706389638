import React, { memo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import GoogleLoginButton from "react-google-login";
// import { FaFacebookF } from "react-icons/fa"
import ReCAPTCHA from "react-google-recaptcha";

import { FcGoogle } from "react-icons/fc";

import "./styles.css";

import { apiPublica } from "../../../../services/api";

function AcessarContaAluno() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [msgErro, setMsgErro] = useState(null);

  // REQUISIÇÃO DE LOGIN
  const efetuarLogin = (e) => {
    e.preventDefault();

    if (email.trim() === "" || senha.trim() === "") {
      return setMsgErro("Todos os campos devem ser preenchidos!");
    }

    if (!captcha) {
      return setMsgErro("O captcha deve ser marcado!");
    }

    apiPublica
      .post("auth/token", {
        username: email,
        password: senha,
        grant_type: "password",
        client_secret:
          "0S77ivla4cjuq6Zp2lRigXcUtBDsiM4p7LBiMgg1HMPviFejySjmvBF1fGYb4rVmicNZ25EzHL2XYBZ0rx7iY011E8aN1guWeaWggRQ0cq6UWQk6WXGaTbNasUyJILyr",
        client_id: "x485A3oZ8VoYPVozEVfx7fehFBdwPKWaj4GkI6aR",
      })
      .then(function (response) {
        localStorage.setItem("@CSA:OAUTH_TOKEN", response.data.access_token);
        history.push("/acesso/inicio");
      })
      .catch(function (error) {
        console.log(error);
        return setMsgErro(
          "Erro ao realizar login. Confira os dados e tente novamente!"
        );
      });
  };

  // const responseFacebook = (response) => {
  //   apiPublica.post("/auth/convert-token", {
  //     token: response.accessToken,
  //     backend: 'facebook',
  //     grant_type: 'convert_token',
  //     client_id: 'rdkfEvtaXaXd9thbOs8aUlgZssghV7cu7ZR4JVWu',
  //     client_secret: '3sWO3CY6e37yswWIrtgYr9BzQp3LU2G9bqtfSzhvJPATltN6oGcxxPj8zUtbQY0pFWda0RfkLpjSekwKpDqBCBVttgQN9OZhGjWgxQIWvNE0hQV6IH8WI1mxvKV4RheP'
  //   })
  //     .then(function (res) {
  //       localStorage.setItem("@CSA:OAUTH_TOKEN", res.data.access_token)
  //       history.push("/acesso/inicio")
  //     })
  //     .catch(function (err) {
  //       console.log(JSON.stringify(err, null, 4))
  //     })
  // }

  const responseGoogle = (response) => {
    apiPublica
      .post("/auth/convert-token", {
        token: response.accessToken,
        backend: "google-oauth2",
        grant_type: "convert_token",
        client_id: "I9AZcTuMq5MnzlPpbnvSPZZuTgFU8Wmb9TthX368",
        client_secret:
          "iPVwF7i2KpVyMRKk80IytzcstSJvCfvcZkRc15QFbm3gG3s0UcJ5hU5PPKsvwjbTaXeGTrxG2nO1mvPMny51CA5OKUN2c5DIDBPv1duRWSZWVf8cVbTnwTOnmC317W9C",
      })
      .then(function (res) {
        localStorage.setItem("@CSA:OAUTH_TOKEN", res.data.access_token);
        history.push("/acesso/inicio");
      })
      .catch(function (err) {
        console.log(JSON.stringify(err, null, 4));
      });
  };

  return (
    <form className="container-acessar-conta-aluno" onSubmit={efetuarLogin}>
      <div className="titulo">
        <h2>Acesse Sua Conta</h2>

        <div className="botoes-login">
          {/* <FacebookLogin
            appId="592789341906946"
            fields="name,email,picture"
            callback={responseFacebook}
            render={renderProps => (
              <button onClick={renderProps.onClick} type="button" className="btn-facebook">
                <FaFacebookF className="icone-btn-login"></FaFacebookF>
                <p>Continuar com Facebook</p>
              </button>
            )}
          /> */}

          <GoogleLoginButton
            clientId="656629105455-1euuqp5deknpmklfk2n45o2eheonhej3.apps.googleusercontent.com"
            buttonText="Google"
            onSuccess={responseGoogle}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                type="button"
                className="btn-google"
              >
                <FcGoogle className="icone-btn-login"></FcGoogle>
                <p>Continuar com Google</p>
              </button>
            )}
          />

          {/* <button className="btn-linkedin">
            <FaLinkedinIn className="icone-btn-login"></FaLinkedinIn>
            <p>Continuar com LinkedIn</p>
          </button> */}
        </div>

        <p>Ou</p>
        <p className="msg-credenciais">Informe suas credenciais de acesso</p>
      </div>

      <div className="container-inputs">
        <label>
          E-mail
          <input
            required
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>

        <label>
          Senha
          <input
            required
            type="password"
            value={senha || ""}
            onChange={(e) => setSenha(e.target.value)}
          />
        </label>

        <Link
          className="esqueci-senha"
          to="/manter-aluno-plataforma/esqueci-senha"
        >
          Esqueci a senha
        </Link>

        <div className="captcha">
          <ReCAPTCHA
            sitekey="6Ldl54EcAAAAAHR3VJkHcIBww5Uv2y8oONVqbye1"
            onChange={() => setCaptcha(true)}
          />
        </div>

        <button type="button" onClick={efetuarLogin}>
          Entrar
        </button>

        {msgErro !== null && <p className="aviso-erro-login">{msgErro}</p>}

        <Link to="/manter-aluno-plataforma/cadastro-gratis">Cadastre-se</Link>
      </div>
    </form>
  );
}

export default memo(AcessarContaAluno);

import React, { memo, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useUsuario } from "../../../../context/Usuario";

import apiPrivada from "../../../../services/api";

import "./styles.css";

function CompletarCadastroAluno() {
  const history = useHistory();

  const { usuario, setUsuario } = useUsuario();

  const [todosEstados, setTodosEstados] = useState([]);
  const [todasCidades, setTodasCidades] = useState([]);

  const [celular, setCelular] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("S/N");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [cep, setCep] = useState("");
  const [cpf, setCpf] = useState("");

  const [msgErro, setMsgErro] = useState(false);
  //const [isPrincipal, setIsPrincipal] = useState(true);

  const registrarCadastro = (e) => {
    e.preventDefault();

    if (
      celular.trim() === "" ||
      rua.trim() === "" ||
      complemento.trim() === "" ||
      bairro.trim() === "" ||
      estado.trim() === "" ||
      cidade.trim() === "" ||
      cep.trim() === "" ||
      cpf.trim() === ""
    ) {
      return setMsgErro(true);
    }

    apiPrivada
      .patch(`alunos/${usuario.id}/`, {
        celular: celular === "" ? undefined : celular,
        endereco_rua: rua === "" ? undefined : rua,
        endereco_numero: numero === "" ? "S/N" : numero,
        endereco_complemento: complemento === "" ? undefined : complemento,
        endereco_bairro: bairro === "" ? undefined : bairro,
        endereco_estado: estado === "" ? undefined : estado,
        endereco_cidade: cidade === "" ? undefined : cidade,
        endereco_cep: cep === "" ? undefined : cep,
        cpf: cpf === "" ? undefined : cpf,
      })
      .then(function () {
        apiPrivada
          .get("auth/validate/")
          .then(function (response) {
            setMsgErro(false);
            setUsuario(response.data.aluno);
            return history.push("/manter-aluno-plataforma/planos-assinatura");
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function () {
        confirmAlert({
          title: "Erro!",
          message: "Erro ao salvar dados! Tente novamente",
          overlayClassName: "alert-erro-completar-cadastro",
          buttons: [
            {
              label: "Ok",
              className: "botao-erro",
            },
          ],
        });
      });
  };

  useEffect(() => {
    if (usuario !== null) {
      setCelular(usuario.celular);
      setRua(usuario.endereco_rua);
      setNumero(
        usuario.endereco_numero === "" ? "S/N" : usuario.endereco_numero
      );
      setComplemento(usuario.endereco_complemento);
      setBairro(usuario.endereco_bairro);
      setEstado(usuario.endereco_estado);
      setCidade(usuario.endereco_cidade);
      setCep(usuario.endereco_cep);
      setCpf(usuario.cpf);
    }
  }, [usuario]);

  useEffect(() => {
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((res) => {
        return res.json();
      })
      .then((estados) => {
        setTodosEstados(estados);
      });
  }, []);

  useEffect(() => {
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
    )
      .then((res) => {
        return res.json();
      })
      .then((cidades) => {
        setTodasCidades(cidades);
      });
  }, [estado]);

  /*
  //REQUISIÇÃO DE PLANOS
  useEffect(() => {
    apiPrivada
      .get("planos/")
      .then(function (response) {
        let planos = response.data;
        let planosAtivos = [];

        planos.map((item) => {
          if (item.is_ativo) {
            planosAtivos.push(item);
          }
          return null;
        });
        if (planosAtivos.length > 3) {
          setIsPrincipal(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  */

  return (
    <form className="container-completar-cadastro" onSubmit={registrarCadastro}>
      <div className="titulo">
        <h2>Complete seu Cadastro para pagamento</h2>

        <p className="msg-continuar-cadastro">
          Preencha os campos abaixo para completar o seu cadastro.
        </p>
      </div>

      <div className="container-inputs">
        <label>
          Celular <span>*</span>
          <InputMask
            required
            mask="(99) 99999-9999"
            value={celular}
            onChange={(e) => setCelular(e.target.value)}
          ></InputMask>
        </label>

        <label>
          Rua <span>*</span>
          <input
            required
            value={rua}
            onChange={(e) => setRua(e.target.value)}
          />
        </label>

        <label>
          Número <span>*</span>
          <input
            required
            value={numero}
            onChange={(e) => setNumero(e.target.value)}
          />
        </label>

        <label>
          Complemento <span>*</span>
          <input
            required
            value={complemento}
            onChange={(e) => setComplemento(e.target.value)}
          />
        </label>

        <label>
          Bairro <span>*</span>
          <input
            required
            value={bairro}
            onChange={(e) => setBairro(e.target.value)}
          />
        </label>

        <label>
          Estado <span>*</span>
          <select
            required
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
          >
            <option value="" disabled>
              Estado
            </option>
            {todosEstados.map((item) => {
              return (
                <option key={item.id} value={item.sigla}>
                  {item.sigla}
                </option>
              );
            })}
          </select>
        </label>

        <label>
          Cidade <span>*</span>
          <select
            required
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
          >
            <option value="" disabled>
              Cidade
            </option>
            {todasCidades.map((item) => {
              return (
                <option key={item.id} value={item.nome}>
                  {item.nome}
                </option>
              );
            })}
          </select>
        </label>

        <label>
          CEP <span>*</span>
          <InputMask
            required
            mask="99999-999"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
          ></InputMask>
        </label>

        <label>
          CPF <span>*</span>
          <InputMask
            required
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          ></InputMask>
        </label>

        <button type="button" onClick={registrarCadastro}>
          Salvar
        </button>

        {msgErro && (
          <p className="aviso-erro-cadastro">
            Todos os campos com * devem ser preenchidos!
          </p>
        )}
      </div>
    </form>
  );
}

export default memo(CompletarCadastroAluno);

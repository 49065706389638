import React, { memo } from "react"
import { Link } from "react-router-dom"

import "./styles.css"

function CardsTurmaPos({ turma }) {
  return (
    <div className="container-cards-turma-pos">
      <img src={turma.turma.imagem} alt="imagem-turma" />

      <div className="acoes">
        <Link className="btn-acoes" to={`/acesso/cursos-pos-graduacao/turma-pos/${turma.turma.id}`}>
          + Detalhes
        </Link>
      </div>

      <div className="nome-turma">
        <p>{turma.turma.nome > 155 ? turma.turma.nome.substr(0, 155) + "..." : turma.turma.nome}</p>
      </div>
    </div>
  )
}

export default memo(CardsTurmaPos)
import React, { memo, useState } from "react"
import Loader from "react-loader-spinner"
import { confirmAlert } from "react-confirm-alert"

import "./styles.css"

import { apiPublica } from "../../../../services/api"

function EsqueciSenha() {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(null)
  const [msgErro, setMsgErro] = useState(false)


  const enviarEmailSenha = () => {
    if (email.trim() === "") {
      return setMsgErro(true)
    }

    setLoading(true)

    apiPublica.post("auth/password-reset/", {
      email: email
    })
      .then(function (response) {
        setLoading(false)
        console.log(response.data)
      })
      .catch(function (error) {
        confirmAlert({
          title: "Erro!",
          message: "Erro ao enviar e-email! Tente novamente",
          overlayClassName: "alert-erro-enviar-email",
          buttons: [
            {
              label: "Ok",
              className: "botao-erro"
            }
          ]
        })

        setLoading(null)
        console.log(error.response.data)
      })
  }


  return (
    <>
      {
        loading === null &&
        <form className="container-esqueci-senha" onSubmit={enviarEmailSenha}>
          <div className="titulo">
            <h2>Recuperar Senha</h2>

            <p className="msg-credenciais">Informe seu e-mail cadastrado e enviaremos um link para a alteração de senha</p>
          </div>

          <div className="container-inputs">
            <label>
              E-mail
              <input
                required
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            {msgErro &&
              <p className="aviso-erro-cadastro">
                O campo de E-mail deve ser preenchido!
              </p>
            }

            <button type="button" onClick={enviarEmailSenha}>
              Enviar
            </button>
          </div>
        </form>
      }

      {
        loading &&
        <div className="container-loading">
          <h1>Enviando...</h1>

          <Loader
            type="TailSpin"
            color="#fff"
            height={150}
            width={150}
          />
        </div>
      }

      {
        loading === false &&
        <form className="container-esqueci-senha">
          <div className="titulo">
            <h2>E-mail Enviado</h2>

            <p className="msg-credenciais">
              E-mail enviado com sucesso, verifique sua caixa de entrada para obter o link de alteração de senha.
            </p>
          </div>
        </form>
      }
    </>
  )
}

export default memo(EsqueciSenha)
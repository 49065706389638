import React, { memo, useState, useEffect } from 'react'
import { RiArrowRightSFill, RiArrowDownSFill } from "react-icons/ri"
import { FaCheck, FaFilePdf } from "react-icons/fa"
import { IoDocumentTextOutline } from "react-icons/io5"
import Vimeo from "@u-wave/react-vimeo"
import Modal from "react-modal"

import apiPrivada from "../../../services/api"

import "./styles.css"

const AccordionAssistirAulas = ({ aula }) => {
  const idAula = aula.id

  const [itemAtivo, setItemAtivo] = useState(true)
  const [itensAula, setItensAula] = useState([])
  const [itensVisualizados, setItensVisualizados] = useState([])
  const [abrirModalArtigo, setAbrirModalArtigo] = useState(false)


  const abrirModal = () => {
    setAbrirModalArtigo(true);
    document.querySelector("body").style.overflow = "hidden"
  }

  const fecharModal = () => {
    setAbrirModalArtigo(false)
    document.querySelector("body").style.overflow = "visible"
  }


  const ModalArtigoAula = ({ artigo }) => {
    return (
      <Modal
        isOpen={abrirModalArtigo}
        onRequestClose={fecharModal}
        className="modal-artigo"
        overlayClassName="overlay-modal-artigo"
        ariaHideApp={false}
      >
        <div className="fechar-modal">
          <button onClick={fecharModal}>X</button>
        </div>

        <div>
          <h2 className="titulo">{artigo.titulo}</h2>
        </div>

        <div className="conteudo-modal" dangerouslySetInnerHTML={{ __html: artigo.get_texto }} />
      </Modal>
    )
  }


  const registrarProgressoAula = (item, progresso) => {
    apiPrivada.post(`progresso/time/`, {
      item_aula: item.id,
      time: progresso.seconds
    })
      .then(function () {
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  const registrarItemVisualizado = (item) => {
    apiPrivada.post(`progresso/toggle/`, {
      item_aula: item.id
    })
      .then(function () {
        setItensVisualizados(itensVisualizados => [...itensVisualizados, item.id])
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  const renderizandoDocumentoAula = (arquivo) => {
    if (arquivo.imagem || arquivo.artigo) {
      let pathArquivo = arquivo.imagem !== null ? arquivo.imagem : ""
      let extensao = pathArquivo.split('/').pop()
      extensao = pathArquivo.indexOf('.') < 1 ? '' : extensao.split('.').pop()

      if (arquivo.imagem) {
        if (extensao === "pdf") {
          return (
            <a
              className="container-pdf"
              href={pathArquivo}
              target="_blank"
              rel="noopener noreferrer"
              download
              onClick={arquivo.artigo ? abrirModal : null}
            >
              <FaFilePdf
                className="icone-pdf"
                onClick={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
              ></FaFilePdf>

              {itensVisualizados.indexOf(arquivo.id) !== -1 &&
                <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
                  <FaCheck className="icone-andamento"></FaCheck>
                  <p>Visualizado</p>
                </div>
              }
            </a>
          )
        } else {
          return (
            <div className="container-documento">
              <a
                href={pathArquivo}
                target="_blank"
                download
                rel="noopener noreferrer"
                onClick={arquivo.artigo ? abrirModal : null}
              >
                <IoDocumentTextOutline
                  className="icone-documento"
                  onClick={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
                ></IoDocumentTextOutline>
              </a>

              {itensVisualizados.indexOf(arquivo.id) !== -1 &&
                <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
                  <FaCheck className="icone-andamento"></FaCheck>
                  <p>Visualizado</p>
                </div>
              }
            </div>
          )
        }
      } else {
        return (
          <div className="container-documento">
            <button onClick={arquivo.artigo ? abrirModal : null}>
              <IoDocumentTextOutline
                className="icone-documento"
                onClick={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
              ></IoDocumentTextOutline>
            </button>

            {itensVisualizados.indexOf(arquivo.id) !== -1 &&
              <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
                <FaCheck className="icone-andamento"></FaCheck>
                <p>Visualizado</p>
              </div>
            }
          </div>
        )
      }
    }
  }


  const verificandoTipoArquivoAula = (arquivo) => {
    if (arquivo.tipo === "2") {
      return (
        <div className="container-video-aula">
          <div className="video">
            <div className="vimeo">
              <Vimeo
                className="player"
                video={arquivo.link}
                start={arquivo.current_time}
                onPause={(seconds) => registrarProgressoAula(arquivo, seconds)}
                onEnd={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
              />
            </div>

            {
              aula.is_aula_free &&
              <div className="status-aula">
                <p>{"Aula Gratis".toUpperCase()}</p>
              </div>
            }
          </div>

          <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
            <FaCheck className="icone-andamento"></FaCheck>
            <p>Assistido</p>
          </div>
        </div>
      )
    } else {
      return renderizandoDocumentoAula(arquivo)
    }
  }


  const listarArquivosAula = () => {
    return itensAula.map((item, index) => {
      if (item.aula === idAula) {
        return (
          <React.Fragment key={index}>
            <hr />

            <div className="container-aula">
              {verificandoTipoArquivoAula(item)}

              <div className="container-conteudo-aula">
                <p className="descricao">{item.descricao}</p>
                <div dangerouslySetInnerHTML={{ __html: aula.get_descricao }} />
              </div>
            </div>

            {
              item.artigo !== null && <ModalArtigoAula artigo={item.artigo}></ModalArtigoAula>
            }
          </React.Fragment >
        )
      }

      return ""
    })
  }


  useEffect(() => {
    apiPrivada.get(`itensaula/`)
      .then(function (response) {
        setItensAula(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    apiPrivada.get(`progresso/`)
      .then(function (response) {
        let progresso = response.data
        let itensVisualizados = []

        progresso.forEach((item) => {
          item.disciplinas.forEach((item) => {
            item.aulas.forEach((item) => {
              item.itens_assistidos.forEach((item) => {
                itensVisualizados.push(item.item_aula.id)
              })
            })
          })
        })

        setItensVisualizados(itensVisualizados)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  return (
    <div className="accordion-assistir-aulas">
      <div className="accordion-titulo" onClick={() => setItemAtivo(!itemAtivo)}>
        <p>{aula.titulo}</p>

        <div>
          {
            itemAtivo
              ? <RiArrowDownSFill className="icone-accordion"></RiArrowDownSFill>
              : <RiArrowRightSFill className="icone-accordion"></RiArrowRightSFill>
          }
        </div>
      </div>

      {itemAtivo &&
        <div className="accordion-conteudo">
          {
            listarArquivosAula()
          }
        </div>
      }
    </div>
  )
}

export default memo(AccordionAssistirAulas)
import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaCheckSquare } from "react-icons/fa";
import { RiMedalFill } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert";

import apiPrivada from "../../services/api";

import "./styles.css";

function CardsPlanos({ plano, isRenovacao, isPlataformaAcessada }) {
  const history = useHistory();

  const [itensPlanos, setItensPlanos] = useState([]);

  const listarItensPlano = (idPlano) => {
    return itensPlanos.map((item) => {
      if (item.plano === idPlano) {
        return (
          <div key={item.id}>
            <FaCheckSquare className="icone-checkbox"></FaCheckSquare>
            <p>{item.descricao}</p>
          </div>
        );
      }

      return "";
    });
  };

  const assinarPlano = (isGratis) => {
    const { id } = plano;

    apiPrivada
      .post("/assinar-plano/cielo/", { plan: id })
      .then((response) => {
        if (isGratis) {
          return history.push("/manter-aluno-plataforma/sucesso-participante");
        }

        const { cielo_checkout_url } = response.data;

        window.location.href = cielo_checkout_url;
      })
      .catch((error) => {
        console.log(error);

        confirmAlert({
          title: "Erro!",
          message: "Erro ao assinar o plano. Tente novamente!",
          overlayClassName: "alert-sucesso-assinar-plano",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      });
  };

  useEffect(() => {
    apiPrivada
      .get(`itensplanos/`)
      .then(function (response) {
        setItensPlanos(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div key={plano.id} className="container-card">
      <div className="titulo">
        <RiMedalFill className="icone-titulo"></RiMedalFill>

        <h2>{plano.titulo}</h2>
      </div>

      <div className="separador">
        <hr />
        <p>Inclui</p>
        <hr />
      </div>

      <div className="descricao-inclusao">{listarItensPlano(plano.id)}</div>

      <div className="preco">
        <h1>R${plano.valor_mensal}/mês</h1>
        <p>{plano.quantidade_meses} meses</p>
        {plano.valor_mensal === 0.0 && (
          <p className="msg-continuar-gratis">
            Ao continuar grátis, tenho ciência de que não terei acessos a
            assistir os cursos.
          </p>
        )}
      </div>

      <div className="botao">
        {plano.valor_mensal === 0.0 ? (
          <button onClick={() => assinarPlano(true)}>Continuar Grátis</button>
        ) : (
          <button onClick={() => assinarPlano(false)}>Assinar</button>
        )}
      </div>
    </div>
  );
}

export default memo(CardsPlanos);

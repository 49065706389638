import React, { useState, memo } from "react"
import { BiHelpCircle } from "react-icons/bi"
import { BsChatDots } from "react-icons/bs"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

import apiPrivada from "../../../../../services/api"

import "./styles.css"

function SugestaoCritica() {
  const [texto, setTexto] = useState("")
  const [msgErro, setMsgErro] = useState(false)

  const enviarSugestao = () => {
    if (texto.trim() === "") {
      return setMsgErro(true)
    }

    apiPrivada.post("sugestoesecriticas/", {
      texto: texto
    })
      .then(function () {
        confirmAlert({
          title: "Sucesso!",
          message: "Sugestão/crítica enviada com sucesso!",
          overlayClassName: "alert-sucesso-enviar-sugestao",
          buttons: [
            {
              label: "Ok",
              className: "botao-confirmar"
            }
          ]
        })

        setTexto("")
        setMsgErro(false)
      })
      .catch(function (error) {
        console.log("erro", error)
      })
  }


  return (
    <div className="container-sugestao-critica">
      <div className="container-titulo">
        <div className="titulo">
          <BiHelpCircle className="icone-titulo"></BiHelpCircle>
          <p>Central de Ajuda</p>
        </div>

        <p className="subtitulo">
          Precisando de ajuda? Entre em contato com o nosso time de suporte ou veja nosso faq.
        </p>
      </div>

      <div className="container-quadro-texto">
        <div className="titulo-quadro">
          <BsChatDots className="icone"></BsChatDots>
          <p>Nos envie sua sugestão/crítica</p>
        </div>

        <div className="quadro">
          <textarea
            cols="30"
            rows="10" placeholder="Escreva aqui..."
            value={texto}
            onChange={(e) => setTexto(e.target.value)}
          ></textarea>

          <button onClick={enviarSugestao}>Enviar</button>

          {msgErro &&
            <p className="aviso-erro-envio">
              O campo de texto deve ser preenchido!
            </p>
          }
        </div>
      </div>
    </div>
  )
}

export default memo(SugestaoCritica)
import React, { memo } from "react"
import { Link } from "react-router-dom"

import "./styles.css"

function CardsDisciplinasPos({ disciplina, posicao }) {
  const notaMinima = disciplina.nota_minima
  const notaAluno = disciplina.notas.valor
  const progressoAluno = disciplina.progresso.porcentagem


  const verificaStatusAluno = () => {
    if (progressoAluno !== 100) {
      return "EM ANDAMENTO"
    } else {
      if (notaAluno > notaMinima) {
        return "aprovado"
      } else {
        return "reprovado"
      }
    }
  }


  return (
    <div className="container-cards-cursos-disciplinas-pos">
      <img src={disciplina.imagem} alt="imagem-curso" />

      {posicao !== undefined &&
        <div className="posicao-disciplina">
          <p>{posicao}</p>
        </div>
      }

      <div className={`tag-status ${verificaStatusAluno()}`}>
        <p>{verificaStatusAluno().toUpperCase()}</p>
      </div>

      <div className="acoes">
        <Link className="btn-acoes" to={`/acesso/cursos-pos-graduacao/detalhes-assistir-aulas-pos/${disciplina.id}`}>
          + Detalhes
        </Link>
      </div>

      <div className="nome-curso">
        <p>{disciplina.titulo.length > 155 ? disciplina.titulo.substr(0, 155) + "..." : disciplina.titulo}</p>
      </div>
    </div>
  )
}

export default memo(CardsDisciplinasPos)
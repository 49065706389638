import React, { useState, useEffect, memo, useCallback } from "react"
import { GrSearch } from "react-icons/gr"

import "../styles.css"

import apiPrivada from "../../../../services/api"

import logoVazada from "../../../../assets/logo-vazada.png"

import CardsCursosDetalhes from "../../../../components/PlataformaAcessada/CardsCursosDetalhes"
import Rodape from "../../../../components/Rodape"

function TrilhasDeConhecimento({ match }) {
  const idTrilha = match.params.idTrilha
  const [cursoSearch, setCursoSearch] = useState("")
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [abaSelecionada, setAbaSelecionada] = useState("TrilhaSelecionada")

  const [trilhaConhecimentoSelecionada, setTrilhaConhecimentoSelecionada] = useState(null)
  const [cursosAtualizacao, setCursosAtualizacao] = useState([])
  const [cursosTrilhaConhecimento, setCursosTrilhaConhecimento] = useState([])


  const listarCursosTrilhaSelecionada = (todosCursosAtualizacao, trilhaSelecionada) => {
    let cursosTrilhaDeConhecimentoSelecionada = []

    trilhaSelecionada.cursos.forEach((item, index) => {
      let resultado = todosCursosAtualizacao.map((curso) => {
        return curso.id
      }).indexOf(item.id)

      if (resultado !== -1) {
        cursosTrilhaDeConhecimentoSelecionada.push({ curso: item, posicao: index })
      }
    })

    setCursosTrilhaConhecimento(cursosTrilhaDeConhecimentoSelecionada)
  }


  const separarCategoriaCursosAtualizacao = useCallback((todosCursos, configuracoesTodosCursos, trilhaConhecimento) => {
    let arrCursosAtualizacao = []

    todosCursos.forEach((curso) => {
      configuracoesTodosCursos.forEach((config) => {
        if (curso.id === config.curso) {
          if (config.disponibilizar_plataforma_assinantes) {
            arrCursosAtualizacao.push(curso)
          }
        }
      })
    })

    setCursosAtualizacao(arrCursosAtualizacao)
    listarCursosTrilhaSelecionada(arrCursosAtualizacao, trilhaConhecimento)
  }, [])


  const filtrarCursos = useCallback((search) => {
    apiPrivada.get(`cursos/?search=${search}`)
      .then(function (responseCursos) {
        apiPrivada.get("configuracoescurso/")
          .then(function (responseConfigs) {
            apiPrivada.get(`trilhasconhecimento/${idTrilha}/`)
              .then(function (responseTrilha) {
                setTrilhaConhecimentoSelecionada(responseTrilha.data)
                separarCategoriaCursosAtualizacao(responseCursos.data, responseConfigs.data, responseTrilha.data)
              })
              .catch(function (error) {
                console.log(error)
              })
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idTrilha, separarCategoriaCursosAtualizacao])


  useEffect(() => {
    if (cursoSearch.length === 0) {
      filtrarCursos("")
    } else if (cursoSearch.length > 2) {
      filtrarCursos(cursoSearch)
    }
  }, [cursoSearch, filtrarCursos])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-pagina-cursos">

      <img className="background" src={logoVazada} alt="background" />

      <div className="container-titulo">
        <div className={cursoSearch ? "btn-search open" : "btn-search"}>
          <span className="icon">
            <GrSearch></GrSearch>
          </span>

          <input type="text" className="input-search" onChange={(e) => setCursoSearch(e.target.value)} />
        </div>
      </div>

      <div className="container-abas">
        <div className="opcoes-abas">
          <p
            className={abaSelecionada === "TodosCursos" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("TodosCursos")}
          >Trilhas de Conhecimento - Cursos de Atualização</p>

          <hr />

          <p
            className={abaSelecionada === "TrilhaSelecionada" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("TrilhaSelecionada")}
          >{trilhaConhecimentoSelecionada ? trilhaConhecimentoSelecionada.titulo : ""}</p>
        </div>
      </div>

      <div className="container-cursos">
        {
          abaSelecionada === "TodosCursos"
            ? cursosAtualizacao.map((item, index) => {
              return (
                <CardsCursosDetalhes
                  key={index}
                  curso={item}
                  tipoCurso={"cursos-atualizacao"}
                ></CardsCursosDetalhes>
              )
            })
            : cursosTrilhaConhecimento.map((item, index) => {
              return (
                <CardsCursosDetalhes
                  key={index}
                  curso={item.curso}
                  tipoCurso={"cursos-atualizacao"}
                  posicao={item.posicao}
                ></CardsCursosDetalhes>
              )
            })
        }
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(TrilhasDeConhecimento)
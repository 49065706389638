import React, { memo, useState, useEffect } from "react"
import { Link } from "react-router-dom"

import "./styles.css"

import { apiPublica } from "../../../services/api"

import logoVazada from "../../../assets/logo-vazada.png"

import CardsCursos from "../../../components/PlataformaLogin/CardsCursos"
import CarouselCursos from "../../../components/PlataformaLogin/CarouselCursos"
import AccordionDiferenciais from "../../../components/PlataformaLogin/AccordionDiferenciais"
import AccordionPerguntasFrequentes from "../../../components/AccordionPerguntasFrequentes"
import QuadroTrilhasConhecimento from "../../../components/QuadroTrilhasConhecimento"
import Rodape from "../../../components/Rodape"

function PaginaInicial() {
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [todosCursos, setTodosCursos] = useState([])
  const [cursosPosGraduacao, setCursosPosGraduacao] = useState([])
  const [cursosAtualizacao, setCursosAtualizacao] = useState([])
  const [depoimentos, setDepoimentos] = useState([])
  const [perguntasSecaoGeral, setPerguntasSecaoGeral] = useState([])
  const [perguntasSecaoAssinatura, setPerguntasSecaoAssinatura] = useState([])
  const [perguntasSecaoPosGraduacao, setPerguntasSecaoPosGraduacao] = useState([])
  const [respostas, setRespostas] = useState([])
  const [diferenciais, setDiferenciais] = useState([])
  const [videosComoIraAprender, setVideosComoIraAprender] = useState([])
  const [videoPrincipalComoIraAprender, setVideoPrincipalComoIraAprender] = useState()
  const [planoPrincipal, setPlanoPrincipal] = useState(null)


  const separarCursosEmCategorias = (todosCursos, configuracoesTodosCursos) => {
    let cursosAtualizacao = []
    let cursosPosGraduacao = []

    todosCursos.forEach((curso) => {
      configuracoesTodosCursos.forEach((config) => {
        if (curso.id === config.curso) {
          if (config.disponibilizar_plataforma_assinantes && !config.disponibilizar_pos_graduacao) {
            cursosAtualizacao.push(curso)
          }

          if (config.disponibilizar_pos_graduacao && !config.disponibilizar_plataforma_assinantes) {
            cursosPosGraduacao.push(curso)
          }

          if (config.disponibilizar_plataforma_assinantes && config.disponibilizar_pos_graduacao) {
            cursosAtualizacao.push(curso)
            cursosPosGraduacao.push(curso)
          }
        }
      })
    })

    setCursosAtualizacao(cursosAtualizacao)
    setCursosPosGraduacao(cursosPosGraduacao)
  }


  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    // REQUISIÇÃO DE CURSOS E CONFIGURAÇÕES DE CURSOS
    apiPublica.get("cursos/")
      .then(function (responseCursos) {
        setTodosCursos(responseCursos.data)

        apiPublica.get("configuracoescurso/")
          .then(function (responseConfigs) {
            separarCursosEmCategorias(responseCursos.data, responseConfigs.data)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE DEPOIMENTOS
    apiPublica.get("depoimentos/")
      .then(function (response) {
        setDepoimentos(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE PERGUNTAS
    apiPublica.get("perguntas/")
      .then(function (response) {
        let todasPerguntas = response.data

        todasPerguntas.forEach((item) => {
          switch (item.secoes_pergunta) {
            case "1":
              setPerguntasSecaoGeral(perguntasSecaoGeral => [...perguntasSecaoGeral, item])
              break
            case "2":
              setPerguntasSecaoAssinatura(perguntasSecaoAssinatura => [...perguntasSecaoAssinatura, item])
              break
            case "3":
              setPerguntasSecaoPosGraduacao(perguntasSecaoPosGraduacao => [...perguntasSecaoPosGraduacao, item])
              break
            default:
              break
          }
        })
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE RESPOSTAS
    apiPublica.get("respostas/")
      .then(function (response) {
        setRespostas(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE DIFERENCIAIS
    apiPublica.get("diferenciais/")
      .then(function (response) {
        setDiferenciais(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE VÍDEOS
    apiPublica.get("videos/")
      .then(function (response) {
        let videos = response.data

        videos.forEach((item) => {
          if (item.is_principal) {
            setVideoPrincipalComoIraAprender(item)
          }
        })

        setVideosComoIraAprender(videos)
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE PLANO PRINCIPAL
    apiPublica.get("plano-principal/")
      .then(function (response) {
        setPlanoPrincipal(response.data[0])
      })
      .catch(function (error) {
        console.log(error)
      })

    return () => intersectionObserver.disconnect()
  }, [])


  const buscarRespostaPergunta = (idPergunta) => {
    return respostas.map((item) => {
      if (item.pergunta === idPergunta) {
        return item.descricao
      }

      return ""
    })
  }


  return (
    <div className="container-pagina-inicial">
      <div className="container-cursos-destaque">
        <img className="background" src={logoVazada} alt="background" />

        <div className="conteudo-cursos-destaque">
          <div className="descricao">
            <h1>MUITO MAIS DO QUE CONTEÚDO</h1>

            <p>
              Uma plataforma de conhecimento e networking voltado para área industrial farmacêutica e afins
            </p>

            <Link className="btn-assine" to="/manter-aluno-plataforma/cadastro-assinatura">
              <button>
                Assine Agora
              </button>
            </Link>

            <p>
              Por apenas <strong>R${planoPrincipal?.valor_mensal}</strong>/mês
            </p>
          </div>

          <div className="cursos">
            {
              todosCursos.map((item) => {
                if (item.is_destaque) {
                  return (
                    <CardsCursos key={item.id} curso={item}></CardsCursos>
                  )
                }
                return null
              })
            }
          </div>
        </div>
      </div>

      {cursosPosGraduacao.length > 0 &&
        <div className="container-secoes pos-graduacao">
          <h1>Nossos Cursos de <i>Pós Graduação</i></h1>

          <CarouselCursos cursos={cursosPosGraduacao}></CarouselCursos>
        </div>
      }

      {cursosAtualizacao.length > 0 &&
        <div className="container-secoes">
          <h1>Nossos Cursos de <i>Especialização</i></h1>

          <CarouselCursos cursos={cursosAtualizacao}></CarouselCursos>
        </div>
      }

      <div className="container-secoes">
        <h1><i>Por que escolher</i> o CSA PRIME</h1>

        <div className="container-motivos-csa">
          <div className="card-motivo">
            <h3>APRENDA COM QUEM REALMENTE SABE</h3>

            <p>
              No CSA PRIME você irá aprender com professores atuantes e com domínio nos temas, tendo a garantia que você
              irá aprender de com os melhores do mercado.
            </p>
          </div>

          <div className="card-motivo">
            <h3>SUA CARREIRA E SEUS ESTUDOS NA DIREÇÃO CERTA</h3>

            <p>
              Um sistema exclusivo de trilhas de conhecimento. Este sistema guiará você pelos conteúdos do CSA PRIME,
              garantindo que você tenha um caminho para seguir sem ficar perdido.
            </p>
          </div>

          <div className="card-motivo">
            <h3>CONTEÚDO PARA TODOS OS NÍVEIS DE PROFISSIONAIS</h3>

            <p>
              Conteúdo completo para todos os níveis de profissionais. Você terá não apenas conteúdos para iniciantes como
              também conteúdos mais avançados e sofisticados.
            </p>
          </div>

          <div className="card-motivo">
            <h3>AMPLIE SEU NETWORKING</h3>

            <p>
              Nossos frequentes eventos ao vivo contribuirão para a troca de experiência e networking entre os melhores
              profissionais da área, além de um fórum de discussões e novas oportunidades.
            </p>
          </div>
        </div>
      </div>

      {videosComoIraAprender.length > 0 &&
        <div className="container-secoes">
          <h1><i>Como</i> você irá aprender</h1>

          <div className="container-videos">
            <div className="container-video-aberto">
              {
                videoPrincipalComoIraAprender &&
                <>
                  <h2>{(videoPrincipalComoIraAprender.descricao.toUpperCase())}</h2>

                  <div dangerouslySetInnerHTML={
                    { __html: videoPrincipalComoIraAprender.iframe }
                  } />
                </>
              }
            </div>

            <div className="container-videos-lateral">
              {videosComoIraAprender.map((item) => {
                if (item.id !== videoPrincipalComoIraAprender.id) {
                  return (
                    <div key={item.id} className="detalhes-video-lateral" onClick={() => setVideoPrincipalComoIraAprender(item)}>

                      <div dangerouslySetInnerHTML={
                        { __html: item.iframe }
                      } />

                      <h3>{item.descricao}</h3>
                    </div>
                  )
                }

                return <React.Fragment key={item.id}></React.Fragment>
              })}
            </div>
          </div>
        </div>
      }

      {diferenciais.length > 0 &&
        <div className="container-secoes">
          <h1><i>Esses são os</i> diferenciais do CSA Educacional</h1>

          <div className="accordion-diferenciais-csa">
            {diferenciais.sort((x, y) => x.posicao - y.posicao).map((item) => {
              return (
                <AccordionDiferenciais key={item.id} titulo={item.titulo} conteudo={item.descricao} />
              )
            })}
          </div>
        </div>
      }

      {depoimentos.length > 0 &&
        <div className="container-secoes">
          <h1><i>O que estão</i> dizendo do CSA-Educacional</h1>

          <div className="container-depoimentos-alunos">
            {
              depoimentos.sort((x, y) => x.posicao - y.posicao).map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className="depoimento-item">
                      <div className="foto-nome">
                        <img src={item.imagem} alt="foto" />
                      </div>

                      <div className="texto">
                        <h2>{item.titulo}</h2>

                        <p>{item.descricao}</p>
                      </div>
                    </div>

                    {
                      index !== depoimentos.length - 1 && <hr />
                    }
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>
      }

      {(perguntasSecaoGeral.length > 0 || perguntasSecaoAssinatura.length > 0 || perguntasSecaoPosGraduacao.length > 0) &&
        <div className="container-secoes">
          <h1>Perguntas <i>Frequentes</i></h1>

          <div className="accordion-perguntas-frequetes">
            {perguntasSecaoGeral.length > 0 &&
              <>
                <h2>Geral</h2>
                {perguntasSecaoGeral.sort((x, y) => x.posicao - y.posicao).map((item) => {
                  let resposta = buscarRespostaPergunta(item.id)

                  return (
                    <AccordionPerguntasFrequentes
                      key={item.id}
                      titulo={item.descricao}
                      conteudo={resposta}
                    />
                  )
                })}
              </>
            }

            {perguntasSecaoAssinatura.length > 0 &&
              <>
                <h2>Assinaturas e Compras</h2>
                {perguntasSecaoAssinatura.sort((x, y) => x.posicao - y.posicao).map((item) => {
                  let resposta = buscarRespostaPergunta(item.id)

                  return (
                    <AccordionPerguntasFrequentes
                      key={item.id}
                      titulo={item.descricao}
                      conteudo={resposta}
                    />
                  )
                })}
              </>
            }

            {perguntasSecaoPosGraduacao.length > 0 &&
              <>
                <h2>Pós Graduação</h2>
                {perguntasSecaoPosGraduacao.sort((x, y) => x.posicao - y.posicao).map((item) => {
                  let resposta = buscarRespostaPergunta(item.id)

                  return (
                    <AccordionPerguntasFrequentes
                      key={item.id}
                      titulo={item.descricao}
                      conteudo={resposta}
                    />
                  )
                })}
              </>
            }
          </div>
        </div>
      }

      <div className="container-secoes">
        <h1>Trilhas de <i>Conhecimento - Curso de Atualização</i></h1>

        <QuadroTrilhasConhecimento isPlataformaAcessada={false}></QuadroTrilhasConhecimento>
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>

      {mostrarRodapeFixo &&
        <div className="rodape-fixo-rolagem">
          <p>
            Por apenas R${planoPrincipal?.valor_mensal}/mês
          </p>

          <Link to="/manter-aluno-plataforma/cadastro-assinatura">
            <button>
              Assine
            </button>
          </Link>
        </div>
      }
    </div>
  )
}

export default memo(PaginaInicial)
import React from "react"
import { Route, Switch } from "react-router-dom"

import { UsuarioProvider } from "../context/Usuario"

import PaginaInicial from "../pages/PlataformaLogin/PaginaInicial"
import TrilhasDeConhecimento from "../pages/PlataformaLogin/TrilhasDeConhecimento"
import ManterAlunoPlataforma from "../pages/PlataformaLogin/ManterAlunoPlataforma"
import Loading from "../pages/PlataformaLogin/Loading"
import ErroLogin from "../pages/PlataformaLogin/ErroLogin"

import MenuSuperior from "../components/PlataformaLogin/MenuSuperior"

const RotasComMenuSuperiorFixo = ({ match }) => {
  return (
    <>
      <MenuSuperior></MenuSuperior>
      <Switch>
        <Route path={match.url} exact component={PaginaInicial}></Route>
        <Route path={`${match.url}trilhas-conhecimento/:idTrilha`} exact component={TrilhasDeConhecimento}></Route>
      </Switch>
    </>
  )
}

export default function PlataformaLogin({ match }) {
  return (
    <UsuarioProvider>
      <Switch>
        <Route path={`${match.url}manter-aluno-plataforma/:etapa`} exact component={ManterAlunoPlataforma}></Route>
        <Route path={`${match.url}loading`} exact component={Loading}></Route>
        <Route path={`${match.url}erro`} exact component={ErroLogin}></Route>
        <Route path={match.url} component={RotasComMenuSuperiorFixo}></Route>
      </Switch>
    </UsuarioProvider>
  )
}
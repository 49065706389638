import React, { memo, useState } from "react"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "react-quill/dist/quill.bubble.css"

import "./styles.css"

import apiPrivada from "../../../../services/api"

function CriarTopico({ alterarTela }) {
  const [titulo, setTitulo] = useState("")
  const [banner, setBanner] = useState("")
  const [texto, setTexto] = useState("")
  const [msgErro, setMsgErro] = useState(false)

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]


  const criarTopico = () => {
    if (titulo.trim() === "" || texto.trim() === "") {
      return setMsgErro(true)
    }

    let formData = new FormData()
    formData.append("banner", banner)
    formData.append("titulo", titulo)
    formData.append("texto", texto)
    formData.append("fechado", false)

    apiPrivada.post(`forumgeral/`, formData)
      .then(function () {
        setMsgErro(false)
        setTitulo("")
        setBanner("")
        setTexto("")

        confirmAlert({
          title: "Sucesso!",
          message: "Tópico criado com sucesso.",
          overlayClassName: "alert-criar-topico",
          buttons: [
            {
              label: "Ok",
              className: "botao-alert-criar-topico"
            }
          ]
        })
      })
      .catch(function (error) {
        console.log(error)

        confirmAlert({
          title: "Erro!",
          message: "Erro ao criar tópico! Tente novamente",
          overlayClassName: "alert-criar-topico",
          buttons: [
            {
              label: "Ok",
              className: "botao-alert-criar-topico"
            }
          ]
        })
      })
  }


  return (
    <div className="container-criar-topico">
      <div className="titulo">
        <p>Criar Tópico</p>
      </div>

      <div className="container-conteudo-topico">
        <p className="texto-titulo">Criar Tópico</p>

        <label>
          <div>
            Título do tópico <span>*</span>
          </div>
          <input
            required
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
          />
        </label>

        <label>
          <div>
            Banner do tópico
          </div>
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={(e) => setBanner(e.target.files[0])}
          />
        </label>

        <label>
          <div>
            Texto Tópico <span>*</span>
          </div>
          <div className="editor-texto">
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={texto}
              onChange={(e) => setTexto(e)}
              placeholder="Texto"
            />
          </div>
        </label>

        <div className="container-botoes">
          <button onClick={alterarTela}>Cancelar</button>
          <button onClick={criarTopico}>Criar Tópico</button>
        </div>

        {msgErro &&
          <p className="aviso-erro-topico">
            Todos os campos com * devem ser preenchidos!
          </p>
        }
      </div>
    </div>
  )
}

export default memo(CriarTopico)
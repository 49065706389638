import React, { createContext, useState, useContext, useEffect } from "react"

import apiPrivada from "../services/api"

export const UsuarioContext = createContext({})

export const UsuarioProvider = (props) => {
  const [usuario, setUsuario] = useState(null)

  useEffect(() => {
    apiPrivada.get("auth/validate/")
      .then(function (response) {
        setUsuario(response.data.aluno)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <UsuarioContext.Provider value={{ usuario, setUsuario }}>
      {props.children}
    </UsuarioContext.Provider>
  )
}

export const useUsuario = () => {
  return (
    useContext(UsuarioContext)
  )
}
import React, { memo, useEffect, useState, useRef, useCallback } from "react"
import { parseISO, format } from "date-fns"
import { ptBR } from 'date-fns/locale'
import { FaEllipsisH, FaCheck } from "react-icons/fa"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "react-quill/dist/quill.bubble.css"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import ReactPaginate from "react-paginate"
import { useUsuario } from "../../../../context/Usuario"

import Rodape from "../../../../components/Rodape"

import apiPrivada from "../../../../services/api"

import avatar from "../../../../assets/user.jpg"

import "./styles.css"

function ForumCurso({ match }) {
  const idCurso = match.params.idCurso

  const { usuario } = useUsuario()

  const [cursoForum, setCursoForum] = useState(null)
  const [novoComentario, setNovoComentario] = useState("")
  const [idComentarioEditado, setIdComentarioEditado] = useState(null)
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)

  const editorTexto = useRef()

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]


  const requisitarComentarios = useCallback((paginaSelecionada) => {
    apiPrivada.get(`forumcurso/${idCurso}/?page=${paginaSelecionada ? paginaSelecionada : 0}`)
      .then(function (response) {
        setCursoForum(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idCurso])


  const salvarComentarioEditado = () => {
    apiPrivada.patch(`forumcursomensagem/${idComentarioEditado}/`, {
      curso: idCurso,
      texto: novoComentario
    })
      .then(function () {
        requisitarComentarios()
        setNovoComentario("")
        setIdComentarioEditado(null)
        requisitarComentarios()
      })
      .catch(function (error) {
        console.log(error)

        confirmAlert({
          title: "Erro!",
          message: "Erro ao alterar comentário! Tente novamente",
          overlayClassName: "alert-visualizar-topico-forum-curso",
          buttons: [
            {
              label: "Ok",
              className: "botao-alert-visualizar-topico-forum-curso"
            }
          ]
        })
      })
  }

  const alertSalvarAlteracaoComentario = () => {
    confirmAlert({
      title: "Salvar Alteração",
      message: "Tem certeza que salvar as alterações neste comentário?",
      overlayClassName: "alert-visualizar-topico-forum-curso",
      buttons: [
        {
          label: "Sim",
          className: "botao-confirmar",
          onClick: () => { salvarComentarioEditado() }
        },
        {
          label: "Não"
        }
      ]
    })
  }

  const abrirComentarioNoEditor = (comentario) => {
    setIdComentarioEditado(comentario.id)
    setNovoComentario(comentario.texto)
    editorTexto.current.focus()
  }


  const excluirComentario = (idComentario) => {
    apiPrivada.delete(`forumcursomensagem/${idComentario}/`)
      .then(function () {
        requisitarComentarios()
      })
      .catch(function (error) {
        console.log(error)

        confirmAlert({
          title: "Erro!",
          message: "Erro ao excluir comentário! Tente novamente",
          overlayClassName: "alert-visualizar-topico-forum-curso",
          buttons: [
            {
              label: "Ok",
              className: "botao-alert-visualizar-topico-forum-curso"
            }
          ]
        })
      })
  }

  const alertExcluirComentario = (idComentario) => {
    confirmAlert({
      title: "Excluir Comentário",
      message: "Tem certeza que deseja remover este comentário?",
      overlayClassName: "alert-visualizar-topico-forum-curso",
      buttons: [
        {
          label: "Sim",
          className: "botao-confirmar",
          onClick: () => { excluirComentario(idComentario) }
        },
        {
          label: "Não"
        }
      ]
    })
  }


  const SubMenuAcoes = ({ comentario }) => {
    const [menuAcoes, setMenuAcoes] = useState(false)

    return (
      <button className="btn-acoes" onClick={() => setMenuAcoes(!menuAcoes)}>
        <FaEllipsisH className="icone"></FaEllipsisH>

        {menuAcoes &&
          <div className="submenu-acoes">
            <p onClick={() => abrirComentarioNoEditor(comentario)}>Editar Comentário</p>
            <p onClick={() => alertExcluirComentario(comentario.id)}>Excluir Comentário</p>
          </div>
        }
      </button>
    )
  }

  const InputRespostaComentario = ({ comentario }) => {
    const [novaResposta, setNovaResposta] = useState("")

    return (
      <div className="container-nova-resposta">
        <img className="foto-perfil" src={usuario?.imagem !== null ? usuario?.imagem : avatar} alt="" />
        <div className="editor-texto-comentario">
          <ReactQuill
            className="editor"
            theme="snow"
            modules={modules}
            formats={formats}
            value={novaResposta}
            onChange={(e) => setNovaResposta(e)}
            placeholder="Adicionar resposta..."
          />
        </div>

        <button className="btn-postar-resposta" onClick={() => adicionarNovoComentario(comentario, novaResposta)}>
          <FaCheck className="icone"></FaCheck>
        </button>
      </div>
    )
  }


  const Resposta = ({ resposta }) => {
    const [responderResposta, setResponderResposta] = useState(false)

    return (
      <>
        <div className="resposta-comentario">
          <div className="comentario">
            <img className="foto-perfil" src={resposta?.autor.imagem !== null ? resposta?.autor.imagem : avatar} alt="" />
            <div className="texto">
              <div className="autor">
                <p className="nome">{resposta?.autor.nome_completo},</p>

                <div>
                  <p className="data">{format(parseISO(resposta?.data_criado), "dd/MM/yyyy 'às' kk:mm", { locale: ptBR })}</p>
                  {resposta?.autor.id === usuario?.id &&
                    <SubMenuAcoes comentario={resposta}></SubMenuAcoes>
                  }
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: resposta?.texto }} />

              <p
                className="btn-responder-comentario"
                onClick={() => setResponderResposta(!responderResposta)}
              >Responder
              </p>
            </div>
          </div>
        </div>

        {renderizarRespostas(resposta.messages)}

        {responderResposta && <InputRespostaComentario comentario={resposta}></InputRespostaComentario>}
      </>
    )
  }


  const renderizarRespostas = (messages) => {
    return (
      <div className="container-resposta-comentario">
        {messages.map((resposta) => (
          <Resposta key={resposta.id} resposta={resposta}></Resposta>
        ))}
      </div>
    )
  }

  
  const adicionarNovoComentario = (comentario, novaResposta) => {
    const requisicaoNovoComentario = () => {
      apiPrivada.post(`forumcursomensagem/`, {
        curso: idCurso,
        texto: comentario ? novaResposta : novoComentario,
        parent: comentario ? comentario.id : ""
      })
        .then(function () {
          setNovoComentario("")
          requisitarComentarios()
        })
        .catch(function (error) {
          console.log(error)

          confirmAlert({
            title: "Erro!",
            message: "Erro ao adicionar comentário! Tente novamente",
            overlayClassName: "alert-visualizar-topico-forum-curso",
            buttons: [
              {
                label: "Ok",
                className: "botao-alert-visualizar-topico-forum-curso"
              }
            ]
          })
        })
    }

    if (comentario) {
      if (novaResposta.trim() !== "") {
        requisicaoNovoComentario()
      }
    } else {
      if (novoComentario.trim() !== "") {
        requisicaoNovoComentario()
      }
    }
  }


  const Comentario = ({ comentario }) => {
    const [responderComentario, setResponderComentario] = useState(false)

    return (
      <div className="container-comentarios">
        <div className="comentario">
          <img className="foto-perfil" src={comentario.autor.imagem !== null ? comentario.autor.imagem : avatar} alt="" />
          <div className="texto">
            <div className="autor">
              <p className="nome">{comentario.autor.nome_completo},</p>

              <div>
                <p className="data">{format(parseISO(comentario?.data_criado), "dd/MM/yyyy 'às' kk:mm", { locale: ptBR })}</p>
                {comentario.autor.id === usuario.id &&
                  <SubMenuAcoes comentario={comentario}></SubMenuAcoes>
                }
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: comentario?.texto }} />

            <p
              className="btn-responder-comentario"
              onClick={() => setResponderComentario(!responderComentario)}
            >Responder
            </p>
          </div>
        </div>

        {renderizarRespostas(comentario.messages)}

        {responderComentario && <InputRespostaComentario comentario={comentario}></InputRespostaComentario>}
      </div>
    )
  }


  useEffect(() => {
    requisitarComentarios()
  }, [requisitarComentarios])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-forum-curso" style={{ backgroundImage: `url(${cursoForum?.imagem})` }}>
      <div className="container-visualizar-topico-forum-curso">
        <div className="container-detalhes-topico">
          <img className="banner" src={cursoForum?.imagem} alt="imagem-topico" />

          <div className="container-texto">
            <p className="titulo">{cursoForum?.titulo}</p>
            <p className="data">
              Fórum iniciado {cursoForum?.data_criado ? format(parseISO(cursoForum?.data_criado), "dd/MM/yy") : ""}
            </p>
            <p className="subtitulo">DESCRIÇÃO CURSO</p>
            <div className="texto">
              <div dangerouslySetInnerHTML={{ __html: cursoForum?.conteudos[0]?.conteudo }} />
            </div>
            <hr />
          </div>

          <div className="container-comentarios">
          <div className="titulo-comentarios">
            <p>COMENTÁRIOS</p>

            <div className="acoes">
              <button onClick={idComentarioEditado ? alertSalvarAlteracaoComentario : () => adicionarNovoComentario(false)}>
                <FaCheck className="icone"></FaCheck>
              </button>
            </div>
          </div>

          <div className="container-novo-comentario">
            <img className="foto-perfil" src={usuario?.imagem !== null ? usuario?.imagem : avatar} alt="" />
            <div className="editor-texto-comentario">
              <ReactQuill
                ref={editorTexto}
                className="editor"
                theme="snow"
                modules={modules}
                formats={formats}
                value={novoComentario}
                onChange={(e) => setNovoComentario(e)}
                placeholder="Adicionar comentário..."
              />
            </div>
          </div>

          <div className="container-lista-comentarios">
            {
              cursoForum?.messages?.data.map((item) => (
                <Comentario key={item.id} comentario={item}></Comentario>
              ))
            }
          </div>
        </div>
        </div>

        <div className="container-paginacao-comentarios">
        <ReactPaginate
          previousLabel={"Primeira"}
          nextLabel={"Última"}
          breakLabel={"..."}
          pageCount={cursoForum?.messages.pages_count}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(e) => requisitarComentarios(e.selected + 1)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(ForumCurso)
import React, { memo, useState, useEffect } from "react"
import Modal from "react-modal"

import { apiPublica } from "../../../services/api"

import "./styles.css"

function CardsCursos({ curso, posicao }) {
  const [abrirModalDetalhes, setAbrirModalDetalhes] = useState(false)
  const [botaoPos, setBotaoPos] = useState(false)


  const abrirModal = () => {
    setAbrirModalDetalhes(true);
    document.querySelector("body").style.overflow = "hidden"
  }

  const fecharModal = () => {
    setAbrirModalDetalhes(false)
    document.querySelector("body").style.overflow = "visible"
  }


  const ModalDetalhesCurso = ({ curso }) => {
    return (
      <Modal
        isOpen={abrirModalDetalhes}
        onRequestClose={fecharModal}
        className="modal-detalhes-curso"
        overlayClassName="overlay-modal-detalhes-curso"
        ariaHideApp={false}
      >
        <div className="fechar-modal">
          <button onClick={fecharModal}>X</button>
        </div>

        <div className="conteudo-modal-detalhes-curso">
          <h1 className="titulo-curso">{curso?.titulo}</h1>
          <div className="informacoes">
            <p className="titulo-secao">Carga Horária</p>

            <div className="container-card-descricao">
              <p className="carga-horaria">{curso?.carga_horaria} Horas</p>
            </div>
          </div>

          {botaoPos &&
            <div className="container-botao-inscreva">
              <button
                className="botao-inscreva-se"
                onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSe6aQjbR5CAR-8M9G2WD4ktyAYqyAqOdagrPsWedMAyakcLMA/viewform", "_blank")}
              >Inscreva-se</button>
            </div>
          }

          {curso?.conteudos.length > 0 &&
            curso.conteudos.map((item, index) => {
              return (
                <div className="informacoes" key={index}>
                  <p className="titulo-secao">{item.titulo}</p>

                  <div className="container-card-descricao">
                    <div dangerouslySetInnerHTML={
                      { __html: item.conteudo }
                    } />
                  </div>
                </div>
              )
            })
          }
        </div>
      </Modal>
    )
  }


  useEffect(() => {
    apiPublica.get("configuracoescurso/")
      .then(function (response) {
        let configuracoesCursos = response.data

        configuracoesCursos.forEach((config) => {
          if (curso.id === config.curso) {
            if (config.disponibilizar_pos_graduacao) {
              setBotaoPos(true)
            }
          }
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [curso])


  return (
    <div className="container-cards-cursos">
      <img src={curso.imagem} alt="imagem-curso" />

      {posicao !== undefined &&
        <div className={"numero-trilha-conhecimento"}>
          <p>{posicao}</p>
        </div>
      }

      {
        curso?.tag &&
        <div className="tag-curso" style={{ backgroundColor: curso.tag.cor }}>
          <p>{curso.tag.texto.toUpperCase()}</p>
        </div>
      }

      <div className="acoes">
        <button className="btn-detalhes" onClick={abrirModal}>
          + Detalhes
        </button>
      </div>

      <div className="nome-curso">
        <p>{curso.titulo.length > 100 ? curso.titulo.substr(0, 100) + "..." : curso.titulo}</p>
      </div>

      <ModalDetalhesCurso curso={curso}></ModalDetalhesCurso>
    </div>
  )
}

export default memo(CardsCursos)
import React, { memo, useState, useEffect } from "react"
import { RiMedalFill } from "react-icons/ri"
import { FaBan } from "react-icons/fa"
import { Link } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import { format, addMonths } from "date-fns"

import { useUsuario } from "../../../../context/Usuario"

import "./styles.css"

function Assinatura() {
  const { usuario } = useUsuario()

  const [tipoAssinatura, setTipoAssinatura] = useState(null)


  const exibirTituloAssinatura = () => {
    switch (tipoAssinatura) {
      case "Assinante":
        return (
          <>
            <p>Aluno</p>
            <p>Assinante</p>
          </>
        )
      case "Pós Graduação":
        return (
          <>
            <p>Aluno</p>
            <p>Pós</p>
          </>
        )
      default:
        return (
          <>
            <button>
              <Link to="/acesso/planos">
                Assinar Agora
              </Link>
            </button>
          </>
        )
    }
  }


  const cancelarRenovacao = () => {
    confirmAlert({
      title: "Entre em contato!",
      message: `Para cancelar a renovação entre em contato conosco.
      \n(62) 98563-3841 / (62) 3317-2048
      \ncontato@csaeducacional.com.br`,
      overlayClassName: "alert-sucesso-cancelar-renovacao",
      buttons: [
        {
          label: "Ok",
          className: "botao-confirmar"
        }
      ]
    })
  }


  const calcularDataExpiracao = () => {
    if (usuario?.assinatura?.data_assinado) {
      let dataAssinatura = new Date(usuario.assinatura.data_assinado)
      let dataExpiracao = addMonths(dataAssinatura, usuario.assinatura.plano.quantidade_meses)

      return format(dataExpiracao, "dd/MM/yyyy")
    }
  }


  useEffect(() => {
    if (usuario) {
      if (usuario.tipo_aluno === "1") {
        return setTipoAssinatura("Pós Graduação")
      } else if (usuario.assinatura.status !== "free" && usuario.tipo_aluno === "2") {
        return setTipoAssinatura("Assinante")
      } else {
        return setTipoAssinatura("Participante")
      }
    }
  }, [usuario])


  return (
    <>
      <div className="container-assinatura">
        <div className="container-topo">
          <div>
            {exibirTituloAssinatura()}
          </div>

          <RiMedalFill className="icone-topo"></RiMedalFill>
        </div>

        <div className="container-dados-assinatura">
          {tipoAssinatura !== "Participante"
            ? <>
              <h3>Obrigado por ser um aluno assinante.</h3>
              <p className="subtitulo">Aproveite todas as vantagens da sua conta</p>

              <hr />
            </>
            : <>
              <h3>Seja um assinante!</h3>
              <p className="subtitulo">Para ter acesso a todos os nossos cursos</p>

              <hr />
            </>
          }
          <div className="container-dados">
            <div className="col-3">
              <p>Tipo de Assinatura:</p>
              <p>{tipoAssinatura}</p>
            </div>

            {tipoAssinatura !== "Pós Graduação" &&
              <div className="col-3">
                <p>Pagamento:</p>
                {tipoAssinatura !== "Participante" &&
                  <p>Cartão de Crédito</p>
                }
              </div>
            }

            {tipoAssinatura !== "Pós Graduação" &&
              <div className="col-3">
                <p>Sua Assinatura expira em:</p>
                <p>{calcularDataExpiracao()}</p>
              </div>
            }
          </div>
        </div>
      </div>

      <div className="container-cancelar-renovacao" onClick={cancelarRenovacao}>
        <div>
          <FaBan className="icone-cancelar-renovacao"></FaBan>
          <p>Cancelar Renovação</p>
        </div>
      </div>
    </>
  )
}

export default memo(Assinatura)
import React, { memo, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

import "./styles.css"

import CardsCursos from "../CardsCursos"

function CarouselCursos({ cursos }) {
  const carouselRef = useRef()

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 5
    },
    tablet: {
      breakpoint: { max: 1200, min: 1000 },
      items: 4,
      slidesToSlide: 4
    },
    mobile: {
      breakpoint: { max: 1000, min: 770 },
      items: 3,
      slidesToSlide: 3
    },
    mini: {
      breakpoint: { max: 770, min: 545 },
      items: 2,
      slidesToSlide: 2
    },
    superMini: {
      breakpoint: { max: 545, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }

  const voltarCardCarousel = () => {
    if (carouselRef.current.state.currentSlide > 0) {
      carouselRef.current.goToSlide(carouselRef.current.state.currentSlide - 1);
    }
  }

  const passarCardCarousel = () => {
    carouselRef.current.goToSlide(carouselRef.current.state.currentSlide + 1);
  }


  return (
    <div className="container-carousel-cards-cursos" style={{ display: "flex" }}>
      <button className="btn-controle-carousel" onClick={voltarCardCarousel}>
        <IoIosArrowBack className="icone-controle-carousel"></IoIosArrowBack>
      </button>

      <Carousel ref={carouselRef} responsive={responsive} arrows={false}>
        {
          cursos.map((item, index) => {
            return (
              <CardsCursos key={item.id} curso={item}></CardsCursos>
            )
          })
        }
      </Carousel>

      <button className="btn-controle-carousel" onClick={passarCardCarousel}>
        <IoIosArrowForward className="icone-controle-carousel"></IoIosArrowForward>
      </button>
    </div>
  )
}

export default memo(CarouselCursos)
import React, { memo, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Loader from "react-loader-spinner"
import InputMask from "react-input-mask"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import ReactPixel from "react-facebook-pixel"

import apiPrivada from "../../../../services/api"

import "./styles.css"

const SCRIPT_URL = 'https://admin.csaprime.com.br/pagseguro/directpayment/'

function FormularioPagamentoAluno() {
  const history = useHistory()
  const idPlano = history.location.state.idPlano

  const [loadingPage, setLoadingPage] = useState(false);
  const [loaded, setLoaded] = useState(false)
  const [plano, setPlano] = useState(null)

  const [nomeTitular, setNomeTitular] = useState("")
  const [cpfTitular, setCpfTitular] = useState("")
  const [dataNascimentoTitular, setDataNascimentoTitular] = useState("")
  const [numeroCartao, setNumeroCartao] = useState("")
  const [bandeiraCartao, setBandeiraCartao] = useState(null)
  const [mesExpiracao, setMesExpiracao] = useState("")
  const [anoExpiracao, setAnoExpiracao] = useState("")
  const [codigoSeguranca, setCodigoSeguranca] = useState("")
  const [formaPagamento, setFormaPagamento] = useState("")

  const [sessionId, setSessionId] = useState(null)
  const [userHash, setUserHash] = useState(null)

  const [bandeirasCartao, setBandeirasCartao] = useState([])

  const [msgErro, setMsgErro] = useState(false)


  useEffect(() => {
    ReactPixel.track("Lead")

    if (idPlano === null) {
      apiPrivada.get("plano-principal/")
        .then(function (response) {
          setPlano(response.data[0])
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      apiPrivada.get(`planos/${idPlano}/`)
        .then(function (response) {
          setPlano(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [idPlano])


  const onLoaded = () => {
    setLoaded(true)
  }


  async function handleSendPaymentToServer(token) {
    const data = {
      // aqui deve ir o id da assinatura e não do plano
      // essa assinatura vai estar atrelada ao usuário e será
      // devolvida no objeto do user
      plan: plano.id,
      credit_card_token: token,
      credit_card_holder_name: nomeTitular,
      credit_card_holder_cpf: cpfTitular,
      credit_card_holder_birth: dataNascimentoTitular,
      sender_hash: userHash,
      // o restante dos dados de usuário não é preciso enviar pois a API irá carregá-los do banco de dados
    }
    // aqui você pode validar dados caso queira
    try {
      const response = await apiPrivada.post('/assinar-plano/', data)
      console.log('SUCESSO!!!!')
      console.log(JSON.stringify(response.data))
      setMsgErro(false)
      setLoadingPage(false)

      history.push("/manter-aluno-plataforma/status")
    } catch (err) {
      console.log(JSON.stringify(err.response))
      setLoadingPage(false)

      confirmAlert({
        title: "Erro!",
        message: "Erro ao efetuar compra! Tente novamente",
        overlayClassName: "alert-compra",
        buttons: [
          {
            label: "Ok",
            className: "botao-compra"
          }
        ]
      })
    }
  }


  function handleConfirm(e) {
    e.preventDefault()

    if (nomeTitular.trim() === ""
      || cpfTitular.trim() === ""
      || dataNascimentoTitular.trim() === ""
      || numeroCartao.trim() === ""
      || codigoSeguranca.trim() === ""
      || mesExpiracao.trim() === ""
      || anoExpiracao.trim() === ""
      || formaPagamento.trim() === "") {
      return setMsgErro(true)
    }

    setLoadingPage(true);

    // eslint-disable-next-line no-undef
    PagSeguroDirectPayment.createCardToken({
      cardNumber: numeroCartao,
      brand: bandeiraCartao,
      cvv: codigoSeguranca,
      expirationMonth: mesExpiracao,
      expirationYear: anoExpiracao,
      success: function (response) {
        handleSendPaymentToServer(response.card.token)
      },
      error: function (response) {
        setLoadingPage(false);
        setMsgErro(true);
      },
      complete: function (response) {
      }
    })
  }


  useEffect(() => {
    // substitui todos os espaços em branco e _ por nada, para ter o número limpo
    const number = numeroCartao.replace(/[ |_]+/gi, '')
    if (number.length >= 6) {
      // eslint-disable-next-line no-undef
      PagSeguroDirectPayment.getBrand({
        cardBin: number,
        success: function (response) {
          setBandeiraCartao(response.brand.name)
        },
        error: function (response) {
          console.log('TODO: show error message here')
        },
        complete: function (response) {
        }
      })
    }
  }, [numeroCartao])


  useEffect(() => {
    if (plano) {
      if (loaded) {
        return
      } else if (document.querySelector(`script[src="${SCRIPT_URL}"]`)) {
        onLoaded()
        return
      }

      const tag = document.createElement('script')
      tag.type = 'text/javascript'
      tag.src = SCRIPT_URL
      tag.onload = onLoaded
      document.head.appendChild(tag)
    }
  }, [loaded, plano])


  useEffect(() => {
    if (plano) {
      function generateSenderHash() {
        // gerar um hash para o usuário
        // eslint-disable-next-line no-undef
        PagSeguroDirectPayment.onSenderHashReady((response) => {
          if (response.status === 'error') {
            console.log('TODO: exibir mensagem de erro melhor aqui ou redirecionar o usuário para lidar com o erro.')
            console.log(response.message)
            return false
          }
          setUserHash(response.senderHash)
        })
      }

      function populateCreditCardBrands() {
        // eslint-disable-next-line no-undef
        PagSeguroDirectPayment.getPaymentMethods({
          // aqui em amount use o valor de:
          //
          // assinatura.plano.quantidade_meses * assinatura.plano.valor_mensal
          // 
          // que vem com o objeto do usuario, vou deixar estático,
          // apenas pq não estou lidando com o fluxo de autenticação
          amount: plano.quantidade_meses * plano.valor_mensal,
          success: function (response) {
            const relPath = 'https://stc.pagseguro.uol.com.br'
            const credit_cards = response.paymentMethods.CREDIT_CARD.options
            setBandeirasCartao(
              Object.keys(credit_cards).map((key) => {
                const obj = credit_cards[key]
                return {
                  image: relPath + obj.images.SMALL.path,
                  name: obj.name,
                }
              })
            )
          },
          error: function (response) {
          },
          complete: function (response) {
          }
        })
      }

      async function loadToken() {
        try {
          const response = await apiPrivada.post('/auth/pagseguro/')
          const { id } = response.data
          // eslint-disable-next-line no-undef
          PagSeguroDirectPayment.setSessionId(id)
          setSessionId(id)
          // depois da sessão gerada, criar um hash para o usuário (comprador)
          generateSenderHash()
          // e pegar as bandeiras de cartão de crédito
          populateCreditCardBrands()
        } catch (err) {
          console.log('TODO: exibir mensagem de erro e/ou redirecionar usuário aqui')
        }
      }

      // quando a página for carregada, gerar uma sessão do pagseguro
      loadToken()
    }
  }, [plano])


  return (
    <>
      {(!loadingPage && loaded && sessionId && userHash)
        ? <form className="container-formulario-pagamento-aluno" onSubmit={handleConfirm}>
          <div className="titulo">
            <h2>Realizar Pagamento</h2>

            <p className="informacoes">Preencha os campos abaixo para continuarmos avançando com seu cadastro</p>

            <div className="detalhes-plano">
              <p>{plano.titulo}</p>
              <p>{plano.quantidade_meses} meses - R${plano.valor_mensal}/mês</p>
            </div>

            <div>
              {bandeirasCartao.map((item, index) => (
                <img className="logo-cartao" key={index} src={item.image} alt={item.name} />
              ))}
            </div>
          </div>

          <div className="container-inputs">
            <label>
              Nome Impresso no  Cartão <span>*</span>
              <input
                required
                value={nomeTitular}
                onChange={(e) => setNomeTitular(e.target.value)}
              />
            </label>

            <label>
              CPF do Titular Do Cartão <span>*</span>
              <InputMask
                required
                mask="999.999.999-99"
                value={cpfTitular}
                onChange={(e) => setCpfTitular(e.target.value)}
              ></InputMask>
            </label>

            <label>
              Data de Nascimento do Titular Do Cartão <span>*</span>
              <InputMask
                required
                mask="99/99/9999"
                value={dataNascimentoTitular}
                onChange={(e) => setDataNascimentoTitular(e.target.value)}
              ></InputMask>
            </label>

            <label>
              Número do Cartão <span>*</span>
              <input
                required
                type="number"
                value={numeroCartao}
                onChange={(e) => setNumeroCartao(e.target.value)}
              />
            </label>

            <label>
              Código Segurança (CVV) <span>*</span>
              <input
                required
                type="number"
                value={codigoSeguranca}
                onChange={(e) => setCodigoSeguranca(e.target.value)}
              />
            </label>

            <label>
              Mês de expiração <span>*</span>
              <input
                required
                type="number"
                value={mesExpiracao}
                onChange={(e) => setMesExpiracao(e.target.value)}
                maxLength={2}
              />
            </label>

            <label>
              Ano de expiração <span>*</span>
              <input
                required
                type="number"
                value={anoExpiracao}
                onChange={(e) => setAnoExpiracao(e.target.value)}
                maxLength={4}
              />
            </label>

            <label>
              Forma De Pagamento <span>*</span>
              <select
                required
                value={formaPagamento}
                onChange={(e) => setFormaPagamento(e.target.value)}
              >
                <option value="" disabled></option>
                <option value="crédito">Crédito</option>
              </select>
            </label>

            <button type="button" onClick={handleConfirm}>
              Realizar Pagamento
            </button>

            {msgErro &&
              <p className="aviso-erro-pagamento">
                Todos os campos com * devem ser preenchidos!
              </p>
            }
          </div>
        </form>
        : <div className="container-loader">
          <h1>Carregando...</h1>

          <Loader
            type="TailSpin"
            color="#fff"
            height={100}
            width={100}
          />
        </div>
      }
    </>
  )
}
export default memo(FormularioPagamentoAluno)
import React, { memo, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

import { useAuth } from "../../../context/Auth";

import apiPrivada from "../../../services/api";

import "./styles.css";

import logoVazada from "../../../assets/logo-vazada.png";

function Loading() {
  const { setIsAutenticado } = useAuth();

  const history = useHistory();
  const pathName = history.location.state.pathname;

  // const verificarCadastroCompleto = (dadosAluno) => {
  //   if (dadosAluno.celular === ""
  //     || dadosAluno.endereco_rua === ""
  //     || dadosAluno.endereco_numero === ""
  //     || dadosAluno.endereco_complemento === ""
  //     || dadosAluno.endereco_bairro === ""
  //     || dadosAluno.endereco_cidade === ""
  //     || dadosAluno.endereco_estado === ""
  //     || dadosAluno.endereco_cep === ""
  //     || dadosAluno.cpf === "") {
  //     return false
  //   }

  //   return true
  // }

  const redirecionarRota = useCallback(
    (dadosAluno) => {
      if (dadosAluno.assinatura !== null) {
        let statusAssinaturaAluno = dadosAluno.assinatura.status || "expired";
        let checkoutAssinatura = dadosAluno.assinatura.checkout_type || "cielo";

        console.log(statusAssinaturaAluno, checkoutAssinatura);

        if (checkoutAssinatura === "pagseguro") {
          switch (statusAssinaturaAluno) {
            case "expired":
              return history.replace("/erro", {
                checkout: "pagseguro",
                statusErro: "expired",
              });
            case 1:
            case 6:
            case 7:
            case 8:
            case 9:
              return history.replace("/erro", {
                statusErro: statusAssinaturaAluno,
                checkout: "pagseguro",
              });
            default:
              return history.replace(`${pathName}`);
          }
        } else if (checkoutAssinatura === "cielo") {
          switch (statusAssinaturaAluno) {
            case "expired":
              return history.replace("/erro", {
                checkout: "cielo",
                statusErro: "expired",
              });
            case 1:
            case 3:
            case 4:
            case 5:
            case 6:
            case 8:
              return history.replace("/erro", {
                checkout: "cielo",
                statusErro: statusAssinaturaAluno,
              });
            default:
              return history.replace(`${pathName}`);
          }
        }
      } else {
        history.replace("/manter-aluno-plataforma/completar-cadastro");
      }
    },
    [history, pathName]
  );

  useEffect(() => {
    const isAutenticado = async () => {
      const tokenLocalStorage = localStorage.getItem("@CSA:OAUTH_TOKEN");

      if (tokenLocalStorage) {
        return await apiPrivada
          .get("auth/validate/")
          .then(function (response) {
            setIsAutenticado(true);
            redirecionarRota(response.data.aluno);
          })
          .catch(function (error) {
            console.log(error);
            return history.replace("/manter-aluno-plataforma/acessar-conta");
          });
      }

      return history.replace("/manter-aluno-plataforma/acessar-conta");
    };

    return isAutenticado();
  }, [history, redirecionarRota, setIsAutenticado]);

  return (
    <div className="container-loading-login">
      <img className="background-1" src={logoVazada} alt="background" />
      <img className="background-2" src={logoVazada} alt="background" />

      <h1>Carregando...</h1>

      <Loader type="TailSpin" color="#fff" height={150} width={150} />
    </div>
  );
}

export default memo(Loading);

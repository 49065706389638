import React, { memo, useCallback } from "react"
import { Link, useHistory } from "react-router-dom"

import "./styles.css"

import logoPrime from "../../../assets/logo-prime.png"
import logoVazada from "../../../assets/logo-vazada.png"
import whatsapp from "../../../assets/whatsapp.png"

import FormularioCadastroAluno from "./FormularioCadastroAluno"
import FormularioPagamentoAluno from "./FormularioPagamentoAluno"
import PlanosCadastro from "./PlanosCadastro"
import SucessoCadastro from "./SucessoCadastro"
import AcessarContaAluno from "./AcessarContaAluno"
import EsqueciSenha from "./EsqueciSenha"
import CompletarCadastro from "./CompletarCadastro"

import AguardaStatusPagamento from "./AguardaStatusPagamento";

function ManterAlunoPlataforma({ match }) {
  const etapa = match.params.etapa

  const history = useHistory()
  const pathName = history.location.pathname

  const verificarEtapa = useCallback(() => {
    switch (etapa) {
      case "cadastro-gratis":
        return <FormularioCadastroAluno tipoCadastro="gratis"></FormularioCadastroAluno>
      case "cadastro-assinatura":
        return <FormularioCadastroAluno tipoCadastro="assinatura"></FormularioCadastroAluno>
      case "planos-gratis":
        return <PlanosCadastro tipoCadastro="gratis" isRenovacao={false}></PlanosCadastro>
      case "planos-assinatura":
        return <PlanosCadastro tipoCadastro="assinatura" isRenovacao={false}></PlanosCadastro>
      case "planos-renovacao":
        return <PlanosCadastro tipoCadastro="renovacao" isRenovacao={true}></PlanosCadastro>
      case "pagamento":
        return <FormularioPagamentoAluno></FormularioPagamentoAluno>
      case "status":
        return <AguardaStatusPagamento></AguardaStatusPagamento>
      case "sucesso-participante":
        return <SucessoCadastro isAssinante={false}></SucessoCadastro>
      case "sucesso-assinante":
        return <SucessoCadastro isAssinante={true}></SucessoCadastro>
      case "acessar-conta":
        return <AcessarContaAluno isAssinante={true}></AcessarContaAluno>
      case "esqueci-senha":
        return <EsqueciSenha></EsqueciSenha>
      case "completar-cadastro":
        return <CompletarCadastro></CompletarCadastro>
      default:
        return <AcessarContaAluno></AcessarContaAluno>
    }
  }, [etapa])

  return (
    <div className="container-manter-aluno-plataforma">
      <header>
        <div className="container-logo">
          <Link to="/">
            <img src={logoPrime} alt="logo" />
          </Link>
        </div>

        {
          pathName !== "/manter-aluno-plataforma/acessar-conta"
            ? <Link to="/manter-aluno-plataforma/acessar-conta">
              <button>
                Entrar
              </button>
            </Link>
            : <></>
        }
      </header>

      <img className="background-1" src={logoVazada} alt="background" />
      <img className="background-2" src={logoVazada} alt="background" />

      {
        verificarEtapa()
      }

      <a href="https://wa.me/556285633841" target="_blank" rel="noreferrer">
        <img className="logo-whatsapp" src={whatsapp} alt="whatsapp" />
      </a>
    </div>
  )
}

export default memo(ManterAlunoPlataforma)
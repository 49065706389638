import React, { memo, useState, useEffect } from "react"
import { FaLinkedin } from "react-icons/fa"
import InputMask from "react-input-mask"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import { useUsuario } from "../../../../context/Usuario"

import apiPrivada from "../../../../services/api"

import "./styles.css"

import avatar from "../../../../assets/user.jpg"

function DadosPessoais() {
  const { usuario, setUsuario } = useUsuario()

  const [todosEstados, setTodosEstados] = useState([])
  const [todasCidades, setTodasCidades] = useState([])

  const [linkLinkedin, setLinkLinkedin] = useState("")
  const [fotoPerfilHTML, setFotoPerfilHTML] = useState("")
  const [fotoPerfilFormData, setFotoPerfilFormData] = useState("")
  const [nomeCompleto, setNomeCompleto] = useState("")
  const [dataNascimento, setDataNascimento] = useState("")
  const [sexoSelecionado, setSexoSelecionado] = useState("")
  const [cpf, setCpf] = useState("")
  const [rg, setRG] = useState("")
  const [orgaoEmissor, setOrgaoEmissor] = useState("")
  const [nomeMae, setNomeMae] = useState("")
  const [nacionalidade, setNacionalidade] = useState("")
  const [celular, setCelular] = useState("")
  const [telefone, setTelefone] = useState("")
  const [email, setEmail] = useState("")
  const [emailSecundario, setEmailSecundario] = useState("")
  const [pais, setPais] = useState("")
  const [rua, setRua] = useState("")
  const [estadoSelecionado, setEstadoSelecionado] = useState("")
  const [cidadeSelecionada, setCidadeSelecionada] = useState("")
  const [numero, setNumero] = useState("S/N")
  const [complemento, setComplemento] = useState("")
  const [cep, setCep] = useState("")
  const [bairro, setBairro] = useState("")
  const [formacao, setFormacao] = useState("")
  const [statusFormacaoSelecionado, setStatusFormacaoSelecionado] = useState("")
  const [anoConclusao, setAnoConclusao] = useState("")
  const [instituicao, setInstituicao] = useState("")
  const [empresaAtual, setEmpresaAtual] = useState("")
  const [cargo, setCargo] = useState("")
  const [senhaAtual, setSenhaAtual] = useState("")
  const [novaSenha, setNovaSenha] = useState("")
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState("")
  const [checkInputSenha, setCheckInputSenha] = useState("")


  const registrarFotoPerfil = (e) => {
    setFotoPerfilHTML(URL.createObjectURL(e.target.files[0]))

    let formData = new FormData()
    formData.append("imagem", e.target.files[0])
    setFotoPerfilFormData(formData)
  }


  const registrarDados = () => {
    apiPrivada.patch(`alunos/${usuario.id}/`, {
      linkedin: linkLinkedin === "" ? undefined : linkLinkedin,
      nome_completo: nomeCompleto === "" ? undefined : nomeCompleto,
      data_nascimento: dataNascimento === "" ? undefined : dataNascimento,
      sexo: sexoSelecionado === "" ? undefined : sexoSelecionado,
      cpf: cpf === "" ? undefined : cpf,
      rg: rg === "" ? undefined : rg,
      orgao_emissor: orgaoEmissor === "" ? undefined : orgaoEmissor,
      nome_mae: nomeMae === "" ? undefined : nomeMae,
      nacionalidade: nacionalidade === "" ? undefined : nacionalidade,
      celular: celular === "" ? undefined : celular,
      telefone: telefone === "" ? undefined : telefone,
      email: email === "" ? undefined : email,
      email_secundario: emailSecundario === "" ? undefined : emailSecundario,
      endereco_pais: pais === "" ? undefined : pais,
      endereco_rua: rua === "" ? undefined : rua,
      endereco_estado: estadoSelecionado === "" ? undefined : estadoSelecionado,
      endereco_cidade: cidadeSelecionada === "" ? undefined : cidadeSelecionada,
      endereco_numero: numero === "" ? "S/N" : numero,
      endereco_complemento: complemento === "" ? undefined : complemento,
      endereco_cep: cep === "" ? undefined : cep,
      endereco_bairro: bairro === "" ? undefined : bairro,
      formacao: formacao === "" ? undefined : formacao,
      status_formacao: statusFormacaoSelecionado === "" ? undefined : statusFormacaoSelecionado,
      ano_de_conclusao: anoConclusao === "" ? undefined : anoConclusao,
      instituicao: instituicao === "" ? undefined : instituicao,
      empresa: empresaAtual === "" ? undefined : empresaAtual,
      cargo: cargo === "" ? undefined : cargo,
    })
      .then(function () {
        apiPrivada.patch(`alunos/${usuario.id}/`, fotoPerfilFormData)
          .then(function () {
            apiPrivada.get("auth/validate/")
              .then(function (response) {
                setUsuario(response.data.aluno)

                confirmAlert({
                  title: "Sucesso!",
                  message: "Dados salvos com sucesso!",
                  overlayClassName: "alert-sucesso-salvar-dados",
                  buttons: [
                    {
                      label: "Ok",
                      className: "botao-sucesso"
                    }
                  ]
                })
              })
              .catch(function (error) {
                console.log(error)
              })
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function () {
        confirmAlert({
          title: "Erro!",
          message: "Erro ao salvar dados! Tente novamente",
          overlayClassName: "alert-erro-salvar-dados",
          buttons: [
            {
              label: "Ok",
              className: "botao-erro"
            }
          ]
        })
      })
  }


  const registrarNovaSenha = () => {
    if (novaSenha === confirmarNovaSenha) {
      apiPrivada.post("auth/change-password/", {
        password1: senhaAtual,
        password2: novaSenha
      }).then(function () {
        confirmAlert({
          title: "Sucesso!",
          message: "Senha alterada com sucesso!",
          overlayClassName: "alert-sucesso-salvar-dados",
          buttons: [
            {
              label: "Ok",
              className: "botao-sucesso"
            }
          ]
        })
      })
        .catch(function (error) {
          confirmAlert({
            title: "Erro!",
            message: `Erro ao alterar senha! ${error.response.data.detail}`,
            overlayClassName: "alert-sucesso-salvar-dados",
            buttons: [
              {
                label: "Ok",
                className: "botao-sucesso"
              }
            ]
          })
        })
    }
  }


  const salvarDados = (e) => {
    e.preventDefault()

    confirmAlert({
      title: "Salvar Dados",
      message: "Tem certeza que deseja salvar os dados?",
      overlayClassName: "alert-sucesso-salvar-dados",
      buttons: [
        {
          label: "Sim",
          className: "botao-confirmar",
          onClick: () => { registrarDados() }
        },
        {
          label: "Não"
        }
      ]
    })
  }


  const alterarSenha = () => {
    if (senhaAtual === "" || novaSenha === "" || confirmarNovaSenha === "") {
      confirmAlert({
        title: "Erro!",
        message: "Todos os campos de redefinição de senha devem ser preenchidos!",
        overlayClassName: "alert-sucesso-salvar-dados",
        buttons: [
          {
            label: "Ok",
            className: "botao-sucesso"
          }
        ]
      })
    } else {
      confirmAlert({
        title: "Alterar Senha",
        message: "Tem certeza que deseja alterar a sua senha?",
        overlayClassName: "alert-sucesso-salvar-dados",
        buttons: [
          {
            label: "Sim",
            className: "botao-confirmar",
            onClick: () => { registrarNovaSenha() }
          },
          {
            label: "Não"
          }
        ]
      })
    }
  }


  useEffect(() => {
    if (novaSenha !== "" && novaSenha === confirmarNovaSenha) {
      return setCheckInputSenha(true)
    }

    setCheckInputSenha(false)
  }, [novaSenha, confirmarNovaSenha])


  useEffect(() => {
    if (usuario !== null) {
      setLinkLinkedin(usuario.linkedin)
      setFotoPerfilHTML(usuario.imagem)
      setNomeCompleto(usuario.nome_completo)
      setDataNascimento(usuario.data_nascimento)
      setSexoSelecionado(usuario.sexo)
      setCpf(usuario.cpf)
      setRG(usuario.rg)
      setOrgaoEmissor(usuario.orgao_emissor)
      setNomeMae(usuario.nome_mae)
      setNacionalidade(usuario.nacionalidade)
      setCelular(usuario.celular)
      setTelefone(usuario.telefone)
      setEmail(usuario.email)
      setEmailSecundario(usuario.email_secundario)
      setPais(usuario.endereco_pais)
      setRua(usuario.endereco_rua)
      setEstadoSelecionado(usuario.endereco_estado)
      setCidadeSelecionada(usuario.endereco_cidade)
      setNumero(usuario.endereco_numero === "" ? "S/N" : usuario.endereco_numero)
      setComplemento(usuario.endereco_complemento)
      setCep(usuario.endereco_cep)
      setBairro(usuario.endereco_bairro)
      setFormacao(usuario.formacao)
      setStatusFormacaoSelecionado(usuario.status_formacao)
      setAnoConclusao(usuario.ano_de_conclusao)
      setInstituicao(usuario.instituicao)
      setEmpresaAtual(usuario.empresa)
      setCargo(usuario.cargo)
    }
  }, [usuario])


  useEffect(() => {
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then(res => {
        return res.json()
      })
      .then(estados => {
        setTodosEstados(estados)
      })
  }, [])

  useEffect(() => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionado}/municipios`)
      .then(res => {
        return res.json()
      })
      .then(cidades => {
        setTodasCidades(cidades)
      })
  }, [estadoSelecionado])


  return (
    <div className="container-dados-aluno">
      <div className="container-topo">
        <p>Meus Dados Pessoais</p>
      </div>

      <form onSubmit={salvarDados}>
        <p className="titulo">Informe seu perfil do linkedin</p>

        <div className="col-1">
          <div className="label-linkedin">
            <label>
              Link de seu perfil Linkedin
              <div className="input-linkedin">
                <input
                  placeholder="Link de seu perfil Linkedin"
                  value={linkLinkedin || ""}
                  onChange={(e) => setLinkLinkedin(e.target.value)}
                />

                <FaLinkedin className="icone-linkedin"></FaLinkedin>
              </div>
            </label>

            <span>Informe o link de seu perfil do <i>linkedin</i> para preenchimento automático de seus dados</span>
          </div>
        </div>

        <hr />

        <p className="titulo">Informações Pessoais</p>

        <div className="col-1">
          <label className="input-file">
            <div className="container-foto-perfil">
              <div className="foto">
                <img src={fotoPerfilHTML !== null ? fotoPerfilHTML : avatar} alt="foto" />
              </div>

              <p>Adicionar Foto Perfil</p>
            </div>

            <input
              placeholder="Anexar Imagem Curso"
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => registrarFotoPerfil(e)}
            />
          </label>
        </div>

        <div className="col-2">
          <label>
            Nome Completo <span>*</span>
            <input
              placeholder="Nome Completo"
              value={nomeCompleto || ""}
              onChange={(e) => setNomeCompleto(e.target.value)}
            />
          </label>

          <label>
            Data de Nascimento <span>*</span>
            <input
              type="date"
              value={dataNascimento || ""}
              onChange={(e) => setDataNascimento(e.target.value)}
            />
          </label>

          <label>
            Sexo <span>*</span>
            <select
              value={sexoSelecionado || ""}
              onChange={(e) => setSexoSelecionado(e.target.value)}
            >
              <option value="" disabled>Sexo</option>
              <option value="1">Masculino</option>
              <option value="2">Femino</option>
            </select>
          </label>

          <label>
            CPF <span>*</span>
            <InputMask
              placeholder="CPF"
              mask="999.999.999-99"
              value={cpf || ""}
              onChange={(e) => setCpf(e.target.value)}
            ></InputMask>
          </label>

          <label>
            RG
            <input
              placeholder="RG"
              value={rg || ""}
              onChange={(e) => setRG(e.target.value)}
            />
          </label>

          <label>
            Orgão Emissor <span>*</span>
            <input
              placeholder="Orgão Emissor"
              value={orgaoEmissor || ""}
              onChange={(e) => setOrgaoEmissor(e.target.value)}
            />
          </label>

          <label>
            Nome da Mãe <span>*</span>
            <input
              placeholder="Nome da Mãe"
              value={nomeMae || ""}
              onChange={(e) => setNomeMae(e.target.value)}
            />
          </label>

          <label>
            Nacionalidade <span>*</span>
            <input
              placeholder="Nacionalidade"
              value={nacionalidade || ""}
              onChange={(e) => setNacionalidade(e.target.value)}
            />
          </label>
        </div>

        <hr />

        <p className="titulo">Dados para Contato</p>

        <div className="col-2">
          <label>
            Celular com DDD <span>*</span>
            <InputMask
              placeholder="Celular com DDD"
              mask="(99) 99999-9999"
              value={celular || ""}
              onChange={(e) => setCelular(e.target.value)}
            ></InputMask>
          </label>

          <label>
            Telefone
            <InputMask
              placeholder="Telefone"
              mask="(99) 9999-9999"
              value={telefone || ""}
              onChange={(e) => setTelefone(e.target.value)}
            ></InputMask>
          </label>

          <label>
            E-mail <span>*</span>
            <input
              placeholder="E-mail"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>

          <label>
            E-mail Secundário
            <input
              placeholder="E-mail Secundário"
              value={emailSecundario || ""}
              onChange={(e) => setEmailSecundario(e.target.value)}
            />
          </label>

          <label>
            Rua <span>*</span>
            <input
              placeholder="Rua"
              value={rua || ""}
              onChange={(e) => setRua(e.target.value)}
            />
          </label>

          <label>
            Número
            <input
              placeholder="Número"
              value={numero || ""}
              onChange={(e) => setNumero(e.target.value)}
            />
          </label>

          <label>
            Complemento
            <input
              placeholder="Complemento"
              value={complemento || ""}
              onChange={(e) => setComplemento(e.target.value)}
            />
          </label>

          <label>
            Bairro <span>*</span>
            <input
              placeholder="Bairro"
              value={bairro || ""}
              onChange={(e) => setBairro(e.target.value)}
            />
          </label>

          <label>
            Estado <span>*</span>
            <select
              value={estadoSelecionado || ""}
              onChange={(e) => setEstadoSelecionado(e.target.value)}
            >
              <option value="" disabled>Estado</option>
              {todosEstados.map((item) => {
                return (
                  <option key={item.id} value={item.sigla}>{item.sigla}</option>
                )
              })}
            </select>
          </label>

          <label>
            Cidade <span>*</span>
            <select
              value={cidadeSelecionada || ""}
              onChange={(e) => setCidadeSelecionada(e.target.value)}
            >
              <option value="" disabled>Cidade</option>
              {todasCidades.map((item) => {
                return (
                  <option key={item.id} value={item.nome}>{item.nome}</option>
                )
              })}
            </select>
          </label>

          <label>
            CEP <span>*</span>
            <InputMask
              required
              placeholder="CEP"
              mask="99999-999"
              value={cep || ""}
              onChange={(e) => setCep(e.target.value)}
            ></InputMask>
          </label>

          <label>
            País <span>*</span>
            <input
              placeholder="País"
              value={pais || ""}
              onChange={(e) => setPais(e.target.value)}
            />
          </label>
        </div>

        <hr />

        <p className="titulo">Informações Acadêmicas Profissionais</p>

        <div className="col-2">
          <label>
            Graduação <span>*</span>
            <input
              placeholder="Graduação"
              value={formacao || ""}
              onChange={(e) => setFormacao(e.target.value)}
            />
          </label>

          <label>
            Status da Graduação <span>*</span>
            <select
              value={statusFormacaoSelecionado || ""}
              onChange={(e) => setStatusFormacaoSelecionado(e.target.value)}
            >
              <option value="" disabled>Status da Graduação</option>
              <option value="1">Completo</option>
              <option value="2">Em Andamento</option>
            </select>
          </label>

          <label>
            Ano de Conclusão <span>*</span>
            <input
              type="number"
              placeholder="Ano de Conclusão"
              value={anoConclusao || ""}
              onChange={(e) => setAnoConclusao(e.target.value)}
            />
          </label>

          <label>
            Instituição
            <input
              placeholder="Instituição"
              value={instituicao || ""}
              onChange={(e) => setInstituicao(e.target.value)}
            />
          </label>

          <label>
            Empresa em que Atua
            <input
              placeholder="Empresa em que Atual"
              value={empresaAtual || ""}
              onChange={(e) => setEmpresaAtual(e.target.value)}
            />
          </label>

          <label>
            Cargo
            <input
              placeholder="Cargo"
              value={cargo || ""}
              onChange={(e) => setCargo(e.target.value)}
            />
          </label>
        </div>

        <hr />

        <p className="titulo">Redefinir Senha</p>

        <div className="col-1">
          <label>
            Senha Atual
            <input
              type="password"
              placeholder="Senha Atual"
              value={senhaAtual || ""}
              onChange={(e) => setSenhaAtual(e.target.value)}
            />
          </label>
        </div>

        <div className="col-2">
          <label>
            Nova Senha
            <input
              type="password"
              className={checkInputSenha ? "senha-check" : ""}
              placeholder="Nova Senha"
              value={novaSenha || ""}
              onChange={(e) => setNovaSenha(e.target.value)}
            />
          </label>

          <label>
            Confirmar Nova Senha
            <input
              type="password"
              className={checkInputSenha ? "senha-check" : ""}
              placeholder="Confirmar Nova Senha"
              value={confirmarNovaSenha || ""}
              onChange={(e) => setConfirmarNovaSenha(e.target.value)}
            />
          </label>
        </div>

        <button type="button" onClick={alterarSenha}>Alterar Senha</button>

        <button type="submit">Salvar</button>
      </form>
    </div>
  )
}

export default memo(DadosPessoais)
import React, { memo } from "react"
import { Link } from "react-router-dom"

import "./styles.css"

function DetalhesDisciplina({ disciplinaSelecionada }) {
  return (
    <div className="container-detalhes-disciplina">
      <div className="container-conteudos-disciplina">
        {disciplinaSelecionada?.conteudos.length > 0 &&
          disciplinaSelecionada.conteudos.map((item, index) => {
            return (
              <div className="container-conteudo" key={index}>
                <p className="titulo-secao">{item.titulo}</p>

                <div className="container-card-descricao">
                  <div dangerouslySetInnerHTML={
                    { __html: item.conteudo }
                  } />
                </div>
              </div>
            )
          })
        }
      </div>

      <Link to={`/acesso/cursos-pos-graduacao/forum-disciplina/${disciplinaSelecionada?.id}`}>
        <button className="btn-forum">Fórum da Disciplina</button>
      </Link>
    </div>
  )
}

export default memo(DetalhesDisciplina)
import axios from "axios"

export const apiPublica = axios.create({
  baseURL: "https://admin.csaprime.com.br"
})

const apiPrivada = axios.create({
  baseURL: "https://admin.csaprime.com.br"
})

const obterTokenLocalStorage = async (config) => {
  try {
    const tokenLocalStorage = localStorage.getItem("@CSA:OAUTH_TOKEN")

    if (tokenLocalStorage !== null) {
      config.headers.Authorization = `Bearer ${tokenLocalStorage}`
      return config
    }
  } catch (e) {
    console.log("ERRO AO OBTER TOKEN DE USUÁRIO", e)

    return config
  }
}

apiPrivada.interceptors.request.use(config => {
  return obterTokenLocalStorage(config)
})

export default apiPrivada
import React, { useState, memo } from "react"
import { Link, } from "react-router-dom"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"
import { FaRegUserCircle } from "react-icons/fa"

import "./styles.css"

import logoPrime from "../../../assets/logo-prime.png"

const Header = () => {
  const [abrirMenu, setAbrirMenu] = useState(false)

  return (
    <header className="menu-superior-pagina-inicial">
      <div className="container-logo">
        <Link to="/">
          <img src={logoPrime} alt="logo" />
        </Link>
      </div>

      <div className="nav">
        <ul className={abrirMenu ? "nav-options active" : "nav-options"}>
          <div className="container-acoes">
            <div>
              <Link to="/manter-aluno-plataforma/cadastro-assinatura">
                <button>
                  Assine Agora
                </button>
              </Link>

              {/* <Link to="/manter-aluno-plataforma/cadastro-gratis">
                <button>
                  Experimente Grátis
                </button>
              </Link> */}
            </div>

            <div className="linha-vertical"></div>

            <Link className="assinante" to="/manter-aluno-plataforma/acessar-conta">
              <FaRegUserCircle className="icone-usuario"></FaRegUserCircle>
              <p>Já sou Assinante</p>
            </Link>
          </div>
        </ul>
      </div>

      <div className="mobile-menu" onClick={() => setAbrirMenu(!abrirMenu)}>
        {abrirMenu ? (
          <AiOutlineClose className="menu-icon" ></AiOutlineClose>
        ) : (
          <AiOutlineMenu className="menu-icon"></AiOutlineMenu>
        )}
      </div>
    </header>
  )
}

export default memo(Header)
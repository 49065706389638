import React, { memo, useEffect, useState, useRef } from "react"
import { parseISO, format } from "date-fns"
import { ptBR } from 'date-fns/locale'
import { FaEllipsisH, FaCheck } from "react-icons/fa"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "react-quill/dist/quill.bubble.css"
import "react-confirm-alert/src/react-confirm-alert.css"
import ReactPaginate from "react-paginate"
import { useUsuario } from "../../../../context/Usuario"

import Rodape from "../../../../components/Rodape"

import avatar from "../../../../assets/user.jpg"

import "./styles.css"

function ForumDisciplina() {
  const { usuario } = useUsuario()

  const cursoForum = {
    "id": 1,
    "autor": {
      "id": 1,
      "nome_completo": "Paulo Junqueira",
      "imagem": "https://www.mantruckandbus.com/fileadmin/_processed_/c/d/csm_digitalisierung-waser-interviewkachel_8e61bf7ac7.jpg"
    },
    "titulo": "Forum da Disciplina 1",
    "texto": "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    "data_criado": "2022-02-24T12:51:26.933Z",
    "fechado": true,
    "messages": "",
    "banner": "https://images.unsplash.com/photo-1588011930968-eadac80e6a5a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
  }
  const [novoComentario, setNovoComentario] = useState("")
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)

  const editorTexto = useRef()

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const SubMenuAcoes = () => {
    const [menuAcoes, setMenuAcoes] = useState(false)

    return (
      <button className="btn-acoes" onClick={() => setMenuAcoes(!menuAcoes)}>
        <FaEllipsisH className="icone"></FaEllipsisH>

        {menuAcoes &&
          <div className="submenu-acoes">
            <p>Editar Comentário</p>
            <p>Excluir Comentário</p>
          </div>
        }
      </button>
    )
  }

  const InputRespostaComentario = ({ comentario }) => {
    const [novaResposta, setNovaResposta] = useState("")

    return (
      <div className="container-nova-resposta">
        <img className="foto-perfil" src={usuario?.imagem !== null ? usuario?.imagem : avatar} alt="" />
        <div className="editor-texto-comentario">
          <ReactQuill
            className="editor"
            theme="snow"
            modules={modules}
            formats={formats}
            value={novaResposta}
            onChange={(e) => setNovaResposta(e)}
            placeholder="Adicionar resposta..."
          />
        </div>

        <button className="btn-postar-resposta">
          <FaCheck className="icone"></FaCheck>
        </button>
      </div>
    )
  }


  const Resposta = ({ resposta }) => {
    const [responderResposta, setResponderResposta] = useState(false)

    return (
      <>
        <div className="resposta-comentario">
          <div className="comentario">
            <img className="foto-perfil" src={resposta?.autor.imagem !== null ? resposta?.autor.imagem : avatar} alt="" />
            <div className="texto">
              <div className="autor">
                <p className="nome">{resposta?.autor.nome_completo},</p>

                <div>
                  <p className="data">{format(parseISO(resposta?.data_criado), "dd/MM/yyyy 'às' kk:mm", { locale: ptBR })}</p>
                  {resposta?.autor.id === usuario?.id &&
                    <SubMenuAcoes comentario={resposta}></SubMenuAcoes>
                  }
                </div>
              </div>
              {/* <div dangerouslySetInnerHTML={{ __html: resposta?.texto }} /> */}

              <p
                className="btn-responder-comentario"
                onClick={() => setResponderResposta(!responderResposta)}
              >Responder
              </p>
            </div>
          </div>
        </div>

        {renderizarRespostas(resposta.messages)}

        {responderResposta && <InputRespostaComentario comentario={resposta}></InputRespostaComentario>}
      </>
    )
  }


  const renderizarRespostas = (messages) => {
    return (
      <div className="container-resposta-comentario">
        {messages.map((resposta) => (
          <Resposta key={resposta.id} resposta={resposta}></Resposta>
        ))}
      </div>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-forum-curso" style={{ backgroundImage: `url(https://images.unsplash.com/photo-1588011930968-eadac80e6a5a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80)` }}>
      <div className="container-visualizar-topico-forum-curso">
        <div className="container-detalhes-topico">
          <img className="banner" src="https://images.unsplash.com/photo-1583912267550-d974311a9a6e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="imagem-topico" />

          <div className="container-texto">
            <p className="titulo">{cursoForum?.titulo}</p>
            <p className="data">
              Fórum iniciado {cursoForum?.data_criado ? format(parseISO(cursoForum?.data_criado), "dd/MM/yy") : ""}
            </p>
            <p className="subtitulo">DESCRIÇÃO CURSO</p>
            <div className="texto">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
            </div>
            <hr />
          </div>

          <div className="container-comentarios">
            <div className="titulo-comentarios">
              <p>COMENTÁRIOS</p>

              <div className="acoes">
                <button>
                  <FaCheck className="icone"></FaCheck>
                </button>
              </div>
            </div>

            <div className="container-novo-comentario">
              <img className="foto-perfil" src={usuario?.imagem !== null ? usuario?.imagem : avatar} alt="" />
              <div className="editor-texto-comentario">
                <ReactQuill
                  ref={editorTexto}
                  className="editor"
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={novoComentario}
                  onChange={(e) => setNovoComentario(e)}
                  placeholder="Adicionar comentário..."
                />
              </div>
            </div>

            <div className="container-lista-comentarios">
              <div className="container-comentarios">
                <div className="comentario">
                  <img className="foto-perfil" src="https://www.mantruckandbus.com/fileadmin/_processed_/c/d/csm_digitalisierung-waser-interviewkachel_8e61bf7ac7.jpg" alt="" />
                  <div className="texto">
                    <div className="autor">
                      <p className="nome">Julio Carlos Henrique</p>

                      <div>
                        <p className="data">21/02/2022 às 15:24</p>
                        <SubMenuAcoes comentario={1}></SubMenuAcoes>
                      </div>
                    </div>

                    <p>
                      It was popularised in the 1960s with the
                      release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>

                    <p
                      className="btn-responder-comentario"
                    >Responder
                    </p>
                  </div>
                </div>

                <InputRespostaComentario comentario={1}></InputRespostaComentario>
              </div>
            </div>
          </div>
        </div>

        <div className="container-paginacao-comentarios">
          <ReactPaginate
            previousLabel={"Primeira"}
            nextLabel={"Última"}
            breakLabel={"..."}
            pageCount={1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            // onPageChange={(e) => requisitarComentarios(e.selected + 1)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(ForumDisciplina)
import React, { memo, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { useUsuario } from "../../../context/Usuario"

import "./styles.css"

import apiPrivada from "../../../services/api"

import logoVazada from "../../../assets/logo-vazada.png"

import Rodape from "../../../components/Rodape"
import DetalhesCurso from "./DetalhesCurso"
import AssistirAulas from "./AssistirAulas"

function DetalhesCurso_AssistirAulas({ match }) {
  const idCurso = match.params.idCurso
  const tipoCurso = match.params.tipoCurso

  const { usuario } = useUsuario()

  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [abaSelecionada, setAbaSelecionada] = useState("detalhes")
  const [planoPrincipal, setPlanoPrincipal] = useState(null)

  const [cursoSelecionado, setCursoSelecionado] = useState(null)


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    // REQUISIÇÃO DO CURSO SELECIONADO
    apiPrivada.get(`cursos/${idCurso}/`)
      .then(function (response) {
        setCursoSelecionado(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    return () => intersectionObserver.disconnect()
  }, [idCurso])


  // REQUISIÇÃO DE PLANO PRINCIPAL
  useEffect(() => {
    apiPrivada.get("plano-principal/")
      .then(function (response) {
        setPlanoPrincipal(response.data[0])
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  return (
    <div className="container-detalhes-assistir">
      <img className="background" src={logoVazada} alt="background" />

      <div className="container-banner-curso">
        <img src={cursoSelecionado?.imagem} alt="banner" />
        <h1>{cursoSelecionado?.titulo}</h1>
      </div>

      <div className="container-progresso">
        <div className="quadro">
          <CircularProgressbar
            className="progresso"
            value={cursoSelecionado?.progresso.porcentagem}
            text={`${cursoSelecionado?.progresso.porcentagem}%`}
          />
          <p>Progresso</p>
        </div>
      </div>

      {
        usuario?.assinatura.status === "free" &&
        <div className="container-assine-agora">
          <p>Ainda não é um assinante? Assine agora e tenha acesso a todos os nossos cursos.</p>

          <p className="preco">Por apenas<br /><strong>R${planoPrincipal?.valor_mensal}</strong><i>/mês</i></p>

          <Link to="/acesso/planos">
            <button>
              Assine Agora
            </button>
          </Link>
        </div>
      }

      <div className="container-abas-aulas">
        <div className="opcoes-abas">
          <div
            className={abaSelecionada === "detalhes" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("detalhes")}>
            <p>Detalhes Do Curso</p>
          </div>

          {tipoCurso !== "cursos-pos-graduacao" &&
            <div
              className={abaSelecionada === "assistir" ? "aba-selecionada" : ""}
              onClick={() => setAbaSelecionada("assistir")}>
              <p>Assistir aulas</p>
            </div>
          }
        </div>

        <div className="container-conteudo-abas">
          {abaSelecionada === "detalhes"
            ? <DetalhesCurso cursoSelecionado={cursoSelecionado}></DetalhesCurso>
            : <AssistirAulas cursoSelecionado={cursoSelecionado}></AssistirAulas>
          }

        </div>
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(DetalhesCurso_AssistirAulas)
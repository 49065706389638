import React, { useState, useEffect, memo } from "react"

import { apiPublica } from "../../../services/api"

import "./styles.css"

import logoVazada from "../../../assets/logo-vazada.png"

import CardsPlanos from "../../../components/CardsPlanos"
import Rodape from "../../../components/Rodape"

function Planos() {
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [planos, setPlanos] = useState([])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    // REQUISIÇÃO DE PLANOS
    apiPublica.get("planos/")
      .then(function (response) {
        setPlanos(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-pagina-planos">
      <img className="background" src={logoVazada} alt="background" />

      <div className="container-titulo">
        <h1>Escolha o Melhor <i>Plano</i></h1>

        <hr />
      </div>

      <div className="container-cards-planos">
        {
          planos.map((item) => {
            if (item.is_ativo) {
              if (item.valor_mensal !== 0) {
                return (
                  <CardsPlanos key={item.id} plano={item} isRenovacao={false} isPlataformaAcessada={true}></CardsPlanos>
                )
              }
              return null
            }
            return null
          })
        }
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(Planos)
import React, { memo, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useUsuario } from "../../context/Usuario"

import { apiPublica } from "../../services/api"

import "./styles.css"

import whatsapp from "../../assets/whatsapp.png"

function Rodape({ mostrarRodapeFixo }) {
  const { usuario } = useUsuario()

  const [planoPrincipal, setPlanoPrincipal] = useState(null)

  const history = useHistory()
  const pathName = history.location.pathname


  // REQUISIÇÃO DE PLANO PRINCIPAL
  useEffect(() => {
    apiPublica.get("plano-principal/")
      .then(function (response) {
        setPlanoPrincipal(response.data[0])
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  return (
    <div className="container-rodape">
      {
        usuario?.assinatura?.status === "free"
          && pathName !== "/acesso/cursos-gratis"
          && pathName !== "/acesso/planos"
          && pathName !== "/acesso/cursos-pos-graduacao"
          && pathName.includes("/gerenciar-perfil-aluno") === false
          && pathName.includes("/detalhes-assistir-aulas") === false
          ? <div className="container-assine-agora-fixo">
            <div>
              <p>
                Ainda não é um assinante? Assine agora e tenha acesso a todos os nossos cursos.
              </p>
            </div>

            <p className="preco">Por apenas<br /><strong>R${planoPrincipal?.valor_mensal}</strong><i>/mês</i></p>

            <Link to={pathName.includes("/acesso") ? "/acesso/planos" : "/manter-aluno-plataforma/cadastro-assinatura"}>
              <button>
                Assine Agora
              </button>
            </Link>
          </div>
          : <></>
      }

      <footer>
        <a className="btn-whatsapp" href="https://wa.me/556285633841" target="_blank" rel="noreferrer">
          Clique aqui e fale com um de nossos especialistas
          <img src={whatsapp} alt="whatsapp" />
        </a>

        <h4>Contato</h4>
        <p>Av. Adibe Miguel, 270 - Salas 3 e 7 - Jamil Miguel</p>
        <p>Anápolis - GO, 75124-020</p>
        <p>(62) 98563-3841 / (62) 3317-2048</p>
        <p>contato@csaeducacional.com.br</p>

        <div>
          <p>2021 todos os direitos reservados</p>
        </div>
      </footer>

      {mostrarRodapeFixo &&
        <a href="https://wa.me/556285633841" target="_blank" rel="noreferrer">
          <img className="logo-whatsapp" src={whatsapp} alt="whatsapp" />
        </a>
      }
    </div>
  )
}

export default memo(Rodape)
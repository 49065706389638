import React, { memo, useEffect, useState } from "react"

import apiPrivada from "../../../../services/api"

import { useUsuario } from "../../../../context/Usuario"

import AccordionDisciplinas from "../../../../components/PlataformaAcessada/AccordionDisciplinas"
import AccordionAssistirAulas from "../../../../components/PlataformaAcessada/AccordionAssistirAulas"

function AssistirAulas({ cursoSelecionado }) {
  const { usuario } = useUsuario()

  const [disciplinas, setDisciplinas] = useState([])
  const [aulas, setAulas] = useState([])


  useEffect(() => {
    // REQUISIÇÃO DE DISCIPLINAS DO CURSO
    apiPrivada.get("disciplinas/")
      .then(function (response) {
        let todasDisciplinas = response.data
        let disciplinasCurso = []

        todasDisciplinas.map((item) => {
          if (item.curso === cursoSelecionado.id) {
            disciplinasCurso.push(item)
          }
          return null
        })

        setDisciplinas(disciplinasCurso)
      })
      .catch(function (error) {
        console.log(error)
      })

    // REQUISIÇÃO DE AULAS DO CURSO
    apiPrivada.get("aulas/")
      .then(function (response) {
        setAulas(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [cursoSelecionado])


  return (
    <div className="container-disciplinas">
      {disciplinas.length > 1
        ? disciplinas.map((item) => {
          return (
            <AccordionDisciplinas key={item.id} disciplina={item}></AccordionDisciplinas>
          )
        })
        : <div className="container-assistir-aulas">
          {
            aulas.map((item) => {
              if (item.is_aula_free || usuario.assinatura.status !== "free") {
                if (item.disciplina === disciplinas[0]?.id) {
                  return (
                    <AccordionAssistirAulas key={item.id} aula={item}></AccordionAssistirAulas>
                  )
                }
                return null
              } return null
            })
          }
        </div>
      }
    </div>
  )
}

export default memo(AssistirAulas)
import React, { memo, useState, useEffect } from "react"

import { apiPublica } from "../../../../services/api"

import "./styles.css"

import CardsPlanos from "../../../../components/CardsPlanos"

function PlanosCadastro({ tipoCadastro, isRenovacao }) {
  const [planos, setPlanos] = useState([])

  // REQUISIÇÃO DE PLANOS
  useEffect(() => {
    apiPublica.get("planos/")
      .then(function (response) {
        setPlanos(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  return (
    <div className="container-planos-cadastro">

      <div className="container-titulo">
        <h1>Escolha o Melhor <i>Plano</i></h1>

        <hr />
      </div>

      <div className="container-cards-planos">
        {
          tipoCadastro === "gratis"
            ? planos.map((item) => {
              if (item.is_ativo) {
                return (
                  <CardsPlanos key={item.id} plano={item} isRenovacao={isRenovacao} isPlataformaAcessada={false}></CardsPlanos>
                )
              }
              return null
            })
            : planos.map((item) => {
              if (item.is_ativo) {
                if (item.valor_mensal !== 0) {
                  return (
                    <CardsPlanos key={item.id} plano={item} isRenovacao={isRenovacao}></CardsPlanos>
                  )
                }
                return null
              }
              return null
            })
        }
      </div>
    </div>
  )
}

export default memo(PlanosCadastro)
import React, { memo, useEffect, useState } from "react"
import { HiOutlineClipboardList } from "react-icons/hi"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import "./styles.css"

import apiPrivada from "../../../services/api"

import logoVazada from "../../../assets/logo-vazada.png"

import Rodape from "../../../components/Rodape"
import DetalhesDisciplina from "./DetalhesDisciplina"
import AssistirAulas from "./AssistirAulas"

function DetalhesDisciplina_AssistirAulas_Pos({ match }) {
  const idDisciplina = match.params.idDisciplina

  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [abaSelecionada, setAbaSelecionada] = useState("detalhes")

  const [disciplinaSelecionada, setDisciplinaSelecionada] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    // REQUISIÇÃO DA DISCIPLINA SELECIONADO
    apiPrivada.get(`posgraduacao/disciplinas/${idDisciplina}/`)
      .then(function (response) {
        setDisciplinaSelecionada(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    return () => intersectionObserver.disconnect()
  }, [idDisciplina])


  return (
    <div className="container-detalhes-assistir-pos">
      <img className="background" src={logoVazada} alt="background" />

      <div className="container-banner-curso">
        <img src={disciplinaSelecionada?.imagem} alt="banner" />
        <h1>{disciplinaSelecionada?.titulo}</h1>
      </div>

      <div className="container-nota-progresso">
        <div className="quadro">
          <HiOutlineClipboardList className="icone"></HiOutlineClipboardList>
          <p>Nota:
            <span className={
              disciplinaSelecionada?.notas.valor >= disciplinaSelecionada?.nota_minima
                ? "aprovado"
                : "reprovado"
            }>
              {disciplinaSelecionada?.notas.atribuidas
                ? disciplinaSelecionada?.notas.valor
                : " Não disponível"
              } </span>
          </p>
        </div>
        <div className="quadro">
          <CircularProgressbar
            className="progresso"
            value={disciplinaSelecionada?.progresso.porcentagem}
            text={`${disciplinaSelecionada?.progresso.porcentagem}%`}
          />
          <p>Progresso</p>
        </div>
      </div>

      <div className="container-abas-aulas">
        <div className="opcoes-abas">
          <div
            className={abaSelecionada === "detalhes" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("detalhes")}>
            <p>Detalhes Disciplina</p>
          </div>

          <div
            className={abaSelecionada === "assistir" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("assistir")}>
            <p>Assistir aulas</p>
          </div>
        </div>

        <div className="container-conteudo-abas">
          {abaSelecionada === "detalhes"
            ? <DetalhesDisciplina disciplinaSelecionada={disciplinaSelecionada}></DetalhesDisciplina>
            : <AssistirAulas disciplinaSelecionada={disciplinaSelecionada}></AssistirAulas>
          }

        </div>
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(DetalhesDisciplina_AssistirAulas_Pos)
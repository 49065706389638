import React, { memo } from "react";
import { useHistory } from "react-router-dom";
// import { FaCheckSquare } from "react-icons/fa"

// import { useUsuario } from "../../../context/Usuario"

// import apiPrivada from "../../../services/api"

import "./styles.css";

import logoVazada from "../../../assets/logo-vazada.png";
import whatsapp from "../../../assets/whatsapp.png";

function ErroLogin() {
  const history = useHistory();
  const statusErro = history.location.state.statusErro;
  const checkout = history.location.state.checkout;

  // const { usuario } = useUsuario()

  // const [planoGratis, setPlanosGratis] = useState([])
  // const [itensPlanoGratis, setItensPlanoGratis] = useState([])

  // const detalhesPlanoGratis = () => {
  //   return (
  //     <div className="detalhes-plano">
  //       <p className="titulo">{planoGratis.titulo}</p>
  //       <p>Duração: {planoGratis.quantidade_meses} meses</p>
  //       <p>Valor: R$ {planoGratis.valor_mensal}</p>

  //       <p className="subtitulo">Incluí</p>
  //       {itensPlanoGratis.map((item) => {
  //         return (
  //           <div key={item.id} className="itens">
  //             <FaCheckSquare className="icone-checkbox"></FaCheckSquare>
  //             <p>{item.descricao}</p>
  //           </div>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  const exibirMensagemErro = () => {
    if (statusErro === "expired") {
      return (
        <div>
          <h1>Assinatura Expirada!</h1>
          <p className="mensagem">
            A sua assinatura expirou. Você deve escolher um novo plano para ter
            acesso a plataforma.
          </p>
          {/* {detalhesPlanoGratis()} */}
          <div className="botoes">
            <button
              onClick={() =>
                history.push("/manter-aluno-plataforma/completar-cadastro")
              }
            >
              Escolher Plano
            </button>
            {/*<button onClick={() => assinarPlano(true)}>Continuar Grátis</button>*/}
          </div>
          *
        </div>
      );
    }
    if (checkout === "pagseguro") {
      switch (statusErro) {
        case 1:
          return (
            <div>
              <h1>Aguardando Pagamento!</h1>

              <p className="mensagem">
                A sua assinatura está sendo processada. Atualize a página para
                verificar quando o seu pagamento for processado.
              </p>

              {/* {detalhesPlanoGratis()} */}

              {/*<div className="botoes">
                <button onClick={() => history.push("/manter-aluno-plataforma/completar-cadastro")}>Escolher Plano</button>
                <button onClick={() => assinarPlano(true)}>Continuar Grátis</button>
              </div>*/}
            </div>
          );
        case 6:
          return (
            <div>
              <h1>Transação Devolvida!</h1>

              <p className="mensagem">
                O valor da transação foi devolvido para o comprador. Você deve
                efetuar a compra novamente.
              </p>

              {/* {detalhesPlanoGratis()} */}

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
                {/* <button onClick={() => assinarPlano(true)}>Continuar Grátis</button> */}
              </div>
            </div>
          );
        case 7:
          return (
            <div>
              <h1>Transação Cancelada!</h1>

              <p className="mensagem">
                A transação foi cancelada sem ter sido finalizada. Você deve
                efetuar a compra novamente.
              </p>

              {/* {detalhesPlanoGratis()} */}

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
                {/* <button onClick={() => assinarPlano(true)}>Continuar Grátis</button> */}
              </div>
            </div>
          );
        case 8:
          return (
            <div>
              <h1>Transação Devolvida!</h1>

              <p className="mensagem">
                A valor da transação foi devolvido para o comprador. Você deve
                efetuar a compra novamente.
              </p>

              {/* {detalhesPlanoGratis()} */}

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
                {/* <button onClick={() => assinarPlano(true)}>Continuar Grátis</button> */}
              </div>
            </div>
          );
        case 9:
          return (
            <div>
              <h1>Retenção temporária!</h1>

              <p className="mensagem">
                Houve uma retenção temporária na sua compra. Você deve aguardar
                até que ela seja finalizada.
              </p>

              {/* {detalhesPlanoGratis()} */}

              {/* <div className="botoes">
                <button onClick={() => assinarPlano(true)}>Continuar Grátis</button>
              </div> */}
            </div>
          );
        default:
          return;
      }
    } else if (checkout === "cielo") {
      switch (statusErro) {
        case 1:
          return (
            <div>
              <h1>Aguardando Pagamento!</h1>

              <p className="mensagem">
                A sua assinatura está sendo processada. Essa página irá
                atualizar automaticamente com o status da sua compra.
              </p>
            </div>
          );
        case 3:
          return (
            <div>
              <h1>Transação Negada!</h1>

              <p className="mensagem">
                Transação não autorizada pelo responsável do meio de pagamento.
              </p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        case 4:
          return (
            <div>
              <h1>Expirado!</h1>

              <p className="mensagem">A transação expirou sem ser paga.</p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        case 5:
          return (
            <div>
              <h1>Transação Cancelada!</h1>

              <p className="mensagem">Transação foi cancelada pelo lojista.</p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        case 6:
          return (
            <div>
              <h1>Pagamento esperando Status!</h1>

              <p className="mensagem">Aguardando o pagamento.</p>
            </div>
          );
        case 8:
          return (
            <div>
              <h1>Devolução!</h1>

              <p className="mensagem">
                Transação cancelada pelo consumidor junto ao emissor do cartão
              </p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        default:
          return;
      }
    }
  };

  // useEffect(() => {
  //   apiPrivada.get("planos/")
  //     .then(function (response) {
  //       let planos = response.data

  //       planos.forEach((plano) => {
  //         if (plano.valor_mensal === 0) {
  //           setPlanosGratis(plano)

  //           apiPrivada.get(`itensplanos/`)
  //             .then(function (response) {
  //               let itensPlanos = response.data
  //               let itensPlanoGratis = []

  //               itensPlanos.forEach((item) => {
  //                 if (item.plano === plano.id) {
  //                   itensPlanoGratis.push(item)
  //                 }
  //               })

  //               setItensPlanoGratis(itensPlanoGratis)
  //             })
  //             .catch(function (error) {
  //               console.log(error)
  //             })
  //         }
  //       })
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  // }, [])

  return (
    <div className="container-erro-login">
      <img className="background-1" src={logoVazada} alt="background" />
      <img className="background-2" src={logoVazada} alt="background" />

      <div className="quadro-erro">{exibirMensagemErro()}</div>

      <a href="https://wa.me/556285633841" target="_blank" rel="noreferrer">
        <img className="logo-whatsapp" src={whatsapp} alt="whatsapp" />
      </a>
    </div>
  );
}

export default memo(ErroLogin);

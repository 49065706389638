import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { HiArrowNarrowLeft } from "react-icons/hi";
// import { FaFacebookF } from "react-icons/fa"
import { FcGoogle } from "react-icons/fc";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import GoogleLoginButton from "react-google-login";
import ReCAPTCHA from "react-google-recaptcha";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useUsuario } from "../../../../context/Usuario";

import apiPrivada, { apiPublica } from "../../../../services/api";

import "./styles.css";

function FormularioCadastroAluno({ tipoCadastro }) {
  const history = useHistory();

  const { setUsuario } = useUsuario();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [checkInputSenha, setCheckInputSenha] = useState(null);

  const [checkBoxTermos, setCheckBoxTermos] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  const [msgErro, setMsgErro] = useState(null);

  // VERIFICA QUAL TIPO DE CADASTRO PARA REDIRECIONAR A PÁGINA
  const verificarProximaPagina = () => {
    if (tipoCadastro === "gratis") {
      return history.push("/manter-aluno-plataforma/planos-gratis");
    } else {
      return history.push("/manter-aluno-plataforma/completar-cadastro");
    }
  };

  const registrarTokenUsuario = () => {
    apiPublica
      .post("auth/token", {
        username: email,
        password: senha,
        grant_type: "password",
        client_secret:
          "0S77ivla4cjuq6Zp2lRigXcUtBDsiM4p7LBiMgg1HMPviFejySjmvBF1fGYb4rVmicNZ25EzHL2XYBZ0rx7iY011E8aN1guWeaWggRQ0cq6UWQk6WXGaTbNasUyJILyr",
        client_id: "x485A3oZ8VoYPVozEVfx7fehFBdwPKWaj4GkI6aR",
      })
      .then(function (response) {
        localStorage.setItem("@CSA:OAUTH_TOKEN", response.data.access_token);

        apiPrivada
          .get("auth/validate/")
          .then(function (responseValidate) {
            setUsuario(responseValidate.data.aluno);
            verificarProximaPagina();
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
        return setMsgErro(
          "Erro ao realizar cadastro. Confira os dados e tente novamente!"
        );
      });
  };

  const cadastrarAluno = (e) => {
    e.preventDefault();

    if (
      nome.trim() === "" ||
      email.trim() === "" ||
      senha.trim() === "" ||
      confirmarSenha.trim() === ""
    ) {
      return setMsgErro("Todos os campos com * devem ser preenchidos!");
    }

    if (!checkBoxTermos) {
      return setMsgErro("Os Termos do Contrato devem ser aceitos!");
    }

    if (!captcha) {
      return setMsgErro("O captcha deve ser marcado!");
    }

    apiPublica
      .post("alunos/", {
        password: senha,
        email: email,
        nome_completo: nome,
        celular: celular,
      })
      .then(function () {
        registrarTokenUsuario();
      })
      .catch(function (error) {
        console.log(error);

        confirmAlert({
          title: "Erro!",
          message: `Erro ao realizar cadastro! ${
            error.response?.data.email[0] ? error.response?.data.email[0] : ""
          }`,
          overlayClassName: "alert-erro-cadastro",
          buttons: [
            {
              label: "Ok",
              className: "botao-erro",
            },
          ],
        });
      });
  };

  // const responseFacebook = (response) => {
  //   apiPublica.post("/auth/convert-token", {
  //     token: response.accessToken,
  //     backend: 'facebook',
  //     grant_type: 'convert_token',
  //     client_id: 'rdkfEvtaXaXd9thbOs8aUlgZssghV7cu7ZR4JVWu',
  //     client_secret: '3sWO3CY6e37yswWIrtgYr9BzQp3LU2G9bqtfSzhvJPATltN6oGcxxPj8zUtbQY0pFWda0RfkLpjSekwKpDqBCBVttgQN9OZhGjWgxQIWvNE0hQV6IH8WI1mxvKV4RheP'
  //   })
  //     .then(function (res) {
  //       localStorage.setItem("@CSA:OAUTH_TOKEN", res.data.access_token)
  //       verificarProximaPagina()
  //     })
  //     .catch(function (err) {
  //       console.log(JSON.stringify(err, null, 4))
  //     })
  // }

  const responseGoogle = (response) => {
    apiPublica
      .post("/auth/convert-token", {
        token: response.accessToken,
        backend: "google-oauth2",
        grant_type: "convert_token",
        client_id: "I9AZcTuMq5MnzlPpbnvSPZZuTgFU8Wmb9TthX368",
        client_secret:
          "iPVwF7i2KpVyMRKk80IytzcstSJvCfvcZkRc15QFbm3gG3s0UcJ5hU5PPKsvwjbTaXeGTrxG2nO1mvPMny51CA5OKUN2c5DIDBPv1duRWSZWVf8cVbTnwTOnmC317W9C",
      })
      .then(function (res) {
        localStorage.setItem("@CSA:OAUTH_TOKEN", res.data.access_token);
        verificarProximaPagina();
      })
      .catch(function (err) {
        console.log(JSON.stringify(err, null, 4));
      });
  };

  // VERIFICA IGUALDADE DE SENHAS
  useEffect(() => {
    if (senha !== "" && senha === confirmarSenha) {
      return setCheckInputSenha(true);
    }

    setCheckInputSenha(false);
  }, [senha, confirmarSenha]);

  return (
    <form
      className="container-formulario-cadastro-aluno"
      onSubmit={cadastrarAluno}
    >
      <div className="titulo">
        <div className="topo">
          <button onClick={() => history.replace("/")}>
            <HiArrowNarrowLeft className="icone-voltar"></HiArrowNarrowLeft>
          </button>

          <h2>Cadastre-se</h2>
        </div>

        <div className="botoes-login">
          {/* <FacebookLogin
            appId="592789341906946"
            fields="name,email,picture"
            callback={responseFacebook}
            render={renderProps => (
              <button onClick={renderProps.onClick} type="button" className="btn-facebook">
                <FaFacebookF className="icone-btn-login"></FaFacebookF>
                <p>Continuar com Facebook</p>
              </button>
            )}
          /> */}

          <GoogleLoginButton
            clientId="656629105455-1euuqp5deknpmklfk2n45o2eheonhej3.apps.googleusercontent.com"
            buttonText="Google"
            onSuccess={responseGoogle}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                type="button"
                className="btn-google"
              >
                <FcGoogle className="icone-btn-login"></FcGoogle>
                <p>Continuar com Google</p>
              </button>
            )}
          />

          {/* <button className="btn-linkedin">
            <FaLinkedinIn className="icone-btn-login"></FaLinkedinIn>
            <p>Continuar com LinkedIn</p>
          </button> */}
        </div>

        <p>Ou</p>
        <p className="msg-continuar-cadastro">
          Preencha os campos abaixo para continuarmos avançando com seu cadastro
        </p>
      </div>

      <div className="container-inputs">
        <label>
          Nome <span>*</span>
          <input
            required
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </label>

        <label>
          E-mail <span>*</span>
          <input
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>

        <label>
          Celular
          <InputMask
            mask="(99) 99999-9999"
            value={celular}
            onChange={(e) => setCelular(e.target.value)}
          ></InputMask>
        </label>

        <label>
          Senha <span>*</span>
          <input
            required
            type="password"
            className={checkInputSenha ? "senha-check" : ""}
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
        </label>

        <label>
          Confirmar Senha <span>*</span>
          <input
            required
            type="password"
            className={checkInputSenha ? "senha-check" : ""}
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
          />
        </label>

        <label className="labelCheckBox">
          Li e concordo com os{" "}
          <a
            href="../../../../../termos_e_condicoes.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Termos do Contrato
          </a>
          <input
            type="checkbox"
            defaultChecked={checkBoxTermos}
            onChange={() => setCheckBoxTermos(!checkBoxTermos)}
          />
          <span className="checkmark"></span>
        </label>

        <div className="captcha">
          <ReCAPTCHA
            sitekey="6Ldl54EcAAAAAHR3VJkHcIBww5Uv2y8oONVqbye1"
            onChange={() => setCaptcha(true)}
          />
        </div>

        <button type="button" onClick={cadastrarAluno}>
          Assinar Agora
        </button>

        {msgErro !== null && <p className="aviso-erro-login">{msgErro}</p>}
      </div>
    </form>
  );
}

export default memo(FormularioCadastroAluno);

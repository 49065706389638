import React, { useState, memo, useEffect, useCallback } from "react"
import { Link } from "react-router-dom"
import { MdPlayArrow } from "react-icons/md"
import { GrSearch } from "react-icons/gr"
import { IoIosArrowForward } from "react-icons/io"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import "./styles.css"

import apiPrivada from "../../../services/api"

import logoVazada from "../../../assets/logo-vazada.png"

import CarouselCursosDetalhes from "../../../components/PlataformaAcessada/CarouselCursosDetalhes"
import QuadroTrilhasConhecimento from "../../../components/QuadroTrilhasConhecimento"
import Rodape from "../../../components/Rodape"

function Inicio() {
  const [cursoSearch, setCursoSearch] = useState("")
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)

  const [autoPlayBanners, setAutoPlayBanners] = useState(false)
  const [cursosBanners, setCursosBanners] = useState([])
  const [cursosPosGraduacao, setCursosPosGraduacao] = useState([])
  const [cursosAtualizacao, setCursosAtualizacao] = useState([])


  const adicionarCursosBanners = (cursos) => {
    cursos.forEach((item) => {
      if (item.is_destaque) {
        if (item.imagem_banner) {
          setCursosBanners(cursosBanners => [...cursosBanners, item])
        }
      }
    })
  }


  const separarCursosEmCategorias = (todosCursos, configuracoesTodosCursos) => {
    let cursosAtualizacao = []
    let cursosPosGraduacao = []

    todosCursos.forEach((curso) => {
      configuracoesTodosCursos.forEach((config) => {
        if (curso.id === config.curso) {
          if (config.disponibilizar_plataforma_assinantes && !config.disponibilizar_pos_graduacao) {
            cursosAtualizacao.push(curso)
          }

          if (config.disponibilizar_pos_graduacao && !config.disponibilizar_plataforma_assinantes) {
            cursosPosGraduacao.push(curso)
          }

          if (config.disponibilizar_plataforma_assinantes && config.disponibilizar_pos_graduacao) {
            cursosAtualizacao.push(curso)
            cursosPosGraduacao.push(curso)
          }
        }
      })
    })

    setCursosAtualizacao(cursosAtualizacao)
    setCursosPosGraduacao(cursosPosGraduacao)
  }


  const filtrarCursos = useCallback((search) => {
    apiPrivada.get(`cursos/?search=${search}`)
      .then(function (responseCursos) {
        apiPrivada.get("configuracoescurso/")
          .then(function (responseConfigs) {
            separarCursosEmCategorias(responseCursos.data, responseConfigs.data)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  const verificarTipoCurso = (curso) => {
    let resultado = cursosPosGraduacao.map((item) => {
      return item.id
    }).indexOf(curso.id)

    if (resultado !== -1) {
      return "cursos-pos-graduacao"
    } else {
      return "cursos-atualizacao"
    }
  }


  useEffect(() => {
    setTimeout(() => {
      setAutoPlayBanners(true)
    }, 4000)
  }, [cursosBanners])


  useEffect(() => {
    if (cursoSearch.length === 0) {
      filtrarCursos("")
    } else if (cursoSearch.length > 2) {
      filtrarCursos(cursoSearch)
    }
  }, [cursoSearch, filtrarCursos])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    // REQUISIÇÃO DAS IMAGENS DE BANNERS DOS CURSOS
    apiPrivada.get("cursos/")
      .then(function (responseCursos) {
        adicionarCursosBanners(responseCursos.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-pagina-inicio">
      <div className="container-banners">
        <Carousel
          autoPlay={autoPlayBanners}
          interval={4000}
          infiniteLoop={true}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          stopOnHover={false}
        >
          {
            cursosBanners.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <img className="img-banner" src={item.imagem_banner} alt="" />
                  </div>

                  <div className="informacoes">
                    <h1>MUITO MAIS DO QUE CONTEÚDO</h1>

                    <p>
                      Uma plataforma de conhecimento e networking voltado para área industrial farmacêutica e afins
                    </p>

                    <div>
                      <Link
                        className="btn assistir"
                        to={`/acesso/${verificarTipoCurso(item)}/detalhes-assistir-aulas/${item.id}`}
                      >
                        <MdPlayArrow className="icone-botao-informacao"></MdPlayArrow>
                        Assistir
                      </Link>

                      <Link
                        className="btn detalhes"
                        to={`/acesso/${verificarTipoCurso(item)}/detalhes-assistir-aulas/${item.id}`}
                      >
                        + Detalhes
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </Carousel>

        <div className={cursoSearch ? "btn-search open" : "btn-search"}>
          <span className="icon">
            <GrSearch></GrSearch>
          </span>

          <input type="text" className="input-search" onChange={(e) => setCursoSearch(e.target.value)} />
        </div>
      </div>

      <div className="container-cursos">
        <img className="background-1" src={logoVazada} alt="background" />
        <img className="background-2" src={logoVazada} alt="background" />

        {cursosAtualizacao.length > 0 &&
          <div className="container-secoes-inicio">
            <div className="titulo">
              <h1>Nossos Cursos de <i>Atualização</i></h1>

              <Link to="/acesso/cursos-atualizacao">
                Ver todos
                <IoIosArrowForward className="icone-ver-todos"></IoIosArrowForward>
              </Link>
            </div>

            <CarouselCursosDetalhes cursos={cursosAtualizacao} tipoCurso={"cursos-atualizacao"}></CarouselCursosDetalhes>
          </div>
        }

        {cursosPosGraduacao.length > 0 &&
          <div className="container-secoes-inicio">
            <div className="titulo">
              <h1>Nossos Cursos de <i>Pós Graduação</i></h1>

              <Link to="/acesso/cursos-pos-graduacao">
                Ver todos
                <IoIosArrowForward className="icone-ver-todos"></IoIosArrowForward>
              </Link>
            </div>

            <CarouselCursosDetalhes cursos={cursosPosGraduacao} tipoCurso={"cursos-pos-graduacao"}></CarouselCursosDetalhes>
          </div>
        }

        <div className="container-secoes-inicio">
          <div className="titulo">
            <h1>Trilhas de <i>Conhecimento - Curso de Atualização</i></h1>
          </div>

          <QuadroTrilhasConhecimento isPlataformaAcessada={true}></QuadroTrilhasConhecimento>
        </div>

        <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
      </div>
    </div>
  )
}

export default memo(Inicio)
import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import apiPrivada from "../../../../services/api";

import whatsapp from "../../../../assets/whatsapp.png";

function AguardaStatusPagamento() {
  const history = useHistory();
  const [tipoCheckout, setTipoCheckout] = useState("pagseguro");
  const [statusErro, setStatusErro] = useState(1);

  useEffect(() => {
    const updateStatus = () => {
      apiPrivada
        .get("auth/validate/")
        .then(function (response) {
          try {
            setTipoCheckout(response.data.aluno.assinatura.checkout_type);
            setStatusErro(response.data.aluno.assinatura.status);
          } catch {}
        })
        .catch(function (error) {});
    };

    const interval = setInterval(updateStatus, 4000);
    updateStatus();
    return () => {
      clearInterval(interval);
    };
  }, []);

  const exibirMensagemErro = () => {
    if (statusErro === "expired") {
      return (
        <div>
          <h1>Assinatura Expirada!</h1>
          <p className="mensagem">
            A sua assinatura expirou. Você deve escolher um novo plano para ter
            acesso a plataforma.
          </p>
          {/* {detalhesPlanoGratis()} */}
          <div className="botoes">
            <button
              onClick={() =>
                history.push("/manter-aluno-plataforma/completar-cadastro")
              }
            >
              Escolher Plano
            </button>
            {/*<button onClick={() => assinarPlano(true)}>Continuar Grátis</button>*/}
          </div>
          *
        </div>
      );
    }

    if (tipoCheckout === "pagseguro") {
      switch (statusErro) {
        case 1:
          return (
            <div>
              <h1>Aguardando Pagamento!</h1>

              <p className="mensagem">
                A sua assinatura está sendo processada. Essa página irá
                atualizar automaticamente com o status da sua compra.
              </p>

              {/* {detalhesPlanoGratis()} */}

              {/*<div className="botoes">
                <button onClick={() => history.push("/manter-aluno-plataforma/completar-cadastro")}>Escolher Plano</button>
                <button onClick={() => assinarPlano(true)}>Continuar Grátis</button>
              </div>*/}
            </div>
          );
        case 6:
          return (
            <div>
              <h1>Transação Devolvida!</h1>

              <p className="mensagem">
                O valor da transação foi devolvido para o comprador. Você deve
                efetuar a compra novamente.
              </p>

              {/* {detalhesPlanoGratis()} */}

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
                {/* <button onClick={() => assinarPlano(true)}>Continuar Grátis</button> */}
              </div>
            </div>
          );
        case 7:
          return (
            <div>
              <h1>Transação Cancelada!</h1>

              <p className="mensagem">
                A transação foi cancelada sem ter sido finalizada. Você deve
                efetuar a compra novamente.
              </p>

              {/* {detalhesPlanoGratis()} */}

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
                {/* <button onClick={() => assinarPlano(true)}>Continuar Grátis</button> */}
              </div>
            </div>
          );
        case 8:
          return (
            <div>
              <h1>Transação Devolvida!</h1>

              <p className="mensagem">
                A valor da transação foi devolvido para o comprador. Você deve
                efetuar a compra novamente.
              </p>

              {/* {detalhesPlanoGratis()} */}

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
                {/* <button onClick={() => assinarPlano(true)}>Continuar Grátis</button> */}
              </div>
            </div>
          );
        case 9:
          return (
            <div>
              <h1>Retenção temporária!</h1>

              <p className="mensagem">
                Houve uma retenção temporária na sua compra. Você deve aguardar
                até que ela seja finalizada.
              </p>

              {/* {detalhesPlanoGratis()} */}

              {/* <div className="botoes">
                <button onClick={() => assinarPlano(true)}>Continuar Grátis</button>
              </div> */}
            </div>
          );
        default:
          history.push("/manter-aluno-plataforma/sucesso-assinante");
          return null;
      }
    } else if (tipoCheckout === "cielo") {
      switch (statusErro) {
        case 1:
          return (
            <div>
              <h1>Aguardando Pagamento!</h1>

              <p className="mensagem">
                A sua assinatura está sendo processada. Essa página irá
                atualizar automaticamente com o status da sua compra.
              </p>
            </div>
          );
        case 3:
          return (
            <div>
              <h1>Transação Negada!</h1>

              <p className="mensagem">
                Transação não autorizada pelo responsável do meio de pagamento.
              </p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        case 4:
          return (
            <div>
              <h1>Expirado!</h1>

              <p className="mensagem">A transação expirou sem ser paga.</p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        case 5:
          return (
            <div>
              <h1>Transação Cancelada!</h1>

              <p className="mensagem">Transação foi cancelada pelo lojista.</p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        case 6:
          return (
            <div>
              <h1>Pagamento esperando Status!</h1>

              <p className="mensagem">Aguardando o pagamento.</p>
            </div>
          );
        case 8:
          return (
            <div>
              <h1>Devolução!</h1>

              <p className="mensagem">
                Transação cancelada pelo consumidor junto ao emissor do cartão
              </p>

              <div className="botoes">
                <button
                  onClick={() =>
                    history.push("/manter-aluno-plataforma/completar-cadastro")
                  }
                >
                  Efetuar Compra
                </button>
              </div>
            </div>
          );
        default:
          history.push("/manter-aluno-plataforma/sucesso-assinante");
          return null;
      }
    }
  };

  return (
    <div className="container-erro-login">
      <div className="quadro-erro">{exibirMensagemErro()}</div>

      <a href="https://wa.me/556285633841" target="_blank" rel="noreferrer">
        <img className="logo-whatsapp" src={whatsapp} alt="whatsapp" />
      </a>
    </div>
  );
}

export default memo(AguardaStatusPagamento);

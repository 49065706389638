import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { AuthProvider } from "../context/Auth"

import PrivateRoute from "./PrivateRoute"
import PlataformaLogin from "./PlataformaLogin"
import PlataformaAcessada from "./PlataformaAcessada"

export default function Routes() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <PrivateRoute path="/acesso" component={PlataformaAcessada}></PrivateRoute>
          <Route path="/" component={PlataformaLogin}></Route>
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  )
}
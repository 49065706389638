import React, { memo } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import "./styles.css"

import CardsCursosDetalhes from "../CardsCursosDetalhes"

function CarouselCursos({ cursos, tipoCurso }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1420 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1420, min: 1070 },
      items: 3,
      slidesToSlide: 3
    },
    mobile: {
      breakpoint: { max: 1070, min: 645 },
      items: 2,
      slidesToSlide: 2
    },
    mini: {
      breakpoint: { max: 645, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }


  return (
    <div className="container-carousel-cards-cursos-detalhes">
      <Carousel responsive={responsive} arrows={false} >
        {
          cursos.map((item) => {
            return (
              <CardsCursosDetalhes
                key={item.id}
                curso={item}
                tipoCurso={tipoCurso}
              ></CardsCursosDetalhes>
            )
          })
        }
      </Carousel>
    </div>
  )
}

export default memo(CarouselCursos)
import React, { memo, useEffect, useState } from "react"
import { FaChartLine } from "react-icons/fa"

import apiPrivada from "../../../../services/api"

import "./styles.css"

import CarouselAulas from "../../../../components/PlataformaAcessada/CarouselAulas"

function MeuProgresso() {
  const [cursosPosGraduacao, setCursosPosGraduacao] = useState([])
  const [cursosAtualizacao, setCursosAtualizacao] = useState([])


  const separarCategoriaCursos = (todosProgressos, configuracoesTodosCursos) => {
    let arrCursosAtualizacao = []

    todosProgressos.forEach((progresso) => {
      configuracoesTodosCursos.forEach((config) => {
        if (progresso.curso.id === config.curso) {
          if (config.disponibilizar_plataforma_assinantes) {
            arrCursosAtualizacao.push(progresso)
          }
        }
      })
    })

    setCursosAtualizacao(arrCursosAtualizacao)
  }


  useEffect(() => {
    apiPrivada.get(`progresso/time/list/`)
      .then(function (response) {
        let progresso = response.data

        apiPrivada.get("configuracoescurso/")
          .then(function (responseConfigs) {
            separarCategoriaCursos(progresso, responseConfigs.data)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        console.log(error)
      })

    apiPrivada.get(`posgraduacao/aulas/time/`)
      .then(function (response) {
        setCursosPosGraduacao(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  return (
    <div className="container-meu-progresso">
      <div className="container-topo">
        <div>
          <FaChartLine className="icone-meu-progresso"></FaChartLine>
        </div>
        <p>Meu Progresso</p>
      </div>

      <h1 className="titulo-topico">Pós Graduação</h1>
      <div className="container-aulas">
        <CarouselAulas progressos={cursosPosGraduacao} tipoCurso={"cursos-pos-graduacao"}></CarouselAulas>
      </div>

      <hr />

      <h1 className="titulo-topico">Cursos de Atualização</h1>
      <div className="container-aulas">
        <CarouselAulas progressos={cursosAtualizacao} tipoCurso={"cursos-atualizacao"}></CarouselAulas>
      </div>
    </div>
  )
}

export default memo(MeuProgresso)
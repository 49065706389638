import React, { memo, useState } from 'react'
import { BiDownArrow, BiRightArrow } from "react-icons/bi"

import "./styles.css"

const AccordionDiferenciais = ({ titulo, conteudo }) => {
  const [itemAtivo, setItemAtivo] = useState(false)

  return (
    <div className="accordion-item-diferenciais">
      <div className="accordion-titulo" onClick={() => setItemAtivo(!itemAtivo)}>
        <div>
          {
            itemAtivo
              ? <BiDownArrow className="icone-accordion"></BiDownArrow>
              : <BiRightArrow className="icone-accordion"></BiRightArrow>
          }
        </div>

        <p>{titulo}</p>
      </div>

      {itemAtivo && <p className="accordion-conteudo">{conteudo}</p>}
    </div>
  )
}

export default memo(AccordionDiferenciais)
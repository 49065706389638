import React, { memo } from "react"
import { Link } from "react-router-dom"

import "./styles.css"

function CardsCursosDetalhes({ curso, tipoCurso, posicao }) {
  return (
    <div className="container-cards-cursos-detalhes">
      <img src={curso.imagem} alt="imagem-curso" />

      {posicao !== undefined &&
        <div className={"numero-trilha-conhecimento"}>
          <p>{posicao}</p>
        </div>
      }

      {
        curso?.tag &&
        <div className="tag-curso" style={{ backgroundColor: curso.tag.cor }}>
          <p>{curso.tag.texto.toUpperCase()}</p>
        </div>
      }

      <div className="acoes">
        <Link className="btn-acoes" to={`/acesso/${tipoCurso}/detalhes-assistir-aulas/${curso.id}`}>
          + Detalhes
        </Link>
      </div>

      <div className="nome-curso">
        <p>{curso.titulo.length > 155 ? curso.titulo.substr(0, 155) + "..." : curso.titulo}</p>
      </div>
    </div>
  )
}

export default memo(CardsCursosDetalhes)
import React, { memo, useState, useEffect } from 'react'
import { RiArrowRightSFill, RiArrowDownSFill } from "react-icons/ri"

import "./styles.css"

import apiPrivada from "../../../services/api"

import AccordionAssistirAulas from "../AccordionAssistirAulas"

const AccordionDisciplinas = ({ disciplina }) => {
  const [itemAtivo, setItemAtivo] = useState(true)
  const [aulas, setAulas] = useState([])


  useEffect(() => {
    // REQUISIÇÃO DE AULAS DO CURSO
    apiPrivada.get("aulas/")
      .then(function (response) {
        let todasAulas = response.data
        let aulasCurso = []

        todasAulas.map((item) => {
          if (item.disciplina === disciplina.id) {
            aulasCurso.push(item)
          }
          return null
        })

        setAulas(aulasCurso)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [disciplina])


  return (
    <div className="accordion-disciplinas">
      <div className="accordion-titulo" onClick={() => setItemAtivo(!itemAtivo)}>
        <p>{disciplina.titulo}</p>

        <div>
          {
            itemAtivo
              ? <RiArrowDownSFill className="icone-accordion"></RiArrowDownSFill>
              : <RiArrowRightSFill className="icone-accordion"></RiArrowRightSFill>
          }
        </div>
      </div>

      {itemAtivo &&
        <div className="accordion-conteudo">
          {
            aulas.map((item) => {
              return (
                <AccordionAssistirAulas key={item.id} aula={item}></AccordionAssistirAulas>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default memo(AccordionDisciplinas)
import React, { memo, useState, useEffect } from 'react'
import { RiArrowRightSFill, RiArrowDownSFill } from "react-icons/ri"
import { FaCheck, FaFilePdf } from "react-icons/fa"
import { IoDocumentTextOutline } from "react-icons/io5"
import Vimeo from "@u-wave/react-vimeo"
import Modal from "react-modal"

import apiPrivada from "../../../services/api"

import "./styles.css"

const AccordionAssistirAulasPos = ({ aula }) => {
  const itensAula = aula.materiais

  const [itemAtivo, setItemAtivo] = useState(true)
  const [itensVisualizados, setItensVisualizados] = useState([])
  const [abrirModalArtigo, setAbrirModalArtigo] = useState(false)


  const abrirModal = () => {
    setAbrirModalArtigo(true);
    document.querySelector("body").style.overflow = "hidden"
  }

  const fecharModal = () => {
    setAbrirModalArtigo(false)
    document.querySelector("body").style.overflow = "visible"
  }


  const ModalArtigoAula = ({ artigo }) => {
    return (
      <Modal
        isOpen={abrirModalArtigo}
        onRequestClose={fecharModal}
        className="modal-artigo"
        overlayClassName="overlay-modal-artigo"
        ariaHideApp={false}
      >
        <div className="fechar-modal">
          <button onClick={fecharModal}>X</button>
        </div>

        <div>
          <h2 className="titulo">{artigo.titulo}</h2>
        </div>

        <div className="conteudo-modal" dangerouslySetInnerHTML={{ __html: artigo.html }} />
      </Modal>
    )
  }


  const registrarProgressoAula = (item, progresso) => {
    apiPrivada.post(`posgraduacao/aulas/time/`, {
      material: item.id,
      time: progresso.seconds
    })
      .then(function () {
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  const registrarItemVisualizado = (item) => {
    apiPrivada.post(`posgraduacao/aulas/status/`, {
      material: item.id
    })
      .then(function () {
        setItensVisualizados(itensVisualizados => [...itensVisualizados, item.id])
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  const renderizandoDocumentoAula = (arquivo) => {
    if (arquivo.tipo === "1" || arquivo.tipo === "4") {
      let pathArquivo = arquivo.file !== null ? arquivo.file : ""
      let extensao = pathArquivo.split('/').pop()
      extensao = pathArquivo.indexOf('.') < 1 ? '' : extensao.split('.').pop()

      if (extensao === "pdf") {
        return (
          <a
            className="container-pdf"
            href={pathArquivo}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <FaFilePdf
              className="icone-pdf"
              onClick={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
            ></FaFilePdf>

            {itensVisualizados.indexOf(arquivo.id) !== -1 &&
              <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
                <FaCheck className="icone-andamento"></FaCheck>
                <p>Visualizado</p>
              </div>
            }
          </a>
        )
      } else {
        return (
          <div className="container-documento">
            <a
              href={pathArquivo}
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              <IoDocumentTextOutline
                className="icone-documento"
                onClick={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
              ></IoDocumentTextOutline>
            </a>

            {itensVisualizados.indexOf(arquivo.id) !== -1 &&
              <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
                <FaCheck className="icone-andamento"></FaCheck>
                <p>Visualizado</p>
              </div>
            }
          </div>
        )
      }
    } else {
      return (
        <div className="container-documento">
          <button onClick={abrirModal}>
            <IoDocumentTextOutline
              className="icone-documento"
              onClick={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
            ></IoDocumentTextOutline>
          </button>

          {itensVisualizados.indexOf(arquivo.id) !== -1 &&
            <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
              <FaCheck className="icone-andamento"></FaCheck>
              <p>Visualizado</p>
            </div>
          }
        </div>
      )
    }
  }


  const verificandoTipoArquivoAula = (arquivo) => {
    if (arquivo.tipo === "2" || arquivo.tipo === "3") {
      return (
        <div className="container-video-aula">
          <div className="video">
            <div className="vimeo">
              <Vimeo
                className="player"
                video={arquivo.link}
                start={arquivo.current_time}
                onPause={(seconds) => registrarProgressoAula(arquivo, seconds)}
                onEnd={itensVisualizados.indexOf(arquivo.id) === -1 ? () => registrarItemVisualizado(arquivo) : () => { }}
              />
            </div>
          </div>

          <div className={itensVisualizados.indexOf(arquivo.id) !== -1 ? "andamento" : "andamento incompleto"}>
            <FaCheck className="icone-andamento"></FaCheck>
            <p>Assistido</p>
          </div>
        </div>
      )
    } else {
      return renderizandoDocumentoAula(arquivo)
    }
  }


  const listarArquivosAula = () => {
    return itensAula.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <hr />

          <div className="container-aula">
            {verificandoTipoArquivoAula(item)}

            <div className="container-conteudo-aula">
              <p className="descricao">{item.titulo}</p>
              <div dangerouslySetInnerHTML={{ __html: item.html }} />
            </div>
          </div>

          {
            item.tipo === "5" && <ModalArtigoAula artigo={item}></ModalArtigoAula>
          }
        </React.Fragment >
      )
    })
  }

  useEffect(() => {
    apiPrivada.get(`posgraduacao/aulas/status/`)
      .then(function (response) {
        let progresso = response.data
        let itensVisualizados = []

        progresso.forEach((item) => {
          item.disciplinas.forEach((item) => {
            item.aulas.forEach((item) => {
              item.itens_assistidos.forEach((item) => {
                itensVisualizados.push(item.material)
              })
            })
          })
        })

        setItensVisualizados(itensVisualizados)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  return (
    <div className="accordion-assistir-aulas-pos">
      <div className="accordion-titulo" onClick={() => setItemAtivo(!itemAtivo)}>
        <p>{aula.titulo}</p>

        <div>
          {
            itemAtivo
              ? <RiArrowDownSFill className="icone-accordion"></RiArrowDownSFill>
              : <RiArrowRightSFill className="icone-accordion"></RiArrowRightSFill>
          }
        </div>
      </div>

      {itemAtivo &&
        <div className="accordion-conteudo">
          {
            listarArquivosAula()
          }
        </div>
      }
    </div>
  )
}

export default memo(AccordionAssistirAulasPos)
import React, { useState, useEffect, useCallback, memo } from "react"
import { useHistory } from "react-router-dom"
import { GrSearch } from "react-icons/gr"

import "./styles.css"

import apiPrivada from "../../../../services/api"

import logoVazada from "../../../../assets/logo-vazada.png"

import CardsDisciplinasPos from "../../../../components/PlataformaAcessada/CardsDisciplinasPos"
import Rodape from "../../../../components/Rodape"

function TurmaPos({ match }) {
  const idTurma = match.params.idTurma
  const history = useHistory()
  const [disciplinaSearch, setDisciplinaSearch] = useState("")
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)

  const [turma, setTurma] = useState(null)
  const [disciplinas, setDisciplinas] = useState([])


  const filtrarDisciplinas = useCallback((search) => {
    apiPrivada.get(`posgraduacao/disciplinas/?turma=${idTurma}&search=${search}`)
      .then(function (response) {
        setDisciplinas(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  useEffect(() => {
    if (disciplinaSearch.length === 0) {
      filtrarDisciplinas("")
    } else if (disciplinaSearch.length > 2) {
      filtrarDisciplinas(disciplinaSearch)
    }
  }, [disciplinaSearch, filtrarDisciplinas])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    return () => intersectionObserver.disconnect()
  }, [])


  useEffect(() => {
    apiPrivada.get(`posgraduacao/turmas/${idTurma}/`)
      .then(function (response) {
        setTurma(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idTurma])


  return (
    <div className="container-pagina-turma-pos">

      <img className="background" src={logoVazada} alt="background" />

      <div className="container-titulo">
        <div className={disciplinaSearch ? "btn-search open" : "btn-search"}>
          <span className="icon">
            <GrSearch></GrSearch>
          </span>

          <input type="text" className="input-search" onChange={(e) => setDisciplinaSearch(e.target.value)} />
        </div>
      </div>

      <div className="container-abas">
        <div className="opcoes-abas">
          <p
            onClick={() => history.goBack()}
          >Todos Cursos</p>

          <hr />

          <p
            className="aba-selecionada"
            onClick={() => history.goBack()}
          >Minha Pós</p>
        </div>
      </div>

      <div className="container-titulo-turma">
        <h1>Turma {turma?.nome} 2021</h1>

        <div className="disciplinas-titulo">
          <p>Disciplinas:</p>
        </div>
      </div>

      <div className="container-disciplinas">
        {disciplinas.map((item, index) => {
          return (
            <CardsDisciplinasPos
              key={index}
              disciplina={item}
              posicao={index + 1}
            ></CardsDisciplinasPos>
          )
        })
        }
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(TurmaPos)
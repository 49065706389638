import React, { memo } from "react"
import { Link } from "react-router-dom"
import ReactPixel from "react-facebook-pixel"

import "./styles.css"

import check from "../../../../assets/check.png"

function SucessoCadastro({ isAssinante }) {
  ReactPixel.track("Purchase")

  return (
    <div className="container-sucesso-cadastro">
      <h2>Sua Conta Foi Criada Com Sucesso.</h2>

      <img src={check} alt="check" />

      <p>Lembre-se de acessar seus perfil e completar o cadastro de seus dados.</p>

      {!isAssinante &&
        <>
          <p className="aviso-participante">
            Você ainda não é um assinante, seu acesso aos cursos é <i>limitado</i>. Para ter acesso a todos os cursos de
            atualização, <Link to="/manter-aluno-plataforma/planos-assinatura">assine agora</Link>.
          </p>

          <p className="aviso-confirmacao">
            Confirme sua inscrição de acesso no link enviado no e-mail cadastrado ou no link da mensagem enviada no seu
            número de whatsapp cadastrado!
          </p>
        </>
      }

      <Link to="/manter-aluno-plataforma/acessar-conta">
        <button>
          Entrar
        </button>
      </Link>
    </div>
  )
}

export default memo(SucessoCadastro)
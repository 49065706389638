import React, { useState, useEffect, memo, useCallback } from "react"
import { GrSearch } from "react-icons/gr"

import "../styles.css"

import apiPrivada from "../../../../services/api"

import logoVazada from "../../../../assets/logo-vazada.png"

import CardsCursosDetalhes from "../../../../components/PlataformaAcessada/CardsCursosDetalhes"
import QuadroTrilhasConhecimento from "../../../../components/QuadroTrilhasConhecimento"
import Rodape from "../../../../components/Rodape"

function Atualizacao() {
  const [cursoSearch, setCursoSearch] = useState("")
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [abaSelecionada, setAbaSelecionada] = useState("TodosCursos")

  const [cursosAtualizacao, setCursosAtualizacao] = useState([])


  const separarCategoriaCursosAtualizacao = (todosCursos, configuracoesTodosCursos) => {
    let arrCursosAtualizacao = []

    todosCursos.forEach((curso) => {
      configuracoesTodosCursos.forEach((config) => {
        if (curso.id === config.curso) {
          if (config.disponibilizar_plataforma_assinantes) {
            arrCursosAtualizacao.push(curso)
          }
        }
      })
    })

    setCursosAtualizacao(arrCursosAtualizacao)
  }


  const filtrarCursos = useCallback((search) => {
    apiPrivada.get(`cursos/?search=${search}`)
      .then(function (responseCursos) {
        apiPrivada.get("configuracoescurso/")
          .then(function (responseConfigs) {
            separarCategoriaCursosAtualizacao(responseCursos.data, responseConfigs.data)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  useEffect(() => {
    if (cursoSearch.length === 0) {
      filtrarCursos("")
    } else if (cursoSearch.length > 2) {
      filtrarCursos(cursoSearch)
    }
  }, [cursoSearch, filtrarCursos])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-pagina-cursos">

      <img className="background" src={logoVazada} alt="background" />

      <div className="container-titulo">
        <div className={cursoSearch ? "btn-search open" : "btn-search"}>
          <span className="icon">
            <GrSearch></GrSearch>
          </span>

          <input type="text" className="input-search" onChange={(e) => setCursoSearch(e.target.value)} />
        </div>
      </div>

      <div className="container-abas">
        <div className="opcoes-abas">
          <p
            className={abaSelecionada === "TodosCursos" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("TodosCursos")}
          >Todos Cursos</p>

          <hr />

          <p
            className={abaSelecionada === "MeusCursos" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("MeusCursos")}
          >Meus Cursos</p>
        </div>
      </div>

      <div className="container-cursos">
        {
          abaSelecionada === "TodosCursos"
            ? cursosAtualizacao.map((item) => {
              return (
                <CardsCursosDetalhes
                  key={item.id}
                  curso={item}
                  tipoCurso={"cursos-atualizacao"}
                ></CardsCursosDetalhes>
              )
            })
            : cursosAtualizacao.map((item, index) => {
              if (item.inscrito) {
                return (
                  <CardsCursosDetalhes
                    key={index}
                    curso={item}
                    tipoCurso={"cursos-atualizacao"}
                  ></CardsCursosDetalhes>
                )
              } else {
                return null
              }
            })
        }
      </div>

      <div className="container-trilhas-de-conhecimento">
        <h1>Trilhas de <i>Conhecimento - Curso de Atualização</i></h1>

        <QuadroTrilhasConhecimento isPlataformaAcessada={true}></QuadroTrilhasConhecimento>
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(Atualizacao)
import React, { memo, useEffect, useState } from "react"

import apiPrivada from "../../../../services/api"

import AccordionAssistirAulasPos from "../../../../components/PlataformaAcessada/AccordionAssistirAulasPos"

function AssistirAulas({ disciplinaSelecionada }) {
  const idDisciplina = disciplinaSelecionada.id

  const [aulas, setAulas] = useState([])


  useEffect(() => {
    apiPrivada.get(`posgraduacao/aulas/?disciplina=${idDisciplina}`)
      .then(function (response) {
        setAulas(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idDisciplina])


  return (
    <div className="container-disciplinas">
      {aulas.length > 0 &&
        <div className="container-assistir-aulas">
          {
            aulas.map((item) => {
              return (
                <AccordionAssistirAulasPos key={item.id} aula={item}></AccordionAssistirAulasPos>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default memo(AssistirAulas)
import React from "react"
import { Route, Switch } from "react-router-dom"

import { UsuarioProvider } from "../context/Usuario"

import MenuSuperior from "../components/PlataformaAcessada/MenuSuperior"
import Inicio from "../pages/PlataformaAcessada/Inicio"
import CursosAtualizacao from "../pages/PlataformaAcessada/Cursos/Atualizacao"
import CursosPosGraduacao from "../pages/PlataformaAcessada/Cursos/PosGraduacao"
import Planos from "../pages/PlataformaAcessada/Planos"
import ForumGeral from "../pages/PlataformaAcessada/ForumGeral"

import TrilhasDeConhecimento from "../pages/PlataformaAcessada/Cursos/TrilhasDeConhecimento"

import DetalhesCurso_AssistirAulas from "../pages/PlataformaAcessada/DetalhesCurso_AssistirAulas"
import DetalhesDisciplina_AssistirAulas_Pos from "../pages/PlataformaAcessada/DetalhesDisciplina_AssistirAulas_Pos"
import ForumDisciplina from "../pages/PlataformaAcessada/DetalhesDisciplina_AssistirAulas_Pos/ForumDisciplina"
import TurmaPos from "../pages/PlataformaAcessada/Cursos/TurmaPos"
import AdquirirCursoAvulso from "../pages/PlataformaAcessada/DetalhesCurso_AssistirAulas/AdquirirCursoAvulso"
import ForumCurso from "../pages/PlataformaAcessada/DetalhesCurso_AssistirAulas/ForumCurso"

import GerenciarPerfilAluno from "../pages/PlataformaAcessada/GerenciarPerfilAluno"

export default function PlataformaAcessada({ match }) {
  return (
    <UsuarioProvider>
      <MenuSuperior></MenuSuperior>

      <Switch>
        <Route path={`${match.url}/inicio`} exact component={Inicio}></Route>
        <Route path={`${match.url}/cursos-atualizacao`} exact component={CursosAtualizacao}></Route>
        <Route path={`${match.url}/cursos-pos-graduacao`} exact component={CursosPosGraduacao}></Route>
        <Route path={`${match.url}/planos`} exact component={Planos}></Route>
        <Route path={`${match.url}/forum-geral`} exact component={ForumGeral}></Route>

        <Route path={`${match.url}/cursos-atualizacao/trilhas-conhecimento/:idTrilha`} exact component={TrilhasDeConhecimento}></Route>

        <Route path={`${match.url}/:tipoCurso/detalhes-assistir-aulas/:idCurso`} exact component={DetalhesCurso_AssistirAulas}></Route>
        <Route path={`${match.url}/:tipoCurso/detalhes-assistir-aulas-pos/:idDisciplina`} exact component={DetalhesDisciplina_AssistirAulas_Pos}></Route>
        <Route path={`${match.url}/:tipoCurso/turma-pos/:idTurma`} exact component={TurmaPos}></Route>
        <Route path={`${match.url}/:tipoCurso/adquirir-curso-avulso`} exact component={AdquirirCursoAvulso}></Route>
        <Route path={`${match.url}/:tipoCurso/forum-curso/:idCurso`} exact component={ForumCurso}></Route>
        <Route path={`${match.url}/cursos-pos-graduacao/forum-disciplina/:idDisciplina`} exact component={ForumDisciplina}></Route>

        <Route path={`${match.url}/gerenciar-perfil-aluno/:opcaoMenu`} exact component={GerenciarPerfilAluno}></Route>
      </Switch>
    </UsuarioProvider>
  )
}
import React, { memo, useState } from "react"
import { IoNotificationsSharp } from "react-icons/io5"
import { BiEditAlt, BiInfoCircle, BiCreditCard } from "react-icons/bi"
import { Link } from "react-router-dom"
import { useUsuario } from "../../../../context/Usuario"

import "./styles.css"

import avatar from "../../../../assets/user.jpg"

function MeuPerfil() {
  const { usuario } = useUsuario()

  const [abrirNotificao, setAbrirNotificao] = useState(false)


  const verificandoCadastroIncompleto = () => {
    for (let property in usuario) {
      if (usuario[property] === null || usuario[property] === "") {
        return true
      }
    }
  }


  return (
    <div className="container-meu-perfil">
      <div className="container-topo">
        <p>{usuario?.nome_completo}</p>

        <div className="container-icone-notificacao" onClick={() => setAbrirNotificao(!abrirNotificao)}>
          <div>1</div>
          <IoNotificationsSharp className="icone-notificacao"></IoNotificationsSharp>
        </div>

        <div className={abrirNotificao ? "container-notificacao-completa-aberta" : "container-notificacao-completa"}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </p>
        </div>
      </div>

      <div className="container-informacoes">
        <div className="foto-perfil">
          <img src={usuario?.imagem !== null ? usuario?.imagem : avatar} alt="aluno" />
        </div>

        <div className="dados">
          <p>Tipo de Aluno: {usuario?.tipo_aluno === 1 ? "Aluno Pós Graduação" : "Aluno Plataforma"}</p>
          <p>E-mail: {usuario?.email}</p>
          <p>CPF: {usuario?.cpf}</p>
          <p>Celular: {usuario?.celular}</p>
        </div>

        <div className="icones-acoes">
          <Link to="/acesso/gerenciar-perfil-aluno/dados-pessoais">
            <BiEditAlt className="icone"></BiEditAlt>
          </Link>

          <div className="container-icone-cadastro">
            <div>1</div>
            <Link to="/acesso/gerenciar-perfil-aluno/dados-pessoais">
              <BiInfoCircle className="icone"></BiInfoCircle>
            </Link>

            {
              verificandoCadastroIncompleto() &&
              <Link to="/acesso/gerenciar-perfil-aluno/dados-pessoais" className="msg-atencao">
                Complete o seu cadastro!
              </Link>
            }

          </div>
        </div>
      </div>

      <Link to="/acesso/gerenciar-perfil-aluno/assinatura" className="assinatura">
        <BiCreditCard className="icone-cartao"></BiCreditCard>
        <p>Gerenciar Assinatura</p>
      </Link>
    </div>
  )
}

export default memo(MeuPerfil)
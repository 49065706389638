import React from "react"
import ReactPixel from "react-facebook-pixel"

import "./global.css"

import Routes from "../src/routes"

export default function App() {
  const options = {
    autoConfig: true,
    debug: false,
  }
  ReactPixel.init('229578809124912', options)
  ReactPixel.pageView()

  return <Routes></Routes>
}
import React, { useState, useEffect, memo, useCallback } from "react"
import { Link } from "react-router-dom"
import { GrSearch } from "react-icons/gr"

import "./styles.css"

import { apiPublica } from "../../../services/api"

import logoVazada from "../../../assets/logo-vazada.png"

import CardsCursos from "../../../components/PlataformaLogin/CardsCursos"
import Rodape from "../../../components/Rodape"


function TrilhasDeConhecimento({ match }) {
  const idTrilha = match.params.idTrilha
  const [cursoSearch, setCursoSearch] = useState("")
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)

  const [trilhaConhecimentoSelecionada, setTrilhaConhecimentoSelecionada] = useState(null)
  const [cursosTrilhaConhecimento, setCursosTrilhaConhecimento] = useState([])
  const [planoPrincipal, setPlanoPrincipal] = useState(null)


  const listarCursosTrilhaSelecionada = (todosCursos, trilhaSelecionada) => {
    let cursosTrilhaDeConhecimentoSelecionada = []

    trilhaSelecionada.cursos.forEach((item, index) => {
      let resultado = todosCursos.map((curso) => {
        return curso.id
      }).indexOf(item.id)

      if (resultado !== -1) {
        cursosTrilhaDeConhecimentoSelecionada.push({ curso: item, posicao: index })
      }
    })

    setCursosTrilhaConhecimento(cursosTrilhaDeConhecimentoSelecionada)
  }


  const filtrarCursos = useCallback((search) => {
    apiPublica.get(`cursos/?search=${search}`)
      .then(function (responseCursos) {
        apiPublica.get(`trilhasconhecimento/${idTrilha}/`)
          .then(function (responseTrilha) {
            setTrilhaConhecimentoSelecionada(responseTrilha.data)
            listarCursosTrilhaSelecionada(responseCursos.data, responseTrilha.data)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [idTrilha])


  useEffect(() => {
    if (cursoSearch.length === 0) {
      filtrarCursos("")
    } else if (cursoSearch.length > 2) {
      filtrarCursos(cursoSearch)
    }
  }, [cursoSearch, filtrarCursos])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    // REQUISIÇÃO DE PLANO PRINCIPAL
    apiPublica.get("plano-principal/")
      .then(function (response) {
        setPlanoPrincipal(response.data[0])
      })
      .catch(function (error) {
        console.log(error)
      })

    return () => intersectionObserver.disconnect()
  }, [idTrilha])


  return (
    <div className="container-trilhas-de-conhecimento">

      <img className="background" src={logoVazada} alt="background" />

      <div className="container-titulo">
        <div className={cursoSearch ? "btn-search open" : "btn-search"}>
          <span className="icon">
            <GrSearch></GrSearch>
          </span>

          <input type="text" className="input-search" onChange={(e) => setCursoSearch(e.target.value)} />
        </div>
      </div>

      <div className="container-titulo-trilha">
        <p>{trilhaConhecimentoSelecionada ? trilhaConhecimentoSelecionada.titulo : ""}</p>
      </div>

      <div className="container-cursos">
        {
          cursosTrilhaConhecimento.map((item, index) => {
            return (
              <CardsCursos
                key={index}
                curso={item.curso}
                posicao={item.posicao}
              ></CardsCursos>
            )
          })
        }
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>

      {mostrarRodapeFixo &&
        <div className="rodape-fixo-rolagem">
          <p>
            Por apenas R${planoPrincipal?.valor_mensal}/mês
          </p>

          <button>
            <Link to="/manter-aluno-plataforma/cadastro-assinatura">
              Assine
            </Link>
          </button>
        </div>
      }
    </div>
  )
}

export default memo(TrilhasDeConhecimento)
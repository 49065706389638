import React, { useState, useEffect, memo, useCallback } from "react"
import { Link } from "react-router-dom"
import { GrSearch } from "react-icons/gr"
import { useUsuario } from "../../../../context/Usuario"

import "../styles.css"

import apiPrivada from "../../../../services/api"

import logoVazada from "../../../../assets/logo-vazada.png"

import CardsCursosDetalhes from "../../../../components/PlataformaAcessada/CardsCursosDetalhes"
import CardsTurmasPos from "../../../../components/PlataformaAcessada/CardsTurmasPos"
import Rodape from "../../../../components/Rodape"

function PosGraduacao() {
  const { usuario } = useUsuario()

  const [cursoSearch, setCursoSearch] = useState("")
  const [mostrarRodapeFixo, setMostrarRodapeFixo] = useState(true)
  const [abaSelecionada, setAbaSelecionada] = useState("TodosCursos")
  const [planoPrincipal, setPlanoPrincipal] = useState(null)

  const [cursosPosGraduacao, setCursosPosGraduacao] = useState([])
  const [turmasPosGraduacao, setTurmasPosGraduacao] = useState([])


  const separarCategoriaCursosPos = (todosCursos, configuracoesTodosCursos) => {
    let arrCursosPosGraduacao = []

    todosCursos.forEach((curso) => {
      configuracoesTodosCursos.forEach((config) => {
        if (curso.id === config.curso) {
          if (config.disponibilizar_pos_graduacao) {
            arrCursosPosGraduacao.push(curso)
          }
        }
      })
    })

    setCursosPosGraduacao(arrCursosPosGraduacao)
  }


  const filtrarCursos = useCallback((search) => {
    apiPrivada.get(`cursos/?search=${search}`)
      .then(function (responseCursos) {
        apiPrivada.get("configuracoescurso/")
          .then(function (responseConfigs) {
            separarCategoriaCursosPos(responseCursos.data, responseConfigs.data)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  const filtrarTurmas = useCallback((search) => {
    apiPrivada.get(`posgraduacao/turmasporaluno/?search=${search}`)
      .then(function (response) {
        setTurmasPosGraduacao(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])


  useEffect(() => {
    if (abaSelecionada === "TodosCursos") {
      if (cursoSearch.length === 0) {
        filtrarCursos("")
      } else if (cursoSearch.length > 2) {
        filtrarCursos(cursoSearch)
      }
    } else {
      if (cursoSearch.length === 0) {
        filtrarTurmas("")
      } else if (cursoSearch.length > 2) {
        filtrarTurmas(cursoSearch)
      }
    }
  }, [abaSelecionada, cursoSearch, filtrarCursos, filtrarTurmas])


  useEffect(() => {
    window.scrollTo(0, 0)

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setMostrarRodapeFixo(false)
      } else {
        setMostrarRodapeFixo(true)
      }
    })

    intersectionObserver.observe(document.querySelector("footer"))

    apiPrivada.get("plano-principal/")
      .then(function (response) {
        setPlanoPrincipal(response.data[0])
      })
      .catch(function (error) {
        console.log(error)
      })

    return () => intersectionObserver.disconnect()
  }, [])


  return (
    <div className="container-pagina-cursos">

      <img className="background" src={logoVazada} alt="background" />

      <div className="container-titulo">
        <div className={cursoSearch ? "btn-search open" : "btn-search"}>
          <span className="icon">
            <GrSearch></GrSearch>
          </span>

          <input type="text" className="input-search" onChange={(e) => setCursoSearch(e.target.value)} />
        </div>
      </div>

      <div className="container-abas">
        <div className="opcoes-abas">
          <p
            className={abaSelecionada === "TodosCursos" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("TodosCursos")}
          >Todos Cursos</p>

          <hr />

          <p
            className={abaSelecionada === "MinhaPos" ? "aba-selecionada" : ""}
            onClick={() => setAbaSelecionada("MinhaPos")}
          >Minha Pós</p>
        </div>
      </div>

      {
        usuario?.assinatura.status === "free" &&
        <div className="container-assine-agora-fixo">
          <div>
            <p>
              Ainda não é um assinante? Assine agora e tenha acesso a todos os nossos cursos.
            </p>
          </div>

          <p className="preco">Por apenas<br /><strong>R${planoPrincipal?.valor_mensal}</strong><i>/mês</i></p>

          <Link to="/acesso/planos">
            <button>
              Assine Agora
            </button>
          </Link>
        </div>
      }

      <div className="container-cursos">
        {abaSelecionada === "TodosCursos"
          ? cursosPosGraduacao.map((item, index) => {
            return (
              <CardsCursosDetalhes
                key={index}
                curso={item}
                tipoCurso={"cursos-pos-graduacao"}
              ></CardsCursosDetalhes>
            )
          })
          : turmasPosGraduacao.map((item, index) => {
            return (
              <CardsTurmasPos
                key={index}
                turma={item}
              ></CardsTurmasPos>
            )
          })
        }
      </div>

      <Rodape mostrarRodapeFixo={mostrarRodapeFixo}></Rodape>
    </div>
  )
}

export default memo(PosGraduacao)
import React from "react"
import { Route, Redirect } from "react-router-dom"

import { useAuth } from "../context/Auth"

export default function PrivateRoute({ component: Component, ...rest }) {
  const { isAutenticado } = useAuth()

  return (
    <Route
      {...rest}
      render={props => isAutenticado
        ? <Component {...props}></Component>
        : <Redirect to={{ pathname: "/loading", state: { from: props.location, pathname: props.location.pathname } }}></Redirect>}
    ></Route>
  )
}
import React, { memo } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import CardsCursosDetalhes from "../CardsCursosDetalhes"
import CardsDisciplinasPos from "../CardsDisciplinasPos"

import "./styles.css"

function CarouselAulas({ progressos, tipoCurso }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 860 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 860, min: 570 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 570, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }


  return (
    <div className="container-carousel-cards-aulas">
      <Carousel responsive={responsive} arrows={false} >
        {
          tipoCurso === "cursos-pos-graduacao"
            ? progressos.map((progresso, index) => {
              return progresso.disciplinas.map((disciplina) => {
                return disciplina.aulas.map((aula) => {
                  return aula.itens_assistidos.map((item) => {
                    console.log(progresso)
                    return (
                      <div key={index} className="container-card-curso">
                        <p className="titulo-progresso" >{progresso.turma.nome} - {aula.aula.titulo}</p>

                        <CardsDisciplinasPos
                          key={index}
                          disciplina={disciplina.disciplina}
                        ></CardsDisciplinasPos>
                      </div>
                    )
                  })
                })
              })
            })
            : progressos.map((progresso, index) => {
              return progresso.disciplinas.map((disciplina) => {
                return disciplina.aulas.map((aula) => {
                  return aula.itens_assistidos.map((item) => {
                    return (
                      <div key={index} className="container-card-curso">
                        <p className="titulo-progresso" >{aula.aula.titulo} - {progresso.curso.titulo}</p>

                        <CardsCursosDetalhes
                          key={index}
                          curso={progresso.curso}
                          tipoCurso={tipoCurso}
                        ></CardsCursosDetalhes>
                      </div>
                    )
                  })
                })
              })
            })
        }
      </Carousel>
    </div>
  )
}

export default memo(CarouselAulas)